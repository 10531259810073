
interface HandleNoDataProps  extends React.PropsWithChildren{
    condition: any;
    emptyComponent?: any | undefined;
}


function HandleNoData({condition, emptyComponent,  children }: HandleNoDataProps) {
  return condition ? children: emptyComponent
}
export default HandleNoData