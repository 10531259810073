import Select, { components, OptionProps } from 'react-select';


function SelectCountries({className, onChange, value }: any) {


    const options = [
        { value: '+52', label: 'MX', flag: '/assets/vendor/flag-icon-css/flags/1x1/mx.svg' },
        // { value: '+1', label: 'US', flag: '/assets/vendor/flag-icon-css/flags/1x1/us.svg' },
    ]

    const SingleValue = ({ children, ...rest }: any) => {
        const value = rest.getValue()[0];
        return (
            <components.SingleValue {...rest}>
                <img src={value.flag} alt={value.label} style={{ width:'10px' }} /> {value.value}
            </components.SingleValue>
        );
    }

    const Option = (props: OptionProps<any>) => {
        return (
            <components.Option {...props}>
                <div  className='d-flex '>
                    <img src={props.data.flag} alt={props.data.label} style={{ width:'10px' }} />
                    <p className='ms-2' style={{ whiteSpace: 'nowrap'}}>{props.data.label} {props.data.value}</p>
                </div>
            </components.Option>
        );
    }


    return (
        <Select
            placeholder={'Lada'}
            options={options}
            className={ 'form-select-custom-flags ' + className}
            classNamePrefix='form-select-custom'
            components={{ SingleValue, Option }}
            onChange={(selected: any) => onChange(selected?.value)}
            // defaultValue
            value={options.find( o => o.value === value) || ''}
        />
    )
}

export default SelectCountries

