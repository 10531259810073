import { END_POINT } from "../constants";
import { HTTP } from "./http";

export const TemplatesService = {

    getAll: () => {
        return HTTP.get<any, any>(`${END_POINT}templates`);
    },
    getAllApproved: () => {
        return HTTP.get<any, any>(`${END_POINT}templates/approved`);
    },
    update: () => {
        return HTTP.get<any, any>(`${END_POINT}templates/update`);
    },
}