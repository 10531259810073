import Input from "../../components/Input"
import Button from "../../components/Button"
import Select from 'react-select'
import SelectCountries from "../../components/SelectCountries"
import { useEffect, useRef, useState } from "react";
import { ContactService } from "../../services/contactService";
import Alert from "../../components/Alert";
import { SegmentService } from "../../services/segmentService";
import { Controller, useForm } from "react-hook-form";
import { AssignmentService } from "../../services/assignmentService";

declare const bootstrap: any;

interface ContactFormModalProps {
    show: Boolean;
    onRequestClose: (data: any) => void;
    data: any
}

function ContactFormModal({ show, onRequestClose, data }: ContactFormModalProps) {

    const defaultValues = {
        _id: '',
        first_name: '',
        last_name: '',
        email: '',
        country_code: '',
        phone_number: '',
        assigned_id: '',
        segmentations: [],
        type: '',
        medical_specialty: '',
    }
    const { control, register, handleSubmit, reset, watch, setValue, formState: { errors, isValid, isSubmitting } } = useForm({
        mode: 'onChange',
        defaultValues
    });

    const [modal, setModal] = useState<any>(null)
    const [medicalSpecialties, setMedicalSpecialties] = useState([])
    const [segments, setSegments] = useState([])
    const [assignments, setAssignments] = useState([])

    const typeContact = watch('type')


    const modalRef = useRef<HTMLDivElement>(null)


    const submit = async (data: any) => {
        try {
            if (data.country_code && data.phone_number) {
                data.phones = [
                    {
                        is_primary: true,
                        phone: {
                            country_code: data.country_code,
                            phone_number: data.phone_number
                        }
                    }
                ]
            }


            if (data._id) {
                await ContactService.update(data);
            } else {

                delete data._id;
                await ContactService.save(data);
            }

            Alert.fire({
                title: 'Contacto guardado',
                text: `Se guardo correctamente el contacto`
            });

            onRequestClose(data)
        } catch (error) {
            console.error(error)
            alert(error.message)
        }
    }

    const getMedicalSpecialties = async () => {
        const response = await ContactService.getMedicalSpecialties()
        setMedicalSpecialties(response.map((a: any) => ({ value: a._id, label: a.name })))
    }

    const getSegments = async () => {
        const response = await SegmentService.getSegments()
        setSegments(response.map((a: any) => ({ value: a._id, label: a.title })))
    }

    const getAssignments = async () => {
        const response = await AssignmentService.getAssignment()
        setAssignments(response.map((a: any) => ({ value: a._id, label: a.name })))
    }


    useEffect(() => {
        if (!typeContact || typeContact === 'PHARMACY' || typeContact === 'OTHER') {
            setValue('medical_specialty', '')
        }

    }, [typeContact])

    useEffect(() => {
        if (modalRef.current) {
            const modalInstance = new bootstrap.Modal(modalRef.current, {})
            setModal(modalInstance)
        }

    }, [modalRef])

    useEffect(() => {
        if (data) {
            reset(data)
        } else {
            reset(defaultValues)
        }

    }, [data])

    useEffect(() => {
        if(modal) {
            show? modal.show(): modal.hide()
        }

    }, [modal, show])


    useEffect(() => {
        getMedicalSpecialties()
        getSegments()
        getAssignments()
    }, [])


    return (
        <div className="modal fade" ref={modalRef} tabIndex={-1} data-bs-backdrop="static" data-bs-keyboard="false" >
            <div className="modal-dialog" role="document">
                <form onSubmit={handleSubmit(submit)}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" >Nuevo contacto</h5>
                            <button type="button" className="btn-close" onClick={() => onRequestClose(false)}></button>
                        </div>
                        <div className="modal-body">
                            <div className="row m-b-40">
                                <div className="col-4">
                                    <p className="text-dark fw-bold m-b-8 font-12 text-uppercase">DATOS</p>
                                    <p className="font-12">Información básica del contacto</p>
                                </div>
                                <div className="col-8">
                                    <div className="m-b-24">
                                        <Input label='Nombre(s) *' errors={errors.first_name} {...register('first_name', { required: true })} />
                                    </div>
                                    <div className="m-b-24">
                                        <Input label='Apellidos *' errors={errors.last_name} {...register('last_name', { required: true })} />
                                    </div>
                                    <div className="m-b-24">
                                        <label className="form-label" >Tipo de contacto *</label>
                                        <Controller
                                            control={control}
                                            name='type'
                                            rules={{ required: true }}
                                            render={({
                                                field: { onChange, onBlur, value },
                                                fieldState: { error },
                                            }) => {

                                                const options = [
                                                    {
                                                        value: 'DOCTOR',
                                                        label: 'Médico'
                                                    },
                                                    {
                                                        value: 'PHARMACY',
                                                        label: 'Farmacia'
                                                    },
                                                    {
                                                        value: 'OTHER',
                                                        label: 'Otro'
                                                    },
                                                ]

                                                return (
                                                    <Select
                                                        placeholder='Seleccionar un tipo de contacto'
                                                        options={options}
                                                        className={error ? 'form-select-custom is-invalid' : ''}
                                                        classNamePrefix='form-select-custom'
                                                        onBlur={onBlur}
                                                        onChange={(value: any) => onChange(value?.value)}
                                                        isClearable
                                                        value={options.find((a: any) => a.value === value) || ''}
                                                    />
                                                )
                                            }}
                                        />
                                        <span className="invalid-feedback">Campo obligatorio.</span>
                                    </div>
                                    {
                                        typeContact === 'DOCTOR' && (
                                            <div className="m-b-24">
                                                <label className="form-label" >Especialidad *</label>
                                                <Controller
                                                    control={control}
                                                    name='medical_specialty'
                                                    rules={{ required: true }}
                                                    render={({
                                                        field: { onChange, onBlur, value },
                                                        fieldState: { error },
                                                    }) => {
                                                        return (
                                                            <Select
                                                                placeholder='Seleccionar una especialidad médica'
                                                                options={medicalSpecialties}
                                                                className={error ? 'form-select-custom is-invalid' : ''}
                                                                classNamePrefix='form-select-custom'
                                                                onBlur={onBlur}
                                                                onChange={(value: any) => onChange(value?.value)}
                                                                isClearable
                                                                value={medicalSpecialties.find((a: any) => a.value === value) || ''}
                                                            />
                                                        )
                                                    }}
                                                />
                                                <span className="invalid-feedback">Campo obligatorio.</span>
                                            </div>
                                        )
                                    }


                                </div>
                            </div>

                            <div className="row m-b-40">
                                <div className="col-4">
                                    <p className="text-dark fw-bold m-b-8 font-12 text-uppercase">Contacto</p>
                                    <p className="font-12">Puntos de contacto con el contacto</p>
                                </div>
                                <div className="col-8">
                                    <div className="m-b-24">
                                        <Input label='Correo electrónico' optional errors={errors.email} {...register('email', { required: false })} />
                                    </div>
                                    <div className="m-b-24">
                                        <label className="form-label" >Número de teléfono <span className="form-label-secondary">(Opcional)</span></label>
                                        <div className={'d-flex ' + (errors?.phone_number ? 'is-invalid' : '')}>
                                            <Controller
                                                control={control}
                                                name='country_code'
                                                rules={{ required: false }}
                                                render={({
                                                    field: { onChange, value },
                                                    fieldState: { error },
                                                }) => (
                                                    <SelectCountries
                                                        className={error ? 'form-select-custom is-invalid' : ''}
                                                        onChange={onChange}
                                                        value={value}
                                                    />
                                                )}
                                            />
                                            <input type="text" className={'form-control form-control-lg ms-2 ' + (errors?.phone_number ? 'is-invalid' : '')}{...register('phone_number', { required: false })} />
                                        </div>
                                        <span className="invalid-feedback">Campo obligatorio.</span>
                                    </div>
                                </div>
                            </div>

                            <div className="row ">
                                <div className="col-4">
                                    <p className="text-dark fw-bold m-b-8 font-12 text-uppercase">Acerca de</p>
                                    <p className="font-12">Datos del perfil en Lipper</p>
                                </div>
                                <div className="col-8">
                                    <div className="m-b-24">
                                        <label className="form-label" >ruta o id *</label>
                                        <Controller
                                            control={control}
                                            name='assigned_id'
                                            rules={{ required: true }}
                                            render={({
                                                field: { onChange, onBlur, value },
                                                fieldState: { error },
                                            }) => (
                                                <Select
                                                    placeholder='Seleccionar ruta o id'
                                                    options={assignments}
                                                    className={error ? 'form-select-custom is-invalid' : ''}
                                                    classNamePrefix='form-select-custom'
                                                    onBlur={onBlur}
                                                    onChange={(value: any) => onChange(value?.value)}
                                                    isClearable
                                                    value={assignments.find((a: any) => a.value === value) || ''}
                                                />
                                            )}
                                        />
                                        <span className="invalid-feedback">Campo obligatorio.</span>
                                    </div>
                                    <div className="m-b-24">
                                        <label className="form-label" >Segmentación *</label>
                                        <Controller
                                            control={control}
                                            name='segmentations'
                                            rules={{ required: true }}
                                            render={({
                                                field: { onChange, onBlur, value },
                                                fieldState: { error },
                                            }) => (
                                                <Select
                                                    placeholder='Seleccionar segmentos'
                                                    options={segments}
                                                    isMulti
                                                    className={error ? 'form-select-custom is-invalid' : ''}
                                                    classNamePrefix='form-select-custom'
                                                    onBlur={onBlur}
                                                    onChange={(values: any) => onChange(values.map((v: any) => v.value))}
                                                    value={segments.filter((s: any) => (value as Array<any>).indexOf(s.value) !== -1)}
                                                />
                                            )}
                                        />
                                        <span className="invalid-feedback">Campo requerido.</span>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-soft-primary btn-sm" onClick={() => onRequestClose(false)}>Cerrar</button>
                            <Button type="submit" className="btn btn-primary btn-sm" disabled={!isValid} isLoading={isSubmitting}>Guardar contacto</Button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
export default ContactFormModal