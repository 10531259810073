import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Aside from "../../components/Aside"
import Footer from "../../components/Footer"
import Header from "../../components/Header"
import { MaterialService } from "../../services/materialService"
import moment from 'moment';
import StatusMaterial from "../../components/StatusMaterial"
import EmptyState from "../../components/EmptyState"
import HandleNoData from "../../components/HandleNoData"
import Allow from "../../guards/Allow"
import { formatType } from "../../utils/formatTypeContentRepository"

declare const bootstrap: any;

function MaterialsHomePage() {

  const [materials, setMaterials] = useState([])
  const [filter, setFilter] = useState<'DRAFT' | 'EXPIRED' | 'RELEASE'>('DRAFT')

  const initTooltip = () => {
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl)
    })
  }



  useEffect(() => {
    initTooltip();
  }, [materials, filter])
  

  useEffect(() => {
    const getMaterials = async () => {
      const response = await MaterialService.getAll();
      setMaterials(response)
    }

    getMaterials();
  }, [])

  return (
    <>
      <Header>
        <div>
          <h4>Materiales</h4>
          <p>Administra los materiales.</p>
        </div>

        <Allow access={['admin', 'agency']}>
          <div className="ms-auto">
            <Link to={'/materials/form'}>
              <button type="button" className="btn btn-primary btn-sm" >añadir nuevo material <i className="bi bi-plus-circle-fill m-l-8"></i></button>
            </Link>
          </div>
        </Allow>
      </Header>

      <Aside />

      <main role="main" className="main">

        <div className="bg-white border-bottom">
          <div className="container p-t-12 p-b-12 px-0 px-5">
            <ul className="nav nav-pills ">
              <li className="nav-item font-12 m-0 me-2">
                <button className={'nav-link fw-bold ' + (filter === 'DRAFT' ? 'active' : 'text-reset')} onClick={() => setFilter('DRAFT')}>
                  EN REVISÓN
                </button>
              </li>
              <li className="nav-item font-12 m-0 me-2"  >
                <button className={'nav-link fw-bold ' + (filter === 'RELEASE' ? 'active' : 'text-reset')} onClick={() => setFilter('RELEASE')}>
                  APROBADOS
                </button>
              </li>
              <li className="nav-item font-12 m-0"  >
                <button className={'nav-link fw-bold ' + (filter === 'EXPIRED' ? 'active' : 'text-reset')} onClick={() => setFilter('EXPIRED')}>
                  PAPELERA
                </button>
              </li>
            </ul>
          </div>
        </div>

        <div className="bg-white border-bottom ">
          <div className="container p-t-12 p-b-12 px-5">
            <div className="d-flex align-items-center">
              <i className="bi bi-search m-l-8"></i>
              <input className="form-control input-group-borderless form-control-flush" type="text" placeholder="Buscar" />
            </div>
          </div>
        </div>

        <div className="content container px-5">

          <HandleNoData
            condition={
              materials
                .filter((m: any) => filter === 'RELEASE' ? m.status === 'ACTIVE' || m.status === 'INACTIVE' : m.status === filter)
                .length
            }
            emptyComponent={<EmptyState />}
          >
            <table className="card-table table">
              <thead>
                <tr>
                  <th scope="col">NOMBRE </th>
                  <th scope="col">Segmentos </th>
                  <th scope="col" className="text-center">versión</th>
                  <th scope="col" className="text-center">fecha de creación</th>
                  {filter === 'RELEASE' && <th scope="col" className="text-center">fecha de publicación</th>}
                  <th scope="col" className="text-center">validadores</th>
                  <th scope="col" className="text-center">estatus</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {
                  materials
                    .filter((m: any) => filter === 'RELEASE' ? m.status === 'ACTIVE' || m.status === 'INACTIVE' : m.status === filter)
                    .map((m: any) => (
                      <tr key={m._id}>
                        <td>
                          <p className="fw-semibold text-dark">{m.name}</p>
                          <p>{formatType(m.type)}</p>
                        </td>
                        <td>

                          {
                            (!m.segmentations || m.segmentations.length === 0) && (
                              <div className="badge bg-soft-danger text-danger" role="badge">
                                  Material sin segmentos
                              </div>
                            )
                          }
                          { m.segmentations ? m.segmentations.slice(0, 2).map( s => s.title ).join(', '): '' }
                          {  m.segmentations && m.segmentations.length > 2 && <span className="badge bg-soft-dark text-dark ms-1" data-bs-toggle="tooltip" data-bs-html="true" title={`${m.segmentations.slice(2).map( s => s.title ).join(', ')}`}>+{m.segmentations.length -2}</span>}
                        </td>
                        <td className="text-center">{m.version}</td>
                        <td className="text-center">{moment(m.created_at).format('DD-MM-YY')}</td>
                        {filter === 'RELEASE' && <td className="text-center">{moment(m.publish_at).format('DD-MM-YY')}</td> }
                        <td className="text-center">
                        {
                            (!m.validators || m.validators.length === 0) && (
                              <div className="badge bg-soft-danger text-danger" role="badge">
                                  Material sin validadores
                              </div>
                            )
                          }

                          {
                            m.validators.map((v: any) => (
                              <div className="avatar avatar-circle " key={v.collaborator._id}>
                                <img className="avatar-img" src={v.collaborator.avatar} alt={`${v.collaborator.first_name} ${v.collaborator.last_name}`} />
                                {v.status === 'IN_REVIEW' && <span className="avatar-status avatar-sm-status avatar-status-info"></span>}
                                {v.status === 'REJECTED' && <span className="avatar-status avatar-sm-status avatar-status-danger"></span>}
                                {v.status === 'APPROVED' && <span className="avatar-status avatar-sm-status avatar-status-success"></span>}
                              </div>
                            ))
                          }
                        </td>
                        <td className="text-center">
                          <StatusMaterial status={m.status} validators={m.validators} />
                        </td>
                        <td>
                          <Link to={`/materials/detail/${m._id}`}>
                            <button type="button" className="btn btn-outline-primary btn-outline-edit btn-icon m-l-8" >
                              <i className="bi bi-eye-fill"></i>
                            </button>
                          </Link>
                        </td>
                      </tr>
                    ))
                }
              </tbody>
            </table>

          </HandleNoData>
        </div>

        <Footer />
      </main>


    </>
  )
}

export default MaterialsHomePage