
class MessageParseService {

    private static instance: MessageParseService;

    public static getInstance(): MessageParseService {
        if (!MessageParseService.instance) {
            MessageParseService.instance = new MessageParseService();
        }
        return MessageParseService.instance;
    }


    parseTxt(value: string): string {
        value = this.parseItalic(value);
        value = this.parseStrike(value);
        value = this.parseBold(value);
        value = this.parseURL(value);
        value = this.parseNewLine(value);

        return value;
    }

    private parseNewLine(value: string): string {
        const expr = /\n\t\r|\n|\t|\r/g;
        if (expr.test(value)) {
            return value.replace(expr, '<br>');
        }

        return value;
    }

    private parseBold(value: string): string {
        const expr = /(?:\*)([^*]+)(?:\*)/g;
        if (expr.test(value)) {
            return value.replace(expr, '<strong>$1</strong>');
        }

        return value;
    }

    private parseItalic(value: string): string {
        const expr = /(?:\_)([^_]+)(?:\_)/g;
        if (expr.test(value)) {
            return value.replace(expr, '<i>$1</i>');
        }
        return value;
    }

    private parseStrike(value: string): string {
        const expr = /(?:\~)([^~]+)(?:\~)/g;
        if (expr.test(value)) {
            return value.replace(expr, '<strike>$1</strike>');
        }
        return value;
    }

    private parseURL(value: string): string {
        const expr = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
        if (expr.test(value)) {
            return value.replace(expr, '<a href="$1" class="white-link" target="_blank">$1</a>');
        }
        return value;
    }
}

export default MessageParseService;