import DefaultMessagePartial from './DefaultMessagePartial'
import RoutesPartial from './RoutesPartial'
import SegmentsPartial from './SegmentsPartial'
import TemplatesPartial from './TemplatesPartial'

function TabContentPage({ partial, goTo }: any) {
    return (
        <div className='row'>
            <div className='col-4'>
                <div className='card'>
                    <div className='card-body p-0 py-2'>
                        <div className={'d-flex align-items-start p-3 cursor-pointer  ' + (!partial || partial === 'segments' ? 'bg-light' : '')} onClick={() => goTo('content', 'segments')}>
                            <img src={require('../../assets/icons/pie_chart.png')} className='me-3' style={{ width: '20px', height: 'auto' }} alt='Segmentos' />
                            <div>
                                <p className='text-dark fw-semibold'>Segmentos</p>
                                <p>Configura tus públicos.</p>
                            </div>
                        </div>
                        <div className={'d-flex align-items-start p-3 cursor-pointer  ' + (partial === 'ids' ? 'bg-light' : '')} onClick={() => goTo('content', 'ids')}>
                            <img src={require('../../assets/icons/pin_drop.png')} className='me-3' style={{ width: '20px', height: 'auto' }} alt='Rutas o IDs' />
                            <div>
                                <p className='text-dark fw-semibold'>Rutas o IDs</p>
                                <p>Configura tus grupos de agentes.</p>
                            </div>
                        </div>
                        <div className={'d-flex align-items-start p-3 cursor-pointer  ' + (partial === 'templates' ? 'bg-light' : '')} onClick={() => goTo('content', 'templates')}>
                            <img src={require('../../assets/icons/message.png')} className='me-3' style={{ width: '20px', height: 'auto' }} alt='Plantillas (Whatsapp)' /> 
                            <div>
                                <p className='text-dark fw-semibold'>Plantillas de Whatsapp</p>
                                <p>Consulta tus plantillas de texto aprobadas.</p>
                            </div>
                        </div>
                        <div className={'d-flex align-items-start p-3 cursor-pointer  ' + (partial === 'default-message' ? 'bg-light' : '')} onClick={() => goTo('content', 'default-message')}>
                            <img src={require('../../assets/icons/message.png')} className='me-3' style={{ width: '20px', height: 'auto' }} alt='Plantillas (Whatsapp)' /> 
                            <div>
                                <p className='text-dark fw-semibold'>Mensaje de bienvenida</p>
                                <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-8'>
                <div className={(!partial || partial === 'segments') ? '': 'd-none'}>
                    <SegmentsPartial />
                </div>
                <div className={(partial === 'ids') ? '': 'd-none'}>
                    <RoutesPartial />
                </div>
                <div className={(partial === 'templates') ? '': 'd-none'}>
                    <TemplatesPartial />
                </div>
                <div className={(partial === 'default-message') ? '': 'd-none'}>
                    <DefaultMessagePartial />
                </div>
            </div>
        </div>
    )
}

export default TabContentPage