import { round } from "./number"


export const formatTime = (time: number) => {
    if(time < 60) {
        return `${round(time)} seg`
    }
    
    let min = time/60

    return `${round(min)} min`
}