import { useLocation, useNavigate } from "react-router-dom";
import Aside from "../../../components/Aside";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import { Doughnut } from 'react-chartjs-2';
import { useEffect, useMemo, useState } from "react";
import { ReportService } from "../../../services/reportService";
import Tooltip from "../../../components/Tooltip";
import * as XLSX from 'xlsx';

function MaterialsDetailPoll() {

  const navigate = useNavigate();

  const { state: { material } } = useLocation()

  const [list, setList] = useState([])

  const formatType = (type) => {
    switch (type) {
      case 'PDF':
        return <span className="badge badge-pdf">PDF</span>
      case 'IMAGE':
        return <span className="badge badge-image">Imagen</span>
      case 'VIDEO':
        return <span className="badge badge-video">Video</span>
      case 'AUDIO':
        return <span className="badge badge-audio">Audio</span>
      case 'LINK':
        return <span className="badge badge-link">Link</span>
      case 'FORM':
        return <span className="badge badge-form">Encuesta</span>
      case 'POLL':
        return <span className="badge badge-poll">Votación</span>

      default:
        break;
    }
  }

  const getDetail = async () => {
    const response = await ReportService.getMaterialDetail(material.content_repository._id)
    setList(response)
  }

  const dowload = () => {
    const workbook = XLSX.utils.book_new();

    let header = [
        'CONTACTO',
        'RUTA O ID',
        'SEGMENTO(S)',
        'PREGUNTA',
        'RESPUESTA'
    ]

    const data = list.map( row => {
        return {
            'CONTACTO': row.contact_detail.contact.first_name +' ' +row.contact_detail.contact.last_name ,
            'RUTA O ID': row.contact_detail.assigned_id ,
            'SEGMENTO(S)': row.contact_detail.segmentations.map( s => s.title).join(', ') ,
            'PREGUNTA': material.content_repository.content.question,
            'RESPUESTA': row.answers ? row.answers.my_answers : '-'
        }
    });
    
    const worksheet = XLSX.utils.json_to_sheet(data, { header })

    XLSX.utils.book_append_sheet(workbook, worksheet);

    XLSX.writeFile(workbook, `poll.xlsx`);
}


  useEffect(() => {
    getDetail()
  }, [material])



  const dataChart = useMemo(() => {
    const labels = material.content_repository.content.answers;
    let data = labels.map(l => list.filter(row => row?.answers?.my_answers === l).length)

    return {
      labels: labels,
      datasets: [
        {
          label: ' Total',
          data: data,
          backgroundColor: [
            'rgba(0, 61, 115, 1)',
            // 'rgba(73, 165, 244, 1)',
            'rgba(0, 130, 242, 1)',
            'rgba(123, 173, 217, 1)',
            'rgba(192, 215, 235, 1)',
          ],
          borderColor: [
            '#f9fafa',
          ],
          // circumference: 180,
          // rotation: 270
        },
      ],
    }
  }, [material, list])

  return (
    <>
      <Header>
        <div>
          <h4>{material.content_repository.name}</h4>
          <p>{formatType(material.content_repository.type)}</p>
        </div>

        <div className="ms-auto">
          <button type="button" className="btn btn-primary btn-sm nowrap" onClick={dowload}><i className="bi-download"></i> Descargar</button>
        </div>

      </Header>

      <Aside />

      <main role="main" className="main">

        <div className="content container px-5">
          <div className="row">

            <div className="col-12 m-b-32">
              <button type="button" className="btn btn-link text-dark text-decoration-underline px-0" onClick={() => navigate(-1)} ><i className="bi bi-arrow-left me-2"></i>  Detalle de materiales</button>
            </div>

            <div className='col-12 m-b-24'>
              <div className="card ">
                <div className="card-body d-flex">
                  <div className='mx-4 text-center flex-grow-1 '>
                    <h3 className="text-center">{material.total_sent}</h3>
                    <p className='nowrap'>Total envios <Tooltip className='ms-1 color-icon' title='Total envios' ><i className="bi-info-circle-fill"></i></Tooltip></p>
                  </div>
                  <div className='border-lg-end'></div>
                  <div className='mx-4 text-center flex-grow-1 '>
                    <h3 className="text-center">{material.unique_sent}</h3>
                    <p className='nowrap'>Envios únicos <Tooltip className='ms-1 color-icon' title='Envios únicos' ><i className="bi-info-circle-fill"></i></Tooltip></p>
                  </div>
                  <div className='border-lg-end'></div>
                  <div className='mx-4 text-center flex-grow-1 '>
                    <h3 className="text-center">{material.open_link}</h3>
                    <p className='nowrap'>Clics <Tooltip className='ms-1 color-icon' title='Clics' ><i className="bi-info-circle-fill"></i></Tooltip></p>
                  </div>
                  <div className='border-lg-end'></div>
                  <div className='mx-4 text-center flex-grow-1 '>
                    <h3 className="text-center">{material.open_content}</h3>
                    <p className='nowrap'>Aperturas <Tooltip className='ms-1 color-icon' title='Aperturas' ><i className="bi-info-circle-fill"></i></Tooltip></p>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div className="row m-b-28">
            <div className="col-12">
              <div className="card ">
                <div className="card-body d-flex p-t-32 p-r-32 p-b-32 p-l-32">

                  <div className="flex-grow-1 my-5 me-5">
                    <p className="title-secondary m-b-8">PREGUNTA</p>
                    <h4>{material.content_repository.content.question}</h4>
                  </div>

                  <div className="me-auto">
                    <div >
                      <Doughnut data={dataChart} options={{
                        plugins: {
                          legend: { position: 'left' }
                        },
                        maintainAspectRatio: false,
                        // responsive: true,
                        // aspectRatio: 4
                      }}
                        height={200}
                        width={300}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <table className="card-table table">
            <thead>
              <tr>
                <th scope="col">Contacto </th>
                <th scope="col" className="text-center">RUTA O ID </th>
                <th scope="col" className="text-center">Segmento(s)</th>
                <th scope="col" className="text-center">Respuesta</th>
              </tr>
            </thead>
            <tbody>

              {
                list.map(row => (
                  <tr key={row._id}>
                    <td>{row.contact_detail.contact.first_name} {row.contact_detail.contact.last_name}</td>
                    <td className="text-center">{row.contact_detail.assigned_id}</td>
                    <td className="text-center">{row.contact_detail.segmentations.map(s => s.title).join(', ')}</td>
                    <td className="text-center">
                      {
                        !row.answers ? (
                          <span className="badge bg-soft-danger text-danger">Sin respuesta</span>
                        ) :
                          (row.answers.my_answers)
                      }
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>

        <Footer />
      </main>


    </>
  )
}
export default MaterialsDetailPoll