import { END_POINT } from "../constants";
import { HTTP } from "./http";

export const CollaboratorService = {

    getCollaborators: () => {
        return HTTP.get<any, any>(`${END_POINT}collaborator`);
    },
    getReviewers: () => {
        return HTTP.get<any, any>(`${END_POINT}collaborator/reviewers`);
    },
    getAgents: () => {
        return HTTP.get<any, any>(`${END_POINT}collaborator/agent`);
    },
    save: (data: any) => {
        return HTTP.post<any, any>(`${END_POINT}collaborator`, data);
    },
    update: (data: any) => {
        return HTTP.put<any, any>(`${END_POINT}collaborator`, data);
    },
    resendInvite: (data: any) => {
        return HTTP.post<any, any>(`${END_POINT}collaborator/resend-invite`, data);
    },
}