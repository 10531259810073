import React, { useCallback } from "react";

export default React.forwardRef(({ type = 'text', optional, label, errors, children, ...register }: any, ref) => {


     
    const getType = useCallback(() => {
        if(!errors) return '';
        
        switch (errors?.type) {
            case 'required':
                return `Campo obligatorio.`
            case 'maxLength':
                return `Sólo se permiten máximo ${errors.message} caracteres`
            case 'minLength':
                return `Se require un mínimo de ${errors.message} caracteres`
            case 'min':
                return `${errors.message}`
            case 'max':
                return `${errors.message}`
            case 'pattern':
            case 'custom':
                return `${errors.message}`
        
            default:
                return `${errors.type}`
        }
    }, [errors?.type])


    return (
        <>
            <label className="form-label" >{label} {optional && <span className="form-label-secondary">(Opcional)</span>}</label>
            <div className={'d-flex ' + (errors ? 'is-invalid' : '')}>
                <input type={type} ref={ref} className={'form-control form-control-lg ' + (errors ? 'is-invalid' : '')} {...register} />
                {children}
            </div>

            <span className="invalid-feedback">
                {getType()}
            </span>
        </>
    )
})