import { useEffect, useState } from "react";
import { STORAGE } from "../constants";
import { LocalStorageService } from "../services/localStoraService";

function ReviewerAccess({children}) {

    const [isReviewer, setIsReviewer] = useState(false)

    useEffect(() => {

        const session = LocalStorageService.get(STORAGE.SESSION);

        if (session) {
            setIsReviewer(session.role.key === 'reviewer')
        }

    }, [])
    
    return isReviewer ? children: null;
}
export default ReviewerAccess