import { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import './index.scss';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import LoginPage from './pages/auth/LoginPage';
import TwoStepAuthPage from './pages/auth/TwoStepAuthPage';
import ResetPasswordPage from './pages/auth/ResetPasswordPage';
import ChangePasswordPage from './pages/auth/ChangePasswordPage';
import ContactHomePage from './pages/contacts/ContactHomePage';
import CollaboratorHomePage from './pages/collaborators/CollaboratorHomePage';
import RootConfigPage from './pages/config/RootConfigPage';
import { LocalStorageService } from './services/localStoraService';
import { STORAGE } from './constants';
import NotFoundPage from './pages/404/NotFoundPage';
import MaterialsHomePage from './pages/materials/MaterialsHomePage';
import MaterialFormPage from './pages/materials/MaterialFormPage';
import MaterialDetailPage from './pages/materials/MaterialDetailPage';
import Dashboard from './pages/dashboard/Dashboard';
import HTMLSDetail from './pages/dashboard/htmls/HTMLSDetail';
import MaterialsDetail from './pages/dashboard/materials/MaterialsDetail';
import MaterialsDetailResults from './pages/dashboard/materials/MaterialsDetailResults';
import MaterialsDetailPoll from './pages/dashboard/materials/MaterialsDetailPoll';
import MaterialsDetailSurvey from './pages/dashboard/materials/MaterialsDetailSurvey';
import RolesGuard from './guards/RolesGuard';
import DocumentationPage from './pages/documentation/DocumentationPage';
import CampaignHomePage from './pages/campaigns/CampaignHomePage';
import CampaignFormPage from './pages/campaigns/CampaignFormPage';
import InboxPage from './pages/indox/InboxPage';

declare const HSSideNav: any;

const PrivateRoute = () => {


  useEffect(() => {
      new HSSideNav('.js-navbar-vertical-aside').init() 

      document.body.className = 'has-navbar-vertical-aside navbar-vertical-aside-show-xl footer-offset bg-main';
      
      return () => {
          document.body.className = '';
      };
  }, [])

  const session = LocalStorageService.get(STORAGE.SESSION);

  return !session ? <Navigate to='/auth/login' replace /> : <Outlet />;
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
      <Routes >
        <Route path="/auth" >
          <Route path="login" element={<LoginPage />} />
          <Route path="two-factor/verify" element={<TwoStepAuthPage />} />
          <Route path="reset-password" element={<ResetPasswordPage />} />
          <Route path="change-password/:token" element={<ChangePasswordPage />} />
        </Route>

        <Route path="/" element={<PrivateRoute />}>  
          <Route path="/" element={<RolesGuard allow={['admin']} />}>
            <Route path="" element={<Dashboard />} />
            <Route path="dashboard/materials" element={<MaterialsDetail />} />
            <Route path="dashboard/materials/detail" element={<MaterialsDetailResults />} />
            <Route path="dashboard/materials/poll" element={<MaterialsDetailPoll />} />
            <Route path="dashboard/materials/survey" element={<MaterialsDetailSurvey />} />
            <Route path="dashboard/htmls" element={<HTMLSDetail />} />
          </Route>
          <Route path="/inbox" element={<RolesGuard allow={['admin']} />}>
            <Route path="" element={<InboxPage />} />
          </Route>
          <Route path="/collaborators" element={<RolesGuard allow={['admin']} />}>
            <Route path="" element={<CollaboratorHomePage />} />
          </Route>
          <Route path="/contacts" element={<RolesGuard allow={['admin']} />}>
            <Route path="" element={<ContactHomePage />} />
          </Route>
          <Route path="/materials" element={<RolesGuard allow={['admin','reviewer','agency']} />}>
            <Route path="" element={<MaterialsHomePage />} />
            <Route path="form" element={<MaterialFormPage />} />
            <Route path="detail/:id" element={<MaterialDetailPage />} />
          </Route>
          <Route path="/documentation" element={<RolesGuard allow={['agency']} />}>
            <Route path="" element={<DocumentationPage />} />
          </Route>
          <Route path="/config" element={<RolesGuard allow={['admin']} />}>
            <Route path="" element={<RootConfigPage />} />
          </Route>
          <Route path="/campaigns" element={<RolesGuard allow={['admin']} />}>
            <Route path="" element={<CampaignHomePage />} />
            <Route path="form" element={<CampaignFormPage />} />
          </Route>
        </Route>

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
