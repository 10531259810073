import SyntaxHighlighter from 'react-syntax-highlighter';

function ClicksPartial() {
    return (
        <div className="card">

            <div className="card-body">
                <h5 className="card-title">Clics</h5>
                <p className="card-text">Además de registrarse la actividad de los slides, Projecter Bridge permite registrar eventos de clic específicos como por ejemplo, abrir un pop-up, dar clic en algún botón, etc.</p>
                
            
                <div className='mt-3'>
                    <SyntaxHighlighter language="javascript" showInlineLineNumbers >
                        {
                            `
windows.ProjecterBridge.click("Pop-up más información");
windows.ProjecterBridge.click("Pop-up más información");

`
                        }
                    </SyntaxHighlighter>
                </div>
                
            </div>
        </div>
    )
}
export default ClicksPartial