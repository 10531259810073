import SchoolIcon from '@mui/icons-material/School';
import Tooltip from '../../../components/Tooltip';
import { useNavigate } from 'react-router-dom';
import { clone } from '../../../utils/clone';

function MaterialsWidget({ highlights, range }) {

  const navigate = useNavigate();

  const goToDetail = () => {
    navigate('/dashboard/materials', { state: { prevHighlights: clone(highlights), range: clone(range) } })
  }

  return (
    <div className="card h-100">
      <div className="card-body">
        <div className="d-flex flex-between-center m-b-5">
          <div className="d-flex align-items-center">
            <div className="icon-item icon-item-sm m-r-8 bg-soft-purple text-purple">
              <SchoolIcon fontSize="inherit" />
            </div>
            <h6 className="m-b-0">Materiales</h6>
          </div>
          <button className="btn  btn-custom-link btn-sm" type='button'  onClick={goToDetail}>
            Ver detalle <i className="bi-box-arrow-up-right ms-2"></i>
          </button>
        </div>

        <div className='d-flex my-5'>
          <div className='mx-4 '>
            <h1 className="text-center">{highlights.total_materials}</h1>
            <p className='nowrap'>Total enviados <Tooltip className='ms-1 color-icon' title='Total de plantillas de material enviadas.' ><i className="bi-info-circle-fill"></i></Tooltip></p>
          </div>
          <div className='border-lg-end'></div>
          <div className='flex-grow-1 mx-4'>

            <div className='d-flex align-items-center'>
              <h4>{highlights.total_unique_sent}</h4>
              <p className='ms-3 nowrap'>Envios únicos <Tooltip className='ms-1 color-icon' title='Total de plantillas de material enviadas a contactos únicos.' ><i className="bi-info-circle-fill"></i></Tooltip></p>
            </div>
            <div className='d-flex align-items-center'>
              <h4>{highlights.total_opened}</h4>
              <p className='ms-3 nowrap'>Clics totales <Tooltip className='ms-1 color-icon' title='Total de clics de contactos únicos a plantillas de material.' ><i className="bi-info-circle-fill"></i></Tooltip></p>
            </div>
            <div className='d-flex align-items-center'>
              <h4>{highlights.total_viewed}</h4>
              <p className='ms-3 nowrap'>Aperturas <Tooltip className='ms-1 color-icon' title='Total de aperturas de materiales por contactos únicos.' ><i className="bi-info-circle-fill"></i></Tooltip></p>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default MaterialsWidget