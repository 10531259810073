import { useEffect, useMemo, useRef, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import Aside from "../../components/Aside"
import DragAndDrop from "../../components/DragAndDrop"
import Footer from "../../components/Footer"
import Header from "../../components/Header"
import Input from "../../components/Input"
import TextArea from "../../components/TextArea"
import { CollaboratorService } from "../../services/collaboratorService"
import { SegmentService } from "../../services/segmentService"
import Select from 'react-select'
import Button from "../../components/Button"
import { MaterialService } from "../../services/materialService"
import Alert from "../../components/Alert"
import { useNavigate } from "react-router-dom"
import PollModal from "./PollModal"
import FormModal from "./FormModal"

declare const flatpickr: any;

function MaterialFormPage() {

    const datepickerRef = useRef()

    const typeMaterial = [
        {
            value: 'PDF',
            label: 'PDF',
        },
        {
            value: 'IMAGE',
            label: 'Imagen',
        },
        {
            value: 'VIDEO',
            label: 'Video',
        },
        {
            value: 'AUDIO',
            label: 'Audio',
        },
        {
            value: 'LINK',
            label: 'Link',
        },
        {
            value: 'FORM',
            label: 'Encuesta',
        },
        {
            value: 'POLL',
            label: 'Votación',
        },
        {
            value: 'HTML_SPACE',
            label: 'HTML',
        },
        {
            value: 'HTML',
            label: 'CLM iPad',
        },
    ]
    
    const defaultValues = {
        name: '',
        description: '',
        segmentations: [],
        validators: [],
        expiration_date: '',
        poster: '',
        type: '',

        file: '',
        link: '',
        poll: {
            question: '',
            answers: []
        },
        form: [],
        agents_can_view_results: false
    }
    const { control, register, watch, handleSubmit, setValue, getValues, setError, resetField, clearErrors,  formState: { errors, isValid, isSubmitting } } = useForm({
        mode: 'onChange',
        defaultValues
    })

    register('form')
    register('poll')

    const typeMaterialSelected = watch('type');
    const pollData = watch('poll');
    const formData = watch('form');

    const navigate = useNavigate();

    const [reviewers, setReviewers] = useState([])
    const [segmentations, setSegmentations] = useState([])
    
    const [showModalPoll, setShowModalPoll] = useState(false)
    const [showModalForm, setShowModalForm] = useState(false)
    const [question, setQuestion] = useState(null)

    const submit = async (data: any) => {
        try {
            const form = new FormData()

            form.append('name', data.name)
            form.append('description', data.description)
            form.append('poster', data.poster)
            form.append('type', data.type)
            form.append('expiration_date', data.expiration_date)

            data.segmentations.forEach((s: any) => form.append('segmentations[]', s));
            data.validators.forEach((v: any) => form.append('validators[]', v));

            if (
                data.type === 'PDF' ||
                data.type === 'IMAGE' ||
                data.type === 'VIDEO' ||
                data.type === 'AUDIO' ||
                data.type === 'HTML'  ||
                data.type === 'HTML_SPACE'
            ) {
                form.append('file', data.file)
            }

            if (data.type === 'LINK') {
                form.append('link', data.link)
            }
            
            if (data.type === 'POLL') {
                form.append('poll[question]', data.poll.question)
                for (const answer of data.poll.answers) {
                    form.append('poll[answers][]', answer)    
                }
            }
            if (data.type === 'FORM' || data.type === 'POLL') {
                form.append('agents_can_view_results', data.agents_can_view_results)
            }
            
            if (data.type === 'FORM') {
                for (let index = 0; index < data.form.length; index++) {
                    const item = data.form[index];
                    form.append(`form[${index}][question]`, item.question)   
                    form.append(`form[${index}][is_mandatory]`, item.is_mandatory)   
                    form.append(`form[${index}][type]`, item.type)   

                    if(item.type === 'SELECT' || item.type === 'MULTISELECT') {
                        for (const answer of item.answers) {  
                            form.append(`form[${index}][answers][]`, answer)
                        }
                    }
                }
            }

            await MaterialService.save(form, (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total);
                console.log(`${loaded} bytes of ${total} bytes. ${percent}%`);
            });

            Alert.fire({
                title: 'Material añadido',
                text: `El material se encuentra como borrador en revisión.`
            });
            navigate(`/materials`);

            // reset(defaultValues);
        } catch (error) {
            console.error(error);
        }
    }

    const savePollData = (data) => {
        if(data) {
            setValue('poll', data)
        }

        setShowModalPoll(false)
    }

    const saveFormData = (data) => {
        if(data) {
            let form: any = getValues('form');

            if(data.id) {
                form = form.map( i => i.id === data.id ? data: i)
            } else {
                form.push({ ...data, id: form.length + 1, })
            }

            setValue('form', form)
        }

        setQuestion(null)
        setShowModalForm(false)
    }

    const editQuestionForm = (item) => {
        setQuestion(item)
        setShowModalForm(true)
    }

    const isValidForm = useMemo(() => isValid && !errors.form && !errors.poll, [isValid, errors.form, errors.poll])

    const acceptExt = useMemo(() => {
        switch (typeMaterialSelected) {
            case 'PDF':
                return '.pdf'
            case 'IMAGE':
                return '.jpg,.jpeg,.png'
            case 'VIDEO':
                return '.mp4'
            case 'AUDIO':
                return '.mp3'
            case 'HTML':
            case 'HTML_SPACE':
                return '.zip'
        
            default:
                return '*'
        }
    }, [typeMaterialSelected])

    useEffect(() => {

        resetField('file')
        resetField('link')
        resetField('poll')
        resetField('form')

    }, [typeMaterialSelected])
   
    useEffect(() => {
        if(typeMaterialSelected === 'FORM') {
            formData.length ? clearErrors('form'): setError('form', {  type: 'required' })
        }
        if(typeMaterialSelected === 'POLL') {
            pollData.question ? clearErrors('poll'): setError('poll', {  type: 'required' })
        }
    }, [typeMaterialSelected, formData, pollData.question])
   
    
    useEffect(() => {
        const getReviewers = async () => {
            const response = await CollaboratorService.getReviewers();
            setReviewers(response.map((a: any) => ({ value: a._id, label: `${a.first_name} ${a.last_name}` })))
        }
        const getSegmentations = async () => {
            const response = await SegmentService.getActive()
            setSegmentations(response.map((a: any) => ({ value: a._id, label: a.title })))
        }

        flatpickr(datepickerRef.current, {
            // enableTime: true,
            dateFormat: 'd-m-Y',
            onChange: (selectedDates, dateStr) => {
                setValue('expiration_date', dateStr)
            }
        });

        getReviewers()
        getSegmentations()

    }, [])


    return (
        <>
            <form onSubmit={handleSubmit(submit)}>
                <Header>
                    <div>
                        <h4>Nuevo material</h4>
                    </div>

                    <div className="ms-auto">
                        <button type="button" className="btn btn-outline-primary btn-sm me-2"  onClick={() => navigate(`/materials`)}>cancelar</button>
                        <Button type="submit" className="btn btn-primary btn-sm" disabled={!isValidForm } isLoading={isSubmitting}>Guardar</Button>
                    </div>
                </Header>

                <Aside />

                <main role="main" className="main">


                    <div className="content container px-5">
                        <div className="row">
                            <div className="col-12 m-b-32">
                                <button type="button" className="btn btn-link text-dark text-decoration-underline px-0" onClick={() => navigate(`/materials`)}><i className="bi bi-arrow-left"></i>  Materiales</button>
                            </div>

                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="m-b-24">
                                            <Input label='nombre *' errors={errors.name} {...register('name', { required: true })} />
                                        </div>
                                        <div className="m-b-24">
                                            <TextArea label='descripción *' errors={errors.description} {...register('description', { required: true })} />
                                        </div>
                                        <div className="m-b-24">
                                            <label className="form-label" >Segmentación</label>
                                            <Controller
                                                control={control}
                                                name='segmentations'
                                                rules={{ required: false }}
                                                render={({
                                                    field: { onChange, onBlur, value },
                                                    fieldState: { error },
                                                }) => (
                                                    <Select
                                                        placeholder='Seleccionar segmentos'
                                                        options={segmentations}
                                                        isMulti
                                                        className={error ? 'form-select-custom is-invalid' : ''}
                                                        classNamePrefix='form-select-custom'
                                                        onBlur={onBlur}
                                                        onChange={(values: any) => onChange(values.map((v: any) => v.value))}
                                                        value={segmentations.filter((s: any) => (value as Array<any>).indexOf(s.value) !== -1)}
                                                    />
                                                )}
                                            />
                                            <span className="invalid-feedback">Campo requerido.</span>
                                        </div>

                                        <div className="m-b-24">
                                            <label className="form-label" >Validadores </label>
                                            <Controller
                                                control={control}
                                                name='validators'
                                                rules={{ required: false }}
                                                render={({
                                                    field: { onChange, onBlur, value },
                                                    fieldState: { error },
                                                }) => (
                                                    <Select
                                                        placeholder='Seleccionar validadores'
                                                        options={reviewers}
                                                        isMulti
                                                        className={error ? 'form-select-custom is-invalid' : ''}
                                                        classNamePrefix='form-select-custom'
                                                        onBlur={onBlur}
                                                        onChange={(values: any) => onChange(values.map((v: any) => v.value))}
                                                        value={reviewers.filter((s: any) => (value as Array<any>).indexOf(s.value) !== -1)}
                                                    />
                                                )}
                                            />
                                            <span className="invalid-feedback">Campo requerido.</span>
                                        </div>

                                        <div className="m-b-24">
                                            <label className="form-label" >Fecha de expiración</label>
                                            <input type="text" className={'form-control form-control-lg ' + (errors.expiration_date ? 'is-invalid' : '')} readOnly ref={datepickerRef} />
                                        </div>


                                        <div className="m-b-24">
                                            <label className="form-label" >Tipo de material *</label>
                                            <Controller
                                                control={control}
                                                name='type'
                                                rules={{ required: true }}
                                                render={({
                                                    field: { onChange, onBlur, value },
                                                    fieldState: { error },
                                                }) => (
                                                    <Select
                                                        placeholder='Seleccionar el tipo de material'
                                                        options={typeMaterial}
                                                        className={error ? 'form-select-custom is-invalid' : ''}
                                                        classNamePrefix='form-select-custom'
                                                        onBlur={onBlur}
                                                        onChange={(value: any) => onChange(value.value)}
                                                        value={typeMaterial.find((a: any) => a.value === value) || ''}
                                                    />
                                                )}
                                            />
                                            <span className="invalid-feedback">Campo requerido.</span>
                                        </div>

                                        {
                                            (typeMaterialSelected === 'FORM' || typeMaterialSelected === 'POLL') && (
                                                <div className="m-b-24">
                                                    <div className="form-check form-switch ">
                                                        <label className="form-check-label" htmlFor="agents_can_view_results">Permitir que los agentes vean los resultados de esta { typeMaterialSelected === 'FORM' ? 'encuesta': 'votación'}.</label>
                                                        <input type="checkbox" className="form-check-input form-check-info" id="agents_can_view_results" {...register('agents_can_view_results')} />
                                                    </div>
                                                </div>
                                            )
                                        }

                                        <div className="m-b-24">
                                            <Controller
                                                control={control}
                                                name='poster'
                                                rules={{ required: true }}
                                                render={({
                                                    field: { onChange, onBlur, value },
                                                    fieldState: { error },
                                                }) => (
                                                    <DragAndDrop
                                                        label='poster *'
                                                        sublabel='IMAGEN DE poster'
                                                        title='Arrastra una imagen aquí'
                                                        subtitle='Tamaño recomendado 800x800px'
                                                        className={error ? 'is-invalid' : ''}
                                                        onChange={(file: any) => onChange(file)}
                                                        accept='.png,.jpg,.jpeg'
                                                    />
                                                )}
                                            />
                                        </div>

                                        {
                                            (
                                                typeMaterialSelected === 'PDF' ||
                                                typeMaterialSelected === 'IMAGE' ||
                                                typeMaterialSelected === 'VIDEO' ||
                                                typeMaterialSelected === 'AUDIO' ||
                                                typeMaterialSelected === 'HTML'  ||
                                                typeMaterialSelected === 'HTML_SPACE'
                                            ) && (
                                                <div className="m-b-24">
                                                    <Controller
                                                        control={control}
                                                        name='file'
                                                        rules={{ required: true }}
                                                        render={({
                                                            field: { onChange, onBlur, value },
                                                            fieldState: { error },
                                                        }) => {
                                                            
                                                            return (
                                                                <DragAndDrop
                                                                    label='Archivo *'
                                                                    sublabel='Archivo de material'
                                                                    title='Arrastra el archivo del material aquí'
                                                                    subtitle=''
                                                                    className={error ? 'is-invalid' : ''}
                                                                    onChange={(file: any) => onChange(file)}
                                                                    accept={acceptExt}
                                                                />
                                                            )
                                                        }
                                                    }
                                                    />
                                                </div>
                                            )
                                        }

                                        {
                                            typeMaterialSelected === 'LINK' && (
                                                <div className="m-b-24">
                                                    <Input label='enlace *' errors={errors.link} {...register('link', { required: true })} />
                                                </div>
                                            )
                                        }
                                        
                                        {
                                            typeMaterialSelected === 'POLL' && (
                                                <div className="m-b-24">
                                                    <div className="card">
                                                        <div className="card-body p-t-8 p-b-8">
                                                            <div className={'d-flex align-items-center ' + (!pollData.question ? 'justify-content-center': '' )}>
                                                                { pollData.question &&  <p className="text-dark fw-bold font-12 text-uppercase">{pollData.question}</p> }
                                                                
                                                                <button type="button" className={'btn btn-link text-info text-decoration-underline fw-semibold ' + (pollData.question ? 'ms-auto': '' )} onClick={() => setShowModalPoll(true) }>
                                                                    { pollData.question ?  'Administrar': 'configurar pregunta' }
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        
                                        {
                                            typeMaterialSelected === 'FORM' && (
                                                <>
                                                    {
                                                        formData.map( (f: any, i) => (
                                                            <div className="m-b-24" key={i}>
                                                                <div className="card">
                                                                    <div className="card-body p-t-8 p-b-8">
                                                                        <div className={'d-flex align-items-center ' }>
                                                                            <p className="text-dark fw-bold font-12 text-uppercase">{f.question}</p>
                                                                            
                                                                            <button type="button" className={'btn btn-link text-info text-decoration-underline fw-semibold ms-auto' } onClick={() => editQuestionForm(f) }>
                                                                                editar pregunta
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                        
                                                    }
                                                    <button type="button" className={'btn btn-link text-info text-decoration-underline fw-semibold px-0' } onClick={() => setShowModalForm(true) }>
                                                        <i className="bi bi-plus"></i> Agregar pregunta
                                                    </button>
                                                </>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </main>
            </form>

            <PollModal 
                open={showModalPoll}
                onSave={savePollData}
            />
            
            <FormModal 
                open={showModalForm}
                onSave={saveFormData}
                defaultData={question}
            />

        </>
    )
}

export default MaterialFormPage