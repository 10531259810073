import { useEffect } from 'react'
import { useForm } from 'react-hook-form';
import Input from '../../components/Input';
import Alert from '../../components/Alert';
import Button from '../../components/Button';
import { AuthService } from '../../services/authService';


function ResetPasswordPage() {
    
    const { register, reset, handleSubmit, formState: { errors, isValid, isSubmitting  } } = useForm({
        mode: 'onChange',
        defaultValues: {
            email: '',
        },
    });

    const submit = async (data: any) => {
        try {
            await AuthService.forgotPassword(data);
            Alert.fire({
                title: 'Correo enviado',
                text: `Revisa tu correo ${data.email}`
            });

            reset();
        } catch (error) {
            console.error(error);
            alert(error);
        }
    }

    useEffect(() => {
        document.body.className = '';

        return () => {
            document.body.className = 'has-navbar-vertical-aside navbar-vertical-aside-show-xl footer-offset';
        };
    }, [])


    return (
        <div className="d-flex align-items-center min-h-100">
            <main id="content" role="main" className="main bg-white pt-0">

                <div className="container-fluid px-0">

                    <div className="row mx-0">
                        <div className="col-lg-6 d-flex justify-content-center align-items-center min-vh-lg-100">
                            <div className="w-100 content-space-t-4 content-space-t-lg-2 content-space-b-1" style={{ maxWidth: '25rem' }} >

                                <form className="js-validate needs-validation" onSubmit={handleSubmit(submit)}>
                                    <div className="">
                                        <img className="m-b-40" src="/assets/img/logo.png" alt="Projecter" />

                                        <div className="m-b-16">
                                            <h1 className="display-5 m-b-8">Contraseña olvidada</h1>
                                            <p>Introduce la dirección de correo electrónico asociada a tu cuenta y te enviaremos un vínculo para restablecer tu contraseña.</p>
                                        </div>
                                    </div>

                                    <div className="m-b-32">
                                        <Input label='Correo electrónico' errors={errors.email} {...register('email',{ required: true })} />
                                    </div>

                                    <div className="d-grid m-b-40">
                                        <Button type="submit" className="btn btn-primary btn-lg p-t-19 p-b-19" disabled={!isValid} isLoading={isSubmitting}>
                                            restablecer contraseña
                                        </Button>
                                    </div>

                                </form>
                            </div>
                        </div>


                    </div>
                </div>
            </main >
        </div >
    )
}

export default ResetPasswordPage