import { useLocation, useNavigate } from "react-router-dom";
import Aside from "../../../components/Aside";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import { ReportService } from "../../../services/reportService";
import { useEffect, useState } from "react";
import Tooltip from "../../../components/Tooltip";
import * as XLSX from 'xlsx';
import { round } from "../../../utils/number";

function MaterialsDetailResults() {

    const navigate = useNavigate();

    const { state: { material } } = useLocation()

    const [list, setList] = useState([])

    const formatType = (type) => {
        switch (type) {
            case 'PDF':
                return <span className="badge badge-pdf">PDF</span>
            case 'IMAGE':
                return <span className="badge badge-image">Imagen</span>
            case 'VIDEO':
                return <span className="badge badge-video">Video</span>
            case 'AUDIO':
                return <span className="badge badge-audio">Audio</span>
            case 'LINK':
                return <span className="badge badge-link">Link</span>
            case 'FORM':
                return <span className="badge badge-form">Encuesta</span>
            case 'POLL':
                return <span className="badge badge-poll">Votación</span>

            default:
                break;
        }
    }

    const getDetail = async() => {
        const response = await ReportService.getMaterialDetail(material.content_repository._id)
        setList(response)
    }

    const dowload = () => {
        const workbook = XLSX.utils.book_new();

        const header = [
            'CONTACTO',
            'RUTA O ID',
            'SEGMENTO(S)',
            'CLIC EN ENLACE',
            'VIO CONTENIDO',
        ]
        
        const data = list.map( row => {

            return {
                'CONTACTO': row.contact_detail.contact.first_name +' ' +row.contact_detail.contact.last_name ,
                'RUTA O ID': row.contact_detail.assigned_id ,
                'SEGMENTO(S)': row.contact_detail.segmentations.map( s => s.title).join(', ') ,
                'CLIC EN ENLACE': row.open_link ? 'Si': 'No' ,
                'VIO CONTENIDO':  row.open_content ? 'Si': 'No',
            }
        });

        
        const worksheet = XLSX.utils.json_to_sheet(data, { header })

        XLSX.utils.book_append_sheet(workbook, worksheet);

        XLSX.writeFile(workbook, `material-detail.xlsx`);
    }

    useEffect(() => {
        getDetail()
    }, [material])

    return (
        <>
            <Header>
                <div>
                    <h4>{material.content_repository.name}</h4>
                    <p>{formatType(material.content_repository.type)}</p>
                </div>

                <div className="ms-auto">
                    <button type="button" className="btn btn-primary btn-sm nowrap" onClick={dowload}><i className="bi-download"></i> Descargar</button>
                </div>

            </Header>

            <Aside />

            <main role="main" className="main">

                <div className="content container px-5">
                    <div className="row">

                        <div className="col-12 m-b-32">
                            <button type="button" className="btn btn-link text-dark text-decoration-underline px-0" onClick={() => navigate(-1)} ><i className="bi bi-arrow-left me-2"></i>  Detalle de materiales</button>
                        </div>


                        <div className='col-12 m-b-24'>
                            <div className="card ">
                                <div className="card-body d-flex">
                                    <div className='mx-4 text-center flex-grow-1 '>
                                        <h3 className="text-center">{material.total_sent}</h3>
                                        <p className='nowrap'>Total envios <Tooltip className='ms-1 color-icon' title='Total de plantillas de material enviadas.' ><i className="bi-info-circle-fill"></i></Tooltip></p>
                                    </div>
                                    <div className='border-lg-end'></div>
                                    <div className='mx-4 text-center flex-grow-1 '>
                                        <h3 className="text-center">{material.unique_sent}</h3>
                                        <p className='nowrap'>Envios únicos <Tooltip className='ms-1 color-icon' title='Total de plantillas de material enviadas a contactos únicos.' ><i className="bi-info-circle-fill"></i></Tooltip></p>
                                    </div>
                                    <div className='border-lg-end'></div>
                                    <div className='mx-4 text-center flex-grow-1 '>
                                        <h3 className="text-center">{material.open_link}</h3>
                                        <p className='nowrap'>Clics <Tooltip className='ms-1 color-icon' title='Total de clics de contactos únicos a plantillas de material.' ><i className="bi-info-circle-fill"></i></Tooltip></p>
                                    </div>
                                    <div className='border-lg-end'></div>
                                    <div className='mx-4 text-center flex-grow-1 '>
                                        <h3 className="text-center">{material.open_content}</h3>
                                        <p className='nowrap'>Aperturas <Tooltip className='ms-1 color-icon' title='Total de aperturas de materiales por contactos únicos.' ><i className="bi-info-circle-fill"></i></Tooltip></p>
                                    </div>

                                    <div className='border-lg-end'></div>
                                    <div className='mx-4 text-center flex-grow-1 '>
                                        <h3 className="text-center">{ round((material.open_link * 100) / material.unique_sent)}%</h3>
                                        <p className='nowrap'>Click rate <Tooltip className='ms-1 color-icon' title='Clics totales x 100 / envíos únicos' ><i className="bi-info-circle-fill"></i></Tooltip></p>
                                    </div>
                                    <div className='border-lg-end'></div>
                                    <div className='mx-4 text-center flex-grow-1 '>
                                        <h3 className="text-center">{ round((material.open_content*100) / material.open_link)}%</h3>
                                        <p className='nowrap'>Click-to-open rate <Tooltip className='ms-1 color-icon' title='Aperturas x 100 / clics totales' ><i className="bi-info-circle-fill"></i></Tooltip></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <table className="card-table table">
                        <thead>
                            <tr>
                                <th scope="col">Contacto </th>
                                <th scope="col" className="text-center">RUTA O ID </th>
                                <th scope="col" className="text-center">Segmento(s)</th>
                                {/* <th scope="col" className="text-center">Total envíos</th> */}
                                <th scope="col" className="text-center">Clic en enlace</th>
                                <th scope="col" className="text-center">Vio Contenido</th>
                            </tr>
                        </thead>
                        <tbody>

                            {
                                list.map( row => (
                                    <tr key={row._id}>
                                        <td>{row.contact_detail.contact.first_name} {row.contact_detail.contact.last_name}</td>
                                        <td className="text-center">{row.contact_detail.assigned_id}</td>
                                        <td className="text-center">{row.contact_detail.segmentations.map( s => s.title).join(', ')}</td>
                                        {/* <td className="text-center">-</td> */}
                                        <td className="text-center">
                                            { row.open_link && <span className="badge bg-soft-success text-success">Si</span>}
                                            { !row.open_link && <span className="badge bg-soft-danger text-danger">No</span>}
                                        </td>
                                        <td className="text-center">
                                            { row.open_content && <span className="badge bg-soft-success text-success">Si</span>}
                                            { !row.open_content && <span className="badge bg-soft-danger text-danger">No</span>}
                                        </td>
                                    </tr>
                                ))
                            }
                            

                        </tbody>
                    </table>
                </div>

                <Footer />
            </main>


        </>
    )
}
export default MaterialsDetailResults