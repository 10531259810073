

export const formatType = (type: string) => {
    switch (type) {
        case 'PDF':
            return 'PDF'
        case 'IMAGE':
            return 'Imagen'
        case 'VIDEO':
            return 'Video'
        case 'AUDIO':
            return 'Audio'
        case 'LINK':
            return 'Link'
        case 'FORM':
            return 'Encuesta'
        case 'POLL':
            return 'Votación'
        case 'HTML':
            return 'CLM iPad'
        case 'HTML_SPACE':
            return 'HTML'

        default:
            return type
    }
}