import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Aside from '../../components/Aside'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import TabContentPage from './TabContentPage'
import TabSecurityPage from './TabSecurityPage'

function RootConfigPage() {

    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const [tab, setTab] = useState<any>(null)
    const [partial, setPartial] = useState<any>(null)

    const goTo = (tab: string, partial: string) => {
        navigate({
            pathname: '',
            search: `?tab=${tab}&partial=${partial}`
        })
    }

    useEffect(() => {
        setTab(searchParams.get('tab'))
        setPartial(searchParams.get('partial'))
    }, [searchParams])


    return (
        <>
            <Header>
                <div>
                    <h4>Configuración</h4>
                    <p>Modifica las preferencias en la plataforma</p>
                </div>
            </Header>

            <Aside />

            <main role="main" className="main">


                <div className="bg-white border-bottom">
                    <div className="container p-t-12 p-b-12 px-5">
                        <ul className="nav nav-pills ">
                            <li className="nav-item font-12 m-0 me-2">
                                <button className={'nav-link fw-bold ' + (!tab || tab === 'content' ? 'active': 'text-reset')} onClick={() => goTo('content', 'segments')}>
                                    CONTENIDO
                                </button>
                            </li>
                            <li className="nav-item font-12 m-0"  >
                                <button className={'nav-link fw-bold ' + (tab === 'security' ? 'active': 'text-reset')} onClick={() => goTo('security', '2-step-verification')}>
                                    SEGURIDAD
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="content container px-5">
                    <div className={(!tab || tab === 'content') ? '': 'd-none'}>
                        <TabContentPage partial={partial} goTo={goTo} />
                    </div>
                    <div className={tab === 'security' ? '': 'd-none'}>
                        <TabSecurityPage partial={partial} goTo={goTo} />
                    </div>
                    
                </div>

                <Footer />
            </main>

        </>
    )
}

export default RootConfigPage