import { useEffect, useRef, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import Aside from "../../components/Aside"
import Footer from "../../components/Footer"
import Header from "../../components/Header"
import Input from "../../components/Input"
import { CollaboratorService } from "../../services/collaboratorService"
import Select from 'react-select'
import Button from "../../components/Button"
import { RolService } from "../../services/rolService"
import { AssignmentService } from "../../services/assignmentService"
import Alert from "../../components/Alert"

declare const bootstrap: any;

function CollaboratorHomePage() {

    const defaultValues = {
        _id: '',
        role: '',
        external_id: '',
        first_name: '',
        last_name: '',
        email: ''
    }
    const { control, register, handleSubmit, setValue, reset, watch, setError, formState: { errors, isValid, isSubmitting } } = useForm({
        mode: 'onChange',
        defaultValues
    })

    const rolSelected = watch('role');

    const modalRef = useRef<HTMLDivElement>(null)
    const [modal, setModal] = useState<any>(null)

    const [collaborators, setCollaborators] = useState([])
    const [roles, setRoles] = useState([])
    const [assignments, setAssignments] = useState([])
    const [isAgent, setIsAgent] = useState(false)
    
    const showModal = (data?: any) => {
        if (data) {
            reset({
                _id: data._id,
                role: data.role._id,
                external_id: data.external_id? data.external_id._id: '',
                first_name: data.first_name,
                last_name: data.last_name,
                email: data.email,
            })
        } else {
            reset(defaultValues);
        }

        modal.show()
    }

    const submit = async (data: any) => {
        try {
            
            if(data._id) {
                await CollaboratorService.update(data);
            } else {

                delete data._id;
                await CollaboratorService.save(data);
            }

            getCollaborators();
            
            Alert.fire({
                title: 'Colaborador guardado',
                text: `Se guardo correctamente el colaborador`
            });

            modal.hide()
            
        } catch (error: any) {
            console.error(error)
            if(error.field) {
                setError(error.field, { type: 'custom', message: error.message })
            }
            
        }
    }

    const getCollaborators = async () => {
        const response = await CollaboratorService.getCollaborators();
        setCollaborators(response)
    }

    const getRoles = async () => {
        const response = await RolService.getRoles();
        setRoles(response.map((a: any) => ({ value: a._id, label: a.name, key: a.key })))
    }

    const getAssignmentsAvailable = async () => {
        const response = await AssignmentService.getAssignmentsActive();
        setAssignments(response.map((a: any) => ({ value: a._id, label: a.name })))
    }

    const resendEmail = async(_id) => {
        const isOK = await Alert.danger({
            title:'Reenviar correo de registro', 
            text: 'Al volver a enviar el correo de registro se generará un nuevo link para que el colaborador genere su contraseña.',
            cancelText: 'Cancelar',
            confirmText: 'Reenviar',
        });

        if(isOK) {
            await CollaboratorService.resendInvite({ _id })
            
            Alert.close();
        }
    }

    useEffect(() => {
        const status = rolSelected && roles.find( (r: any) => r.key === 'agent' && r.value === rolSelected);
        
        if(!status) {
            setValue('external_id', '')
        }

        setIsAgent(status ? true: false)
    }, [rolSelected])

    useEffect(() => {
        if (modalRef.current) {
            const modalInstance = new bootstrap.Modal(modalRef.current, {})
            setModal(modalInstance)
        }

    }, [modalRef])


    useEffect(() => {
        getRoles()
        getAssignmentsAvailable()
        getCollaborators()
    }, [])


    return (
        <>
            <Header>
                <div>
                    <h4>Colaboradores</h4>
                    <p>Administra y añade nuevos colaboradores</p>
                </div>

                <div className="ms-auto">
                    <button type="button" className="btn btn-primary btn-sm" onClick={() => showModal()}>nuevo colaborador <i className="bi bi-plus-circle-fill m-l-8"></i></button>
                </div>
            </Header>

            <Aside />

            <main role="main" className="main">

                <div className="bg-white border-bottom ">
                    <div className="container p-t-12 p-b-12 px-5">
                        <div className="d-flex align-items-center">
                            <i className="bi bi-search m-l-8"></i>
                            <input className="form-control input-group-borderless form-control-flush" type="text" placeholder="Buscar" />
                        </div>
                    </div>
                </div>

                <div className="content container px-5">

                    <table className="card-table table">
                        <thead>
                            <tr>
                                <th scope="col">NOMBRE perfil</th>
                                <th scope="col">Rol</th>
                                <th scope="col">Correo electrónico</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                collaborators.map((collaborator: any) => (
                                    <tr key={collaborator._id}>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                <span className="avatar avatar-sm avatar-primary avatar-circle">
                                                    <img className="avatar-img" src={collaborator.avatar} alt={collaborator.first_name + ' ' + collaborator.last_name} />
                                                </span>
                                                <div className="m-l-16">
                                                    <p className="fw-semibold text-dark">{collaborator.first_name} {collaborator.last_name}</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <p>{collaborator.role.name}</p>
                                            {collaborator.external_id && <p>{collaborator.external_id.name}</p>}
                                        </td>
                                        <td>
                                            <p className="p-0">{collaborator.email}</p>
                                            {collaborator.is_new && <button className="btn btn-link-projecter" onClick={() => resendEmail(collaborator._id)}>Reenviar correo de registro</button>}
                                            
                                        </td>
                                        <td>
                                            {/* <button type="button" className="btn btn-outline-primary btn-outline-trash  btn-icon ">
                                                <i className="bi bi-trash-fill"></i>
                                            </button> */}
                                            <button type="button" className="btn btn-outline-primary btn-outline-edit btn-icon m-l-8" onClick={() => showModal(collaborator)}>
                                                <i className="bi bi-pencil-fill"></i>
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            }

                        </tbody>
                    </table>

                </div>

                <Footer />
            </main>

            <div className="modal fade" ref={modalRef} tabIndex={-1} data-bs-backdrop="static" data-bs-keyboard="false" >
                <div className="modal-dialog" role="document">
                    <form onSubmit={handleSubmit(submit)}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" >Nuevo contacto</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="row m-b-40">
                                    <div className="col-4">
                                        <p className="text-dark fw-bold m-b-8 font-12 text-uppercase">DATOS</p>
                                        <p className="font-12">Información básica del colaborador</p>
                                    </div>
                                    <div className="col-8">
                                        <div className="m-b-24">
                                            <Input label='Nombre(s) *' errors={errors.first_name} {...register('first_name', { required: true })} />
                                        </div>
                                        <div className="m-b-24">
                                            <Input label='Apellidos *' errors={errors.last_name} {...register('last_name', { required: true })} />
                                        </div>

                                        <div className="m-b-24">
                                            <label className="form-label" >Rol *</label>
                                            <Controller
                                                control={control}
                                                name='role'
                                                rules={{ required: true }}
                                                render={({
                                                    field: { onChange, onBlur, value },
                                                    fieldState: { error },
                                                }) => (
                                                    <Select
                                                        placeholder='Selecciona un rol'
                                                        options={roles}
                                                        className={error ? 'form-select-custom is-invalid' : ''}
                                                        classNamePrefix='form-select-custom'
                                                        onBlur={onBlur}
                                                        onChange={(value: any) => onChange(value?.value)}
                                                        isClearable
                                                        value={roles.find((a: any) => a.value === value) || ''}
                                                    />
                                                )}
                                            />
                                            <span className="invalid-feedback">Campo obligatorio.</span>
                                        </div>

                                        {
                                            isAgent && (
                                                <div className="m-b-24">
                                                    <label className="form-label" >Ruta o ID <span className="form-label-secondary text-capitalize">(Opcional)</span></label>
                                                    <Controller
                                                        control={control}
                                                        name='external_id'
                                                        render={({
                                                            field: { onChange, onBlur, value },
                                                            fieldState: { error },
                                                        }) => {
                                                            return  (
                                                                <Select
                                                                    placeholder='Selecciona una ruta o id'
                                                                    options={assignments}
                                                                    className={error ? 'form-select-custom is-invalid' : ''}
                                                                    classNamePrefix='form-select-custom'
                                                                    onBlur={onBlur}
                                                                    onChange={(value: any) => onChange(value?.value)}
                                                                    isClearable
                                                                    value={assignments.find((a: any) => a.value === value) || ''}
                                                                />
                                                            )
                                                        }}
                                                    />
                                                    <span className="invalid-feedback">
                                                        { errors.external_id && errors.external_id.type === 'custom' && `${errors.external_id.message }`}
                                                        { errors.external_id && errors.external_id.type !== 'custom' && 'Campo obligatorio.' }
                                                    </span>
                                                </div>
                                            )
                                        }


                                    </div>
                                </div>

                                <div className="row m-b-40">
                                    <div className="col-4">
                                        <p className="text-dark fw-bold m-b-8 font-12 text-uppercase">Contacto</p>
                                        <p className="font-12">Puntos de contacto con el colaborador</p>
                                    </div>
                                    <div className="col-8">
                                        <div className="m-b-24">
                                            <Input label='Correo electrónico *' errors={errors.email} {...register('email', { required: true })} />
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-soft-primary btn-sm" data-bs-dismiss="modal">Cerrar</button>
                                <Button type="submit" className="btn btn-primary btn-sm" disabled={!isValid} isLoading={isSubmitting}>Guardar colaborador</Button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

        </>
    )
}

export default CollaboratorHomePage