import { END_POINT } from "../constants";
import { HTTP } from "./http";

export const SegmentService = {

    getSegments: () => {
        return HTTP.get<any, any>(`${END_POINT}segmentation`);
    },
    getActive: () => {
        return HTTP.get<any, any>(`${END_POINT}segmentation/active`);
    },
    save: (data: any) => {
        return HTTP.post<any, any>(`${END_POINT}segmentation`, data);
    },
    update: (data: any) => {
        return HTTP.put<any, any>(`${END_POINT}segmentation`, data);
    },
}