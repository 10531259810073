
function Audio() {
    return (
        <div className="d-flex  align-items-center message-multimedia audio">
            
            {/* <i className="bi bi-play-fill"></i>
            <div className="progress flex-grow-1">
                <div className="progress-bar w-75" >
                    <div className="progress-indicator"></div>
                </div>
            </div> */}

            <audio controls>
                <source src="https://www.w3schools.com/html/horse.mp3" />
                Your browser does not support the audio element.
            </audio>

        </div>
    )
}
export default Audio