import { END_POINT } from "../constants";
import { HTTP } from "./http";

export const CampaignService = {

    testSMS: (data: { message: string, phone: string }) => {
        return HTTP.post<any, any>(`${END_POINT}campaign/test/sms`, data);
    },
    
    getContacts: (data: { segmentations: string  }) => {
        return HTTP.post<any, any>(`${END_POINT}campaign/template/contacts`, data);
    },

    save: (form: FormData) => {
        return HTTP.post<any, any>(`${END_POINT}campaign/save`, form);
    },

    getAll: () => {
        return HTTP.get<any, any>(`${END_POINT}campaign`);
    },
    
}