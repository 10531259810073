import { useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form";
import Alert from "../../components/Alert";
import Button from "../../components/Button";
import EmptyState from "../../components/EmptyState";
import HandleNoData from "../../components/HandleNoData";
import Input from "../../components/Input";
import TextArea from "../../components/TextArea";
import { SegmentService } from "../../services/segmentService"

declare const bootstrap: any;

function SegmentsPartial() {

    const modalRef = useRef<HTMLDivElement>(null)

    const [modal, setModal] = useState<any>(null)
    const [segments, setSegments] = useState([])

    const defaultValues = {
        _id: '',
        title: '',
        description: '',
        is_active: false
    }
    const { register, handleSubmit, reset, watch, formState: { errors, isValid, isSubmitting } } = useForm({
        mode: 'onChange',
        defaultValues
    });

    const getSegments = async () => {
        const response = await SegmentService.getSegments();
        setSegments(response);
    }

    const showModal = (data?: any) => {
        if(data) {
            reset(data)
        } else {
            reset(defaultValues);
        }
        
        modal.show()
    }


    const submit = async (data: any) => {
        try {
            if(data._id) {
                await SegmentService.update(data);
            } else {
                delete data._id;
                await SegmentService.save(data);
            }
            
            getSegments();

            Alert.fire({
                title: 'Segmento guardado',
                text: `Se guardo correctamente el segmento`
            });

            modal.hide()
        } catch (error) {
            console.error(error)
            alert(error);
        }
    }

    useEffect(() => {
        if(modalRef.current) {
            const modalInstance = new bootstrap.Modal(modalRef.current, {})
            setModal(modalInstance)
        }
        
    }, [modalRef])

    useEffect(() => {
        getSegments()
    }, [])

    return (
        <>
            <div className='d-flex align-items-center mb-3'>
                <h5>Segmentos</h5>
                <button type="button" className="btn btn-outline-primary ms-auto" onClick={() => showModal()} >nuevo segmento</button>
            </div>

            <HandleNoData
                condition={segments.length}
                emptyComponent={<EmptyState />}
            >
                {
                    segments.map((s: any) => (
                        <div className='card m-b-16' key={s._id}>
                            <div className='card-body'>
                                {s.is_active && <p className='text-success fw-semibold m-b-8'><i className="bi bi-dot"></i> Activo</p>}
                                {!s.is_active && <p className='text-muted fw-semibold m-b-8'><i className="bi bi-dot"></i> Inactivo</p>}

                                <div className='d-flex align-items-start'>
                                    <div>
                                        <h6 className="mb-1">{s.title}</h6>
                                        <p>{s.description}</p>
                                    </div>
                                    <div className='ms-auto nowrap'>
                                        <button type="button" className="btn btn-outline-primary btn-outline-edit btn-icon m-l-8"  onClick={() => showModal(s)}>
                                            <i className="bi bi-pencil-fill"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </HandleNoData>

            <div className="modal fade" ref={modalRef} data-bs-backdrop="static" data-bs-keyboard="false" >
                <div className="modal-dialog" role="document">
                    <form onSubmit={handleSubmit(submit)}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" >{watch('_id') ? 'Actualizar': 'Nuevo' } segmento</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="row ">
                                    <div className="m-b-24">
                                        <Input label='Nombre del segmento *' errors={errors.title} {...register('title', { required: true })} />
                                    </div>
                                    <div className="m-b-24">
                                        <TextArea label='Descripción *' errors={errors.description} {...register('description', { required: true })} />
                                    </div>
                                    <div className="">
                                        <div className="form-check form-switch ">
                                            <label className="form-check-label" htmlFor="is_active">Activo</label>
                                            <input type="checkbox" className="form-check-input form-check-info" id="is_active" {...register('is_active')} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-soft-primary btn-sm" data-bs-dismiss="modal">Cancelar</button>
                                <Button type="submit" className="btn btn-primary btn-sm" disabled={!isValid} isLoading={isSubmitting}>{watch('_id') ? 'Guardar cambios': 'Guardar segmento' }</Button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default SegmentsPartial