import { useRef, useState, useEffect, useMemo } from "react"
import Aside from "../../../components/Aside"
import Header from "../../../components/Header"
import Footer from "../../../components/Footer"
import { useLocation, useNavigate } from "react-router-dom"
import moment from 'moment';
import 'moment/locale/es'
import { round } from "../../../utils/number"
import Tooltip from "../../../components/Tooltip"
import { CollaboratorService } from "../../../services/collaboratorService"
import { ContactService } from "../../../services/contactService"
import { MaterialService } from "../../../services/materialService"
import { ReportService } from "../../../services/reportService"
import AgentTable from "./AgentTable"
import ContacTable from "./ContacTable"
import MaterialTable from "./MaterialTable"

moment.locale('es')

declare const $: any;

function HTMLSDetail() {

    const navigate = useNavigate();
    const { state: { prevHighlights, range } } = useLocation()

    const rangeElement = useRef(null)

    const [rangeFilter, setRangeFilter] = useState({
        start: moment(range.start),
        end: moment(range.end),
        isFirstRender: true
    })

    const [highlights, setHighlights] = useState(prevHighlights)

    const [agents, setAgents] = useState([])
    const [contacts, setContacts] = useState([])
    const [contentRepositories, setContentRepositories] = useState([])

    const [filter, setFilter] = useState<'AGENT' | 'CONTACT' | 'MATERIAL'>('AGENT')

    const avg = useMemo(() => {

        if (highlights.total_avg < 60) {
            return `${round(highlights.total_avg)}seg`
        }

        let min = highlights.total_avg / 60

        return `${round(min)}min`

    }, [highlights.total_avg])

    const getAgents = async () => {
        const response = await CollaboratorService.getAgents();
        setAgents(response.map(a => ({ value: a._id, label: `${a.first_name} ${a.last_name}` })))
    }

    const getContacts = async () => {
        const response = await ContactService.getContacts();
        setContacts(response.map(a => ({ value: a._id, label: `${a.first_name} ${a.last_name}` })))
    }

    const getContentRepositories = async () => {
        const response = await MaterialService.getForReports();
        setContentRepositories(response.map(a => ({ value: a._id, label: a.name })))
    }


    useEffect(() => {
        if (!rangeElement || !rangeElement.current) return;

        $(rangeElement.current).find('.js-daterangepicker-predefined-preview').html(rangeFilter.start.format('MMMM D, YYYY') + ' - ' + rangeFilter.end.format('MMMM D, YYYY'));

        const cb = (start, end) => {
            setRangeFilter({
                start,
                end,
                isFirstRender: false
            })

            $(rangeElement.current).find('.js-daterangepicker-predefined-preview').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
        }

        $(rangeElement.current).daterangepicker({
            startDate: rangeFilter.start,
            endDate: rangeFilter.end,
            ranges: {
                'Hoy': [moment(), moment()],
                'Ayer': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Últimos 7 días': [moment().subtract(6, 'days'), moment()],
                'Últimos 30 días': [moment().subtract(29, 'days'), moment()],
                'Mes actual': [moment().startOf('month'), moment().endOf('month')],
                'Mes pasado': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            },
            locale: {
                applyLabel: 'Aplicar',
                cancelLabel: 'Cancelar',
                customRangeLabel: 'Personalizado',
                daysOfWeek: [
                    "Do",
                    "Lu",
                    "Ma",
                    "Mi",
                    "Ju",
                    "Vi",
                    "Sa"
                ],
                monthNames: [
                    "Enero",
                    "Febrero",
                    "Marzo",
                    "Abril",
                    "Mayo",
                    "Junio",
                    "Julio",
                    "Agosto",
                    "Septiembre",
                    "Octubre",
                    "Noviembre",
                    "Diciembre",
                ],
            }
        }, cb);

    }, [rangeElement])


    const getDashboardHTMLS = async () => {
        try {
            const request = await ReportService.getDashboardHTMLS(rangeFilter.start.format('YYYY-MM-DD'), rangeFilter.end.format('YYYY-MM-DD'))
            setHighlights(request)
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        if (!rangeFilter.isFirstRender) {
            getDashboardHTMLS()
        }
    }, [rangeFilter])

    useEffect(() => {
        getAgents();
        getContacts();
        getContentRepositories();
    }, [])


    return (
        <>
            <Header>
                <div>
                    <h4>Detalle de e-detailing</h4>
                    <p>Consulte las estadísticas relacionadas al e-detailing, de acuerdo al periodo de tiempo seleccionado y al criterio de consulta.</p>
                </div>

                <div className="ms-auto ">
                    <div className="d-flex align-items-center ms-3">
                        {/* <button type="button" className="btn btn-outline-primary btn-sm nowrap" ><i className="bi-download"></i> Descargar</button> */}
                        
                        <button ref={rangeElement} className="btn btn-primary btn-sm nowrap ms-2">
                            <i className="bi-calendar-week me-2"></i>
                            <span className="js-daterangepicker-predefined-preview"></span>
                        </button>
                    </div>
                </div>
            </Header>

            <Aside />

            <main role="main" className="main">

                <div className="bg-white border-bottom">
                    <div className="container p-t-12 p-b-12 px-0 px-5">
                        <ul className="nav nav-pills ">
                            <li className="nav-item font-12 m-0 me-2">
                                <button className={'nav-link fw-bold ' + (filter === 'AGENT' ? 'active' : 'text-reset')} onClick={() => setFilter('AGENT')}>
                                    POR AGENTE
                                </button>
                            </li>
                            <li className="nav-item font-12 m-0 me-2"  >
                                <button className={'nav-link fw-bold ' + (filter === 'CONTACT' ? 'active' : 'text-reset')} onClick={() => setFilter('CONTACT')}>
                                    POR CONTACTO
                                </button>
                            </li>
                            <li className="nav-item font-12 m-0"  >
                                <button className={'nav-link fw-bold ' + (filter === 'MATERIAL' ? 'active' : 'text-reset')} onClick={() => setFilter('MATERIAL')}>
                                    POR MATERIAL
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="content container px-5">
                    <div className="row">
                        <div className="col-12 m-b-24">
                            <button type="button" className="btn btn-link text-dark text-decoration-underline px-0" onClick={() => navigate(-1)} ><i className="bi bi-arrow-left me-2"></i>  Dashboard</button>
                        </div>

                        <div className='col-12 m-b-24'>
                            <div className="card ">
                                <div className="card-body d-flex">
                                    <div className='mx-4 text-center flex-grow-1 '>
                                        <h3 className="text-center">{round(highlights.total_sessiones)}</h3>
                                        <p className='nowrap'>Total sesiones <Tooltip className='ms-1 color-icon' title='Total de sesiones registradas.' ><i className="bi-info-circle-fill"></i></Tooltip></p>
                                    </div>
                                    <div className='border-lg-end'></div>
                                    <div className='mx-4 text-center flex-grow-1 '>
                                        <h3 className="text-center">{round(highlights.total_unique_sessions)}</h3>
                                        <p className='ms-3 nowrap'>Contactos únicos <Tooltip className='ms-1 color-icon' title='Contactos únicos que tuvieron al menos una sesión en el periodo seleccionado.' ><i className="bi-info-circle-fill"></i></Tooltip></p>
                                    </div>
                                    <div className='border-lg-end'></div>
                                    <div className='mx-4 text-center flex-grow-1 '>
                                        <h3 className="text-center">{round(highlights.total_per_day)}</h3>
                                        <p className='ms-3 nowrap'>Promedio de sesiones x agente <Tooltip className='ms-1 color-icon' title='Promedio de sesiones registradas por agente activo por día.' ><i className="bi-info-circle-fill"></i></Tooltip></p>
                                    </div>
                                    <div className='border-lg-end'></div>
                                    <div className='mx-4 text-center flex-grow-1 '>
                                        <h3 className="text-center">{avg}</h3>
                                        <p className='ms-3 nowrap'>Tiempo promedio por sesión. <Tooltip className='ms-1 color-icon' title='Duración promedio de las sesiones registradas.' ><i className="bi-info-circle-fill"></i></Tooltip></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={filter === 'AGENT' ? '' : 'd-none'}><AgentTable agents={agents} rangeFilter={rangeFilter} /></div>
                    <div className={filter === 'CONTACT' ? '' : 'd-none'}><ContacTable contacts={contacts} rangeFilter={rangeFilter} /></div>
                    <div className={filter === 'MATERIAL' ? '' : 'd-none'}><MaterialTable materials={contentRepositories} rangeFilter={rangeFilter} /></div>

                </div>

                <Footer />
            </main>


        </>
    )
}
export default HTMLSDetail