import SyntaxHighlighter from 'react-syntax-highlighter';

function TokensPartial() {
    return (
        <div className="card">

            <div className="card-body">
                <h5 className="card-title">Tokens</h5>
                <p className="card-text">Para personalizar la experiencia, desde el proyecto se pueden invocar objetos de la base de datos como nombre y apellido del cliente, sus datos contacto, datos sobre el representante o incluso datos de la empresa</p>

                <p  className="card-text mt-3">Puedes agregar estas variables de dos formas, por medio de etiquetas html o por medio de un atributo.</p>
                <p  className="card-text mt-3">Las etiquetas disponibles son:</p>
                <ul className="card-text mt-3">
                    <li><code>{'<pb-customer-full-name />'}</code></li>
                    <li><code>{'<pb-customer-first-name />'}</code></li>
                    <li><code>{'<pb-customer-last-name />'}</code></li>
                    <li><code>{'<pb-customer-email />'}</code></li>
                    <li><code>{'<pb-customer-phone />'}</code></li>
                    <li><code>{'<pb-customer-medical-specialty />'}</code></li>
                    <li><code>{'<pb-agent-full-name />'}</code></li>
                    <li><code>{'<pb-agent-email />'}</code></li>
                    <li><code>{'<pb-account-name />'}</code></li>
                    <li><code>{'<pb-account-whatsapp />'}</code></li>

                </ul>
                
                
                <div className='mt-3'>
                    <SyntaxHighlighter language="vbscript-html" showInlineLineNumbers >
                        {
                            `
<div>
    <h1>Hola <pb-customer-full-name /></h1>
    <p>Mi nombre es <pb-agent-full-name /> y puedes enviarme un correo a <pb-customer-email /></p>
</div>

`
                        }
                    </SyntaxHighlighter>
                </div>

                <p  className="card-text mt-3">Si deseas utilizar las variables por medio del atributo de datos <code>data-*</code> utiliza:</p>
                <ul className="card-text mt-3">
                    <li><code>data-pb-customer-full-name</code></li>
                    <li><code>data-pb-customer-first-name</code></li>
                    <li><code>data-pb-customer-last-name</code></li>
                    <li><code>data-pb-customer-email</code></li>
                    <li><code>data-pb-customer-phone</code></li>
                    <li><code>data-pb-customer-medical-specialty</code></li>
                    <li><code>data-pb-agent-full-name</code></li>
                    <li><code>data-pb-agent-email</code></li>
                    <li><code>data-pb-account-name</code></li>
                    <li><code>data-pb-account-whatsapp</code></li>


                </ul>
            
                <div className='mt-3'>
                    <SyntaxHighlighter language="vbscript-html" showInlineLineNumbers >
                        {
                            `
<div>
    <h1>Hola <span data-pb-customer-full-name="true"></span></h1>
    <p>Mi nombre es <span data-pb-agent-full-name="true"></span> y puedes enviarme un correo a <span data-pb-agent-email="true"></span></p>
</div>

`
                        }
                    </SyntaxHighlighter>
                </div>
               
                <p  className="card-text mt-3">Para renderizar correctamente las variables ya sea por etiquetas o atributo de datos debes de invocar la función:</p>
                <div className='mt-3'>
                    <SyntaxHighlighter language="javascript" showInlineLineNumbers >
                        {
                            `
windows.ProjecterBridge.render();

`
                        }
                    </SyntaxHighlighter>
                </div>

                <p  className="card-text mt-3">Para facilitar la depuración del archivo e-detailing y ayudar en su progamación, ponemos a tu disposición la siguiente información por defecto:</p>

                <ul className="card-text mt-3">
                    <li>Cliente</li>
                        <ul>
                            <li>Nombre(s): Gregory</li>
                            <li>Apellidos: House</li>
                            <li>Correo: dr.house@ejemplo.com</li>
                            <li>Teléfono: 55 1234 5678</li>
                            <li>Especialidad médica: Nefrología</li>
                        </ul>
                    <li>Agente</li>
                    <ul>
                        <li>Nombre completo: Neil Rackham</li>
                        <li>Correo: neilr@ejemplo.com</li>
                    </ul>
                    <li>Cuenta</li>
                    <ul>
                        <li>Nombre: TheCompany</li>
                        <li>Número de WhatsApp: 55 9999 9999</li>

                    </ul>
                </ul>
                                
            </div>
        </div>
    )
}
export default TokensPartial