import moment from "moment"
import { useState, useEffect, useMemo } from "react"
import Aside from "../../components/Aside"
import EmptyState from "../../components/EmptyState"
import Footer from "../../components/Footer"
import Header from "../../components/Header"
import { InboxService } from "../../services/inboxService"
import { Chat, Message } from "../../interfaces"
import MessageParseService from "../../services/messageParseService"
import Audio from "../../components/Audio";
import ContactFormModal from "../contacts/ContactFormModal"
import TemplatesModal from "./TemplatesModal"
import Alert from "../../components/Alert"
import { useForm } from "react-hook-form"
import SocketIOService from '../../services/socket.io.service'
import { Socket } from "socket.io-client"

function InboxPage() {

    const messageParseService = MessageParseService.getInstance();

    const defaultValues = {
        message: ''
    }
    const { control, register, handleSubmit, reset, watch, getValues, setValue, formState: { errors, isValid, isSubmitting } } = useForm({
        mode: 'onChange',
        defaultValues
    });

    const [socket, setSocket] = useState<Socket>()
    const [chats, setChats] = useState<Array<Chat>>([])
    const [chatSelected, setChatSelected] = useState<Chat>()
    const [messages, setMessages] = useState<Array<Message>>([])

    const [modalContact, setModalContact] = useState({
        show: false,
        data: null
    })

    const [modalTemplate, setModalTemplate] = useState({
        show: false,
        data: null
    })

    const showModalContact = () => {
        let contact = {
            _id: chatSelected.contact._id,
            first_name: chatSelected.contact.first_name,
            last_name: chatSelected.contact.last_name,
            email: chatSelected.contact.email,
            country_code: chatSelected.contact.phones && chatSelected.contact.phones.length ? chatSelected.contact.phones[0].phone.country_code : '',
            phone_number: chatSelected.contact.phones && chatSelected.contact.phones.length ? chatSelected.contact.phones[0].phone.phone_number : '',
            assigned_id: chatSelected.contact.assigned_id?._id || '',
            segmentations: chatSelected.contact.segmentations.map((s: any) => s._id),
            type: chatSelected.contact.type,
            medical_specialty: chatSelected?.contact?.medical_specialty?._id || ''
        }

        setModalContact({
            show: true,
            data: contact
        })
    }

    const handleHideModalContact = async (refresh: boolean) => {
        if (refresh) {
            console.log(refresh)
            await getMessages(true)
        }

        setModalContact({
            show: false,
            data: null
        })
    }

    const getInbox = async () => {
        const data = await InboxService.getInbox()
        setChats(data)
    }

    const getMessages = async (updateChat = false) => {
        const data = await InboxService.getMessages(chatSelected._id)
        setMessages(data.messages)

        if (updateChat) {
            setChatSelected(data.chat)
        }

    }

    const hasActiveSession = useMemo(() => {
        if (!chatSelected || !chatSelected.last_message) return 0;

        const now = moment();
        const lastMessageDate = moment(chatSelected.last_message.created_at)

        const duration = moment.duration(now.diff(lastMessageDate));
        return duration.asHours();
    }, [chatSelected])

    const submit = async (form) => {
        try {
            const response = await InboxService.sendMessage({
                contact: chatSelected.contact._id,
                message: form.message
            })

            Alert.fire({
                title: 'Mensaje enviado',
                text: `El mensaje se ha enviado al contacto correctamente`
            });

            setMessages(m => m.concat([response.message]))

            reset(defaultValues)

        } catch (error) {

            Alert.fire({
                title: 'Error al enviar mensaje',
                text: `Ocurrio un error al enviar el mensaje, intenta nuevamentes y si el error persiste contacta a tu administrador.`,
                type: 'error'
            });
        }
    }

    const handleHideModalTemplates = (data: any) => {
        if (data) {
            setMessages(m => m.concat([data]))
        }

        setModalTemplate({ show: false, data: null })
    }

    useEffect(() => {
        if (chatSelected) {
            getMessages()
            reset(defaultValues)
        }
    }, [chatSelected])

    useEffect(() => {
        getInbox()
    }, [])

    useEffect(() => {
        const root = document.getElementById('root')
        root.classList.add('d-flex', 'flex-column')

        return () => {
            root.classList.remove('d-flex', 'flex-column')
        }
    }, [])

    useEffect(() => {
        let socketInstance = SocketIOService.start()
        setSocket(socketInstance)

        return () => {
            if (socket) {
                socket.disconnect()
            }
        }
    }, [])

    useEffect(() => {

        const handleNewMessage = (message: Message) => {
            if (chatSelected && chatSelected._id === message.chat) {
                setMessages(msgs => [...msgs, message])
            }
        }

        const handleUpdateMessage = (chat: Chat) => {
            const exists = chats.find(c => c._id === chat._id)

            if (exists) {
                setChats(chts => chts.map(c => {
                    if (c._id === chat._id) {
                        c = chat
                    }

                    return c
                }))
            } else {
                setChats(chts => [chat, ...chts])
            }
        }

        if(socket) {
            // socket.on('status-message', (payload) => console.log('status-message', payload));
            socket.on('update-chat', handleUpdateMessage)
            socket.on('new-message', handleNewMessage)
        }
        

        return () => {
            if(socket) {
                socket.off('update-chat', handleUpdateMessage)
                socket.off('new-message', handleNewMessage)
            }
        }
    }, [socket, chatSelected, chats])


    return (
        <>
            <Header>
                <div>
                    <h4>Bandeja de entrada</h4>
                    <p>En este espacio puedes revisar los mensajes entrantes de contactos que: no tienen asignado a un agente, o contactos nuevos que aún no han sido registrados. Aquí mismo podrás responderles, así como asignarles un agente que les de seguimiento.</p>
                </div>
            </Header>

            <Aside />

            <main role="main" className="main d-flex flex-column ">
                <div className="content container px-5 flex-grow-1">

                    { chats.length === 0 && <div className="p-5"><EmptyState text='Aún no tenemos datos chats para ti, vuelve más tarde.' /></div> }

                    {
                        chats.length > 0 && (
                        <div className="card h-100">
                            <div className="card-body p-0">
                                <div className="row g-0 h-100" >
                                    {/* <div className="row g-0" style={{ height: `${mainHeight}px`, overflow: 'hidden' }}> */}
                                    <div className="col-4 h-100-scroll">
                                        <div className="list-chat-container" >  
                                            <div className="messages-header" >
                                                <h3>Chats</h3>
                                            </div>
                                                                                {
                                                chats.map(chat => (
                                                    <div className={`chat-item d-flex ${chatSelected?._id === chat._id ? 'active' : ''}`} key={chat._id} onClick={() => setChatSelected(chat)}>
                                                        <span className="avatar avatar-primary avatar-circle">
                                                            <img className="avatar-img w-100" src={chat.contact.avatar} alt={`${chat.contact.first_name} ${chat.contact.last_name}`} />
                                                        </span>
                                                        <div className="px-2 flex-grow-1 d-flex">
                                                            <div className="flex-grow-1">
                                                                <h4 className="mb-0">{chat.contact.first_name} {chat.contact.last_name}</h4>
                                                                {chat.contact.is_organic_traffic && <span className="badge bg-soft-primary text-primary ">Orgánico</span>}
                                                            </div>
                                                            <span className="date">{moment(chat.updated_at).format('DD/MM/YY HH:mm')}</span>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    <div className="col-8 messages h-100-scroll" >
                                        {
                                            !chatSelected && <div className="d-flex flex-column justify-content-center h-100"><EmptyState title='Selecciona un chat' text='Visualiza la información detallada.' alternative className='mt-0'/></div>
                                        }
                                        {
                                            chatSelected && (
                                                <>
                                                    <div className="p-3 bg-white d-flex align-items-center">

                                                        <span className="avatar avatar-primary avatar-circle">
                                                            <img className="avatar-img w-100" src={chatSelected.contact.avatar} alt={`${chatSelected.contact.first_name} ${chatSelected.contact.last_name}`} />
                                                        </span>
                                                        <div className="px-2 flex-grow-1 d-flex ">
                                                            <div className="flex-grow-1">
                                                                <h4 className="mb-0 font-16">{chatSelected.contact.first_name} {chatSelected.contact.last_name}</h4>
                                                                <p>{chatSelected.number}</p>
                                                            </div>
                                                        </div>
                                                        {chatSelected.contact.is_organic_traffic && <span className="badge bg-soft-primary text-primary ">Orgánico</span>}

                                                        <button type="button" className="btn btn-link" onClick={showModalContact}>
                                                            <i className="bi bi-pencil-fill"></i>
                                                        </button>

                                                    </div>

                                                    {
                                                        !chatSelected.contact.assigned_id && (
                                                            <div className="mx-3 mt-3">
                                                                <div className="alert alert-blue">
                                                                    <div className="d-flex">
                                                                        <i className="bi bi-exclamation-triangle me-2"></i>
                                                                        <p>Es importante completar la información de este usuario para asignarlo a una ruta o ID</p>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        )
                                                    }



                                                    <div className="messages-container" >

                                                        {
                                                            messages.map((message) => {
                                                                // if (index === messages.length - 1) {
                                                                //     scrollToBottom()
                                                                // }

                                                                return (
                                                                    <div className={`message-item ${message.type === 'OUTBOUND' ? '' : 'left'} ${message.format === 'STICKER' ? 'sticker' : ''}`} key={message._id}>

                                                                        {(message.format === 'TEXT' || message.format === 'CONTENT_REPOSITORY') && <div dangerouslySetInnerHTML={{ __html: messageParseService.parseTxt(message.message) }} />}
                                                                        {message.format === 'IMAGE' && (
                                                                            <div>
                                                                                <div className="d-flex message-multimedia">
                                                                                    <div className="icon"><i className="bi bi-camera-fill"></i></div>
                                                                                    <div className="flex-grow-1 ms-2">
                                                                                        <h6>Imagen recibido</h6>
                                                                                        <button className="btn-link">Ver imagen</button>
                                                                                    </div>
                                                                                </div>
                                                                                {message.message && <div dangerouslySetInnerHTML={{ __html: messageParseService.parseTxt(message.message) }} />}
                                                                            </div>
                                                                        )}
                                                                        {message.format === 'VIDEO' && (
                                                                            <div>
                                                                                <div className="d-flex message-multimedia">
                                                                                    <div className="icon"><i className="bi bi-camera-video-fill"></i></div>
                                                                                    <div className="flex-grow-1 ms-2">
                                                                                        <h6>Video recibido</h6>
                                                                                        <button className="btn-link">Ver video</button>
                                                                                    </div>
                                                                                </div>
                                                                                {message.message && <div dangerouslySetInnerHTML={{ __html: messageParseService.parseTxt(message.message) }} />}
                                                                            </div>
                                                                        )}
                                                                        {message.format === 'DOCUMENT' && (
                                                                            <div>
                                                                                <div className="d-flex message-multimedia">
                                                                                    <div className="icon"><i className="bi bi-file-earmark-fill"></i></div>
                                                                                    <div className="flex-grow-1 ms-2">
                                                                                        <h6>{message.message}</h6>
                                                                                        <button className="btn-link">Descargar archivo</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                        {message.format === 'AUDIO' && (
                                                                            <div>
                                                                                <Audio />
                                                                            </div>
                                                                        )}
                                                                        {message.format === 'LOCATION' && (
                                                                            <div>
                                                                                <div className="">
                                                                                    <iframe src={`https://maps.google.com/maps?q=${message.format_detail.latitude},${message.format_detail.longitude}&hl=es;z=10&output=embed`} width="100%" height="200" loading="lazy" allowFullScreen referrerPolicy="no-referrer-when-downgrade" title='Location'></iframe>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                        {message.format === 'STICKER' && (
                                                                            <div className="message-multimedia sticker">
                                                                                <img src={message.format_detail.url} alt='Sticker' />
                                                                            </div>
                                                                        )}

                                                                        <p>{moment(message.created_at).format('DD/MM/YY HH:mm')}</p>
                                                                    </div>
                                                                )
                                                            })
                                                        }

                                                        {
                                                            hasActiveSession >= 24 && (
                                                                <div className={`message-item no-session`}>
                                                                    <div className="d-flex">
                                                                        <i className="bi bi-info-circle me-2"></i>
                                                                        La sesión caducó, envía una plantilla de texto para contactar a {`${chatSelected.contact.first_name} ${chatSelected.contact.last_name}`}
                                                                    </div>
                                                                </div>
                                                            )
                                                        }



                                                    </div>

                                                    <div className="d-flex bg-white p-3">
                                                        <button type="button" className="btn btn-link btn-icon me-2" onClick={() => setModalTemplate({ show: true, data: chatSelected })} >
                                                            <i className="bi bi-plus-lg font-22"></i>
                                                        </button>
                                                        <textarea className="form-control" rows={1} disabled={hasActiveSession >= 24} {...register('message', { required: true })} ></textarea>
                                                        <button type="button" className="btn btn-primary btn-icon ms-2" disabled={hasActiveSession >= 24 || !isValid || isSubmitting} onClick={handleSubmit(submit)}>
                                                            {isSubmitting && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>}
                                                            {!isSubmitting && <i className="bi bi-send-fill"></i>}
                                                        </button>
                                                    </div>
                                                    {/* <div ref={listChatContainerRef}></div> */}
                                                </>
                                            )
                                        }


                                    </div>
                                </div>
                            </div>
                        </div>
                        )
                    }

                    
                </div>

                <Footer className="alternative" />
            </main>


            <ContactFormModal show={modalContact.show} data={modalContact.data} onRequestClose={handleHideModalContact} />

            <TemplatesModal
                show={modalTemplate.show}
                data={modalTemplate.data}
                onRequestClose={handleHideModalTemplates}
            />
        </>
    )
}
export default InboxPage