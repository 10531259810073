import { io } from "socket.io-client";
import {  END_POINT_WS, STORAGE } from "../constants";
import { LocalStorageService } from "./localStoraService";


class SocketIOService {

    private socket;

    start() {

        const sessionToken = LocalStorageService.get(STORAGE.TOKEN)

        this.socket = io(END_POINT_WS, {
            auth: {
                token: sessionToken,
                join_to_room: true,
            }
        })


        this.socket.on('connect', () => {
            console.log('Socket IO connected::', this.socket.id);
        });

        this.socket.on('disconnect', () => {
            console.log('Socket IO disconnected::', this.socket.id);
        });
        this.socket.on('connect_error', (error) => {
            console.log('connect_error::', error);
        });

        // this.socket.on('unauthorized', (payload) => this.handleUnauthorized(payload));
        

        return this.socket;
    }
}

export default new SocketIOService();