import { useForm } from "react-hook-form";
import Button from "../../components/Button"
import TextArea from "../../components/TextArea"
import { SettingsService } from "../../services/settings";
import Alert from "../../components/Alert";
import { useEffect } from "react";

function DefaultMessagePartial() {


    const defaultValues = {
        message: ''
    }
    const { register, handleSubmit, reset, watch, formState: { errors, isValid, isSubmitting } } = useForm({
        mode: 'onChange',
        defaultValues
    });

    const submit = async(data) => {
        try {
            await SettingsService.setDefaultMessage(data.message)
            
            Alert.fire({
                title: 'Mensaje actualizado',
                text: `Se actualizo correctamente el mensaje de bienvenida`
            });

            // reset();
        } catch (error) {

            console.error(error);
            Alert.fire({
                type: 'error',
                title: 'Error',
                text: `Ocurrio un error al actualizar el mensaje de bienvenida`
            });
            
        }
    }

    const getDefaultMessage = async() => {
        const response = await SettingsService.getDefaultMessage()
        reset({ message: response.default_message })
    }

    useEffect(() => {
        getDefaultMessage()
    }, [])
    
    
    return (
        <>
            <div className='d-flex align-items-center mb-3'>
                <h5>Mensaje de bienvenida</h5>
                <Button  className="btn btn-outline-primary ms-auto" isLoading={isSubmitting} onClick={handleSubmit(submit)}>Actualizar</Button>
            </div>

            <div className='card m-b-16'>
                <div className='card-body'>
                    <TextArea label='Mensaje *' errors={errors.message} {...register('message', { required: true })} />
                </div>
            </div>            
        </>
    )
}
export default DefaultMessagePartial