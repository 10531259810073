import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useSession from "../../hooks/useSession";


function NotFoundPage() {
    
    const navigate = useNavigate();
    
    const { session } = useSession()

    const handleHome = () => {

        const role = session.role.key;

        switch (role) {
            case 'admin':
                navigate('/')
                break;
        
            default:
                navigate('/materials')
        }
    }
    
    useEffect(() => {
        document.body.className = '';

        return () => {
            document.body.className = 'has-navbar-vertical-aside navbar-vertical-aside-show-xl footer-offset';
        };
    }, [])


    return (
        <div className="d-flex align-items-center min-h-100">
            <main id="content" role="main" className="main bg-white pt-0">

                <div className="container-fluid px-0">

                    <div className="row mx-0">
                        <div className="col-lg-6 d-flex justify-content-center align-items-center min-vh-lg-100">
                            <div className="w-100 content-space-t-4 content-space-t-lg-2 content-space-b-1" style={{ maxWidth: '25rem' }} >

                                <div className="js-validate needs-validation" >
                                    <div className="">
                                        <img className="m-b-40" src="/assets/img/logo.png" alt="Projecter" />

                                        <div className="m-b-16">
                                            <h1 className=" m-b-8">404</h1>
                                            <p>Lo sentimos, la página que busca no se encuentra.</p>

                                            <div className="d-grid mt-5">
                                                <button className="btn btn-primary btn-lg  " onClick={handleHome}>Inicio</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div
                            className="col-lg-6 d-none d-lg-flex justify-content-center align-items-center min-vh-lg-100 position-relative bg-light px-0"
                            style={{
                                backgroundImage: 'url(/assets/img/auth-alt-bg.jpg)',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center'
                            }}
                        >
                        </div>


                    </div>
                </div>
            </main >
        </div >
    )
}

export default NotFoundPage