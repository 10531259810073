import SyntaxHighlighter from 'react-syntax-highlighter';

function SurveyPartial() {
    return (
        <div className="card">

            <div className="card-body">
                <h5 className="card-title">Encuestas</h5>
                <p className="card-text">Dentro del proyecto se pueden crear encuestas que permitan obtener preferencias, elecciones u opiniones de los clientes o usuarios. Para que esto funcione, se deben incluir los siguientes elementos:</p>
                
                <ul className="card-text mt-3">
                    <li><code>name</code> Nombre de la encuesta</li>
                    <li><code>key</code> Identificador de pregunta, puedes incluir toda la pregunta o solo un texto que ayude a saber a qué hace referencia</li>
                    <li><code>value</code> Respuesta de la pregunta</li>
                </ul>
                <div className='mt-3'>
                    <SyntaxHighlighter language="javascript" showInlineLineNumbers >
                        {
                            `
window.ProjecterBridge.survey("Preferencias", "¿Cúal es tu color favorito?", "Azul");

`
                        }
                    </SyntaxHighlighter>
                </div>

                
                <p  className="card-text mt-3"><strong>Nota:</strong> puedes sobrescribir la respuesta de una pregunta utilizando el mismo <code>name</code> y <code>key</code> </p>
                
                <p  className="card-text mt-3">Puedes registrar tantas preguntas y tantas encuestas como se necesiten, pero para agrupar preguntas de una misma encuesta se debe de usar el mismo <code>name</code> de encuesta al registrarlo</p>
               
            </div>
        </div>
    )
}
export default SurveyPartial