import { useState } from "react"


export default function useRequest() {

    const [isPending, setIsPending] = useState(false)

    const request = async(req) => {
        try {
            setIsPending(true)
            const response = await req()

            return response;
        } catch (error: any) {
            console.error(error)
            throw new Error(error)
        } finally {
            setIsPending(false)
        }
    } 

    return {
        request,
        isPending
    }
}