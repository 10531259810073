import { useEffect, useState } from "react";
import Alert from "../../components/Alert";
import { TwoStepVerificationService } from "../../services/twoStepVerificationService"


function TabSecurityPage({ partial, goTo }: any) {

  const [status, setStatus] = useState(false)

  const getStatus = async () => {
    const response = await TwoStepVerificationService.getStatus();
    setStatus(response.two_step_verification);
  }

  const handleStatus = async(value: any) => {

    const response = await Alert.danger({
      title: '¡Atención!', 
      text: value ? 'Esta acción activará la autenticación en 2 pasos para todos los colaboradores.': 'Esta acción desactivará la autenticación en 2 pasos para todos los colaboradores.',
      confirmText: value ? 'activar autenticación': 'desactivar autenticación',
    });

    if(response) {
      await TwoStepVerificationService.toggleStatus()
      await getStatus()
    } 

    Alert.close()
  }

  useEffect(() => {
    getStatus()
  }, [])


  return (
    <div className='row'>
      <div className='col-4'>
        <p className="text-dark fw-bold">Acceso de colaboradores</p>
        <p>Administra la forma en que los colaboradores accesan a su cuenta</p>
      </div>
      <div className='col-8'>
        <div className="card">
          <div className="card-body">
            <div className="d-flex align-items-center">
              <div>
                <p className="text-dark fw-bold">Autenticación dos pasos</p>
                <p>Seguridad adicional para proteger la cuenta de los colaboradores.</p>
              </div>

              <div className="ms-auto nowrap">
                <div className="form-check form-switch ">
                  <label className="form-check-label" htmlFor="is_active">Activo</label>
                  <input type="checkbox" className="form-check-input form-check-info" id="is_active" checked={status}  onChange={ ({target}) => handleStatus(target.checked)} />
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TabSecurityPage