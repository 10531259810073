import { useEffect, useState } from "react"
import Aside from "../../components/Aside"
import Footer from "../../components/Footer"
import Header from "../../components/Header"
import { useNavigate, useSearchParams } from "react-router-dom";
import InstallPartial from "./InstallPartial";
import ClicksPartial from "./ClicksPartial";
import SharePartial from "./SharePartial";
import SlidesPartial from "./SlidesPartial";
import SurveyPartial from "./SurveyPartial";
import TokensPartial from "./TokensPartial";

function DocumentationPage() {

    const navigate = useNavigate();
    
    const [searchParams] = useSearchParams();

    const [partial, setPartial] = useState<any>(null)


    const goTo = (partial: string) => {
        navigate({
            pathname: '',
            search: `?partial=${partial}`
        })
    }


    useEffect(() => {
        setPartial(searchParams.get('partial'))
    }, [searchParams])

    return (
        <>
            <Header>
                <div>
                    <h4>Documentación</h4>
                    {/* <p>Lorem ipsum</p> */}
                </div>

            </Header>

            <Aside />

            <main role="main" className="main">


                <div className="content container px-5">

                    <div className='row'>
                        <div className='col-4'>
                            <div className='card'>
                                <div className='card-body p-0 py-2'>
                                    <div className={'d-flex align-items-start p-3 cursor-pointer  ' + (!partial || partial === 'install' ? 'bg-light' : '')} onClick={() => goTo('install')}>
                                        <div>
                                            <p className='text-dark fw-semibold'>Instalación</p>
                                            <p>Instala la biblioteca de Projecter Bridge en tu proyecto.</p>
                                        </div>
                                    </div>
                                    <div className={'d-flex align-items-start p-3 cursor-pointer  ' + (partial === 'slides' ? 'bg-light' : '')} onClick={() => goTo('slides')}>
                                        <div>
                                            <p className='text-dark fw-semibold'>Slides</p>
                                            <p>¿Cómo hacer el tracking de actividad de cada slide?</p>
                                        </div>
                                    </div>
                                    <div className={'d-flex align-items-start p-3 cursor-pointer  ' + (partial === 'clicks' ? 'bg-light' : '')} onClick={() => goTo('clicks')}>
                                        <div>
                                            <p className='text-dark fw-semibold'>Clics</p>
                                            <p>¿Cómo hacer tracking sobre eventos clic o tap específicos?</p>
                                        </div>
                                    </div>
                                    <div className={'d-flex align-items-start p-3 cursor-pointer  ' + (partial === 'survey' ? 'bg-light' : '')} onClick={() => goTo('survey')}>
                                        <div>
                                            <p className='text-dark fw-semibold'>Encuestas</p>
                                            <p>¿Cómo generar y rastrear surveys? </p>
                                        </div>
                                    </div>
                                    <div className={'d-flex align-items-start p-3 cursor-pointer  ' + (partial === 'share' ? 'bg-light' : '')} onClick={() => goTo('share')}>
                                        <div>
                                            <p className='text-dark fw-semibold'>Compartir</p>
                                            <p>Compartir recursos desde el e-detailing</p>
                                        </div>
                                    </div>
                                    <div className={'d-flex align-items-start p-3 cursor-pointer  ' + (partial === 'tokens' ? 'bg-light' : '')} onClick={() => goTo('tokens')}>
                                        <div>
                                            <p className='text-dark fw-semibold'>Tokens</p>
                                            <p>Personaliza la experiencia del e-detailing usando tokens.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-8'>
                            <div className={(!partial || partial === 'install') ? '' : 'd-none'}>
                                <InstallPartial />
                            </div>
                            <div className={(partial === 'slides') ? '' : 'd-none'}>
                                <SlidesPartial />
                            </div>
                            <div className={(partial === 'clicks') ? '' : 'd-none'}>
                                <ClicksPartial />
                            </div>
                            <div className={(partial === 'survey') ? '' : 'd-none'}>
                                <SurveyPartial />
                            </div>
                            <div className={(partial === 'share') ? '' : 'd-none'}>
                                <SharePartial />
                            </div>
                            <div className={(partial === 'tokens') ? '' : 'd-none'}>
                                <TokensPartial />
                            </div>
                        </div>
                    </div>

                </div>

                <Footer />
            </main>
        </>
    )
}
export default DocumentationPage