import SyntaxHighlighter from 'react-syntax-highlighter';

function SharePartial() {
    return (
        <div className="card">

            <div className="card-body">
                <h5 className="card-title">Compartir</h5>
                <p className="card-text">Es posible compartir recursos desde la sesión de e-detailing utilizando las opciones de share nativas del dispositivo (iPad).</p>
                
                <p  className="card-text mt-3">Para compartir un archivo se requiere solo dos párametros:</p>
                <ul className="card-text mt-3">
                    <li><code>name</code> Nombre o identificador del archivo</li>
                    <li><code>path</code> Ruta relativa del archivo iniciando con un <code>/</code></li>
                    
                </ul>
                <div className='mt-3'>
                    <SyntaxHighlighter language="javascript" showInlineLineNumbers >
                        {
                            `
window.ProjecterBridge.share("Resultados 2023", "/asses/pdfs/resultados-2023.pdf");

`
                        }
                    </SyntaxHighlighter>
                </div>
              
            </div>
        </div>
    )
}
export default SharePartial