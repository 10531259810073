import ReactDOM from 'react-dom/client';
import AlertDanger from './AlertDanger';

declare const bootstrap: any;

const emptyElement = (container: string) => {
    const body = document.getElementsByTagName('body')[0];

    const exists = document.getElementsByClassName(container)
    if (exists && exists.length) {
        body.removeChild(exists[0])
    }
}

const template = (title: string, text: string, type: string) => {
    return (
        <>
            <div className={`d-flex p-t-30 p-b-30 p-l-16 p-r-16 ${type === 'success' ? 'custom-alert-success': 'custom-alert-error'}`}>
                { type === 'success' && <img src={require('../assets/icons/success.png')} alt='Alert' style={{ width: '32px', height: '32px' }} className='m-r-24' /> }
                { type === 'error' && <img src={require('../assets/icons/error.png')} alt='Alert' style={{ width: '32px', height: '32px' }} className='m-r-24' /> }
                <div>
                    <h6 className='fw-semibold title '>{title}</h6>
                    <p className='desc'>{text}</p>
                </div>
                <div className='ms-auto'>
                    <i className="bi bi-x-lg cursor-pointer h3" onClick={() => emptyElement('projecter-alert-container')}></i>
                </div>
            </div>
        </>
    )
}


const Alert = {

    fire: ({ title, text, type = 'success' }: any) => {
        const body = document.getElementsByTagName('body')[0];

        emptyElement('projecter-alert-container')

        const container = document.createElement('div')
        container.classList.add('projecter-alert-container')

        body.appendChild(container)

        const rootAlert = ReactDOM.createRoot(container);
        rootAlert.render(template(title, text, type))

        setTimeout(() => emptyElement('projecter-alert-container'), 4000)
    },
    danger: async({ title, text, confirmText = 'continuar', cancelText = 'cancelar' }: any) => {
    
        return new Promise((resolve) => {
            let modal: any;

            const body = document.getElementsByTagName('body')[0];

            emptyElement('projecter-alert-container-modal')
    
            const container = document.createElement('div')
            container.classList.add('projecter-alert-container-modal')
    
            body.appendChild(container)
    
            const rootAlert = ReactDOM.createRoot(container);
            
            const onConfirm = () => {
                resolve(true);
            }
    
            rootAlert.render(<AlertDanger  title={title} text={text} onConfirm={onConfirm} confirmText={confirmText} cancelText={cancelText}/>)

            setTimeout(() => {
                modal = new bootstrap.Modal(document.getElementById('projecter-alert-container-alert-danger'), {})
                modal.show();
            }, 100)
        })

    },
    setMessageError: (message: string) => {
        const element = document.getElementById('projecter-alert-container-alert-danger-error-message')
        const btn = document.getElementById('projecter-alert-container-alert-danger-btn')
        if(element) {
            const containerMesssage = element.querySelector('.message');
            containerMesssage!.innerHTML = message;
            element.style.display = 'block';
            btn!.style.display = 'none';
        }
    },
    close: () => {
        const modal = bootstrap.Modal.getInstance(document.getElementById('projecter-alert-container-alert-danger')) 
        modal.hide();
        setTimeout(() => emptyElement('projecter-alert-container-modal'), 200)
    }
}


export default Alert;
