const DEFAULT_IMG = require('../assets/img/empty-state.png')
const ALTERNATIVE_IMG = require('../assets/img/empty-state-alternative.png')

function EmptyState({ title = 'Nada que mostrar', text = 'Aún no tenemos datos relevantes para ti, vuelve más tarde. ', className='m-t-60', alternative = false}) {
  return (
    <div className={`text-center  ${className}`}>
        {!alternative && <img src={DEFAULT_IMG} className='me-3' style={{ width: '317px' }} alt='Sin ' />}
        {alternative && <img src={ALTERNATIVE_IMG} className='me-3' style={{ width: '317px' }} alt='Sin ' />}
        <h4 className="m-t-16 m-b-8">{title}</h4>
        <p className="large">{text}</p>
    </div>
  )
}
export default EmptyState