import { END_POINT } from "../constants";
import { HTTP } from "./http";

export const TwoStepVerificationService = {

    getStatus: () => {
        return HTTP.get<any, any>(`${END_POINT}settings/two_step_verification`);
    },
    toggleStatus: () => {
        return HTTP.post<any, any>(`${END_POINT}settings/toggle/two_step_verification`);
    },
}