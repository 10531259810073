import { useEffect, useId, useState } from "react"
import { Controller, useFieldArray } from "react-hook-form"
import Select from 'react-select'
import * as XLSX from 'xlsx';
import { TemplatesService } from "../../services/templatesService"
import { TemplateHeader, TemplateBody, TemplateFooter, TemplateButtons, TemplateParse } from "../../utils/templateWhatsapp"
import { CampaignService } from "../../services/campaignService"


function WhatsAppPartial({ control, errors, register, getValues, setValue, watch, contentRepositories}) {

    // whatsapp: {
    //     template: '',
    //     tokens: [],
    // }

    const varsSource: any = [
        {
            value: 'system',
            label: 'Sistema',
        },
        {
            value: 'const',
            label: 'Constante',
        },
        // {
        //     value: 'content_repository',
        //     label: 'Material',
        // },
        {
            value: 'custom',
            label: 'Dinámico',
        },
    ]

    const systemVars = [
        {
            label: 'contact.first_name',
            value: 'contact.first_name',
        },
        {
            label: 'contact.last_name',
            value: 'contact.last_name',
        },
        {
            label: 'contact.email',
            value: 'contact.email',
        },
        {
            label: 'contact.phone_number',
            value: 'contact.phone_number',
        },
        {
            label: 'contact.type',
            value: 'contact.type',
        },
        {
            label: 'contact.medical_specialty',
            value: 'contact.medical_specialty',
        },
    ]

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'whatsapp.tokens'
    });
    
    const fileId = useId()

    
    const [templates, setTemplates] = useState([])
    const [templatesList, setTemplatesList] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [headerColumns, setHeaderColumns] = useState([])

    const include_content_repository = watch('include_content_repository')
    const template = watch('whatsapp.template')
    const file = watch('whatsapp.file')

    const [selectedTemplate, setSelectedTemplate] = useState<{
        header: TemplateHeader,
        body: TemplateBody,
        bodyTokens: Array<any>,
        footer: TemplateFooter,
        buttons: TemplateButtons
    }>()

    const formatTypeContact = (type) => {

        switch (type) {
            case 'DOCTOR':
                return 'Doctor'
            case 'PHARMACY':
                return 'Farmacia'
            case 'OTHER':
                return 'Otro'

            default:
                return type;
        }

    }

    const downloadContacts = async () => {
        const segmentations = getValues('segmentations')

        const response = await CampaignService.getContacts({ segmentations: segmentations.join(',') })

        const workbook = XLSX.utils.book_new();

        const header = [
            'contact.id',
            'contact.first_name',
            'contact.last_name',
            'contact.email',
            'contact.phone_number',
            'contact.type',
            'contact.medical_specialty',
            'contact.segments',
        ]

        const data = response.map(row => {
            return {
                'contact.id': row._id,
                'contact.first_name': row.first_name,
                'contact.last_name': row.last_name,
                'contact.email': row.email,
                'contact.phone_number': `${row.phones[0].phone['country_code']}${row.phones[0].phone['phone_number']}`,
                'contact.type': formatTypeContact(row.type),
                'contact.medical_specialty': row?.medical_specialty?.name || '',
                'contact.segments': row.segmentations ? row.segmentations.map( s => s.title).join(', '): [] ,
            }
        });

        const worksheet = XLSX.utils.json_to_sheet(data, { header })

        XLSX.utils.book_append_sheet(workbook, worksheet);

        XLSX.writeFile(workbook, `contacts.xlsx`);

    }

    const isTemplateForMaterial = (template) => {
        const buttons = template.components.find( c => c.type === 'BUTTONS' );
    
        if(!buttons) return false;

        return buttons.buttons.find( b => b.type === 'URL' && b.url === 'https://shortty.link/{{1}}' );
    }

    useEffect(() => {
      const getTemplates = async () => {
        const response = await TemplatesService.getAllApproved();
        // console.log(response)
        setTemplatesList(response)
      }

      getTemplates()
    }, [])
    
    useEffect(() => {
        if(include_content_repository) {

            const templatesLinks = templatesList.filter( t => isTemplateForMaterial(t))
            setTemplates(templatesLinks.map((a: any) => ({ value: a._id, label: `${a.name} (${a.language})` })))
        } else {

            const templatesNotLinks = templatesList.filter( t => !isTemplateForMaterial(t))
            setTemplates(templatesNotLinks.map((a: any) => ({ value: a._id, label: `${a.name} (${a.language})` })))
        }

        setValue('whatsapp.template', '')

    }, [include_content_repository, templatesList])

    useEffect(() => {
        const t = templatesList.find(t => t._id === template)
        if (t) {
            const templateParsed = TemplateParse(t.components)
            // console.log(templateParsed)
            setSelectedTemplate(templateParsed)
        } else {
            setSelectedTemplate(null)
        }
    }, [template])

    useEffect(() => {
        if (selectedTemplate) {
            remove()
            selectedTemplate.bodyTokens.forEach( t => append({ 
                token: t, 
                source: '', 
                const: '',
                custom: '',
                system: '',
            }, { shouldFocus: false}))

        }
    }, [selectedTemplate])

    useEffect(() => {

        if (file[0]) {

            const header = [
                'contact.id',
                'contact.first_name',
                'contact.last_name',
                'contact.email',
                'contact.phone_number',
                'contact.type',
                'contact.medical_specialty',
            ]

            const reader = new FileReader();

            reader.onload = (event: any) => {

                const workbook = XLSX.read(event.target.result, { type: 'binary' });
                const sheet_name_list = workbook.SheetNames;

                const xlData = XLSX.utils.sheet_to_json(workbook.Sheets[sheet_name_list[0]], { blankrows: false });

                const headerFile = Object.keys(xlData[0])
                
                let headerColumnsTMP = [];

                for (const row of headerFile) {
                    if(!header.includes(row)) {
                        headerColumnsTMP.push(row)
                    }
                }

                setHeaderColumns(headerColumnsTMP.map(h => ({label: h, value: h})));


            };
            reader.onerror = function (ex) {
                console.log(ex);
            };
            reader.readAsBinaryString(file[0]);

        }


    }, [file])
    

    return (
        <div className='row mt-5'>
            <div className='col-3'>
                <p className="text-dark fw-bold text-uppercase mb-3">Detalle plantilla</p>
                <p>Personaliza el contenido de la plantilla.</p>
            </div>
            <div className='col-9'>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-6">

                                <div className="m-b-24">
                                    <div className="form-check form-switch ">
                                        <label className="form-check-label" htmlFor="include_content_repository">Vincular material</label>
                                        <input type="checkbox" className="form-check-input form-check-info" id="include_content_repository" {...register('include_content_repository')} />
                                    </div>
                                </div>

                                {
                                    include_content_repository && (
                                        <div className="m-b-24">
                                            {
                                                !watch('segmentations').length &&  (
                                                    <div className="alert alert-blue">Para elegir un material, agrega uno o más segmentos para buscar los materiales asociados a dichos segmentos.</div>
                                                )
                                            }
                                            
                                            <label className="form-label" >Material *</label>
                                            <Controller
                                                control={control}
                                                name='content_repository'
                                                rules={{ required: false }}
                                                render={({
                                                    field: { onChange, onBlur, value },
                                                    fieldState: { error },
                                                }) => (
                                                    <Select
                                                        placeholder='Seleccionar un material'
                                                        options={contentRepositories}
                                                        className={error ? 'form-select-custom is-invalid' : ''}
                                                        classNamePrefix='form-select-custom'
                                                        onBlur={onBlur}
                                                        onChange={(value: any) => onChange(value.value)}
                                                        value={contentRepositories.filter( c => c.value === value)}
                                                    />
                                                )}
                                            />
                                            <span className="invalid-feedback">Campo requerido.</span>
                                        </div>
                                    )
                                }
                                


                                <div className="m-b-24">
                                    <label className="form-label" >PLANTILLA DE MENSAJE*</label>
                                    <Controller
                                        control={control}
                                        name='whatsapp.template'
                                        rules={{ required: true }}
                                        render={({
                                            field: { onChange, onBlur, value },
                                            fieldState: { error },
                                        }) => (
                                            <Select
                                                placeholder='Selecciona una plantilla'
                                                options={templates}
                                                className={error ? 'form-select-custom is-invalid' : ''}
                                                classNamePrefix='form-select-custom'
                                                onBlur={onBlur}
                                                onChange={(value: any) => onChange(value.value)}
                                                value={templates.filter( c => c.value === value)}
                                            />
                                        )}
                                    />
                                    <span className="invalid-feedback">Campo requerido.</span>
                                </div>

                                {
                                    fields.length > 0 && (
                                        <>
                                            <p className="text-dark fw-semibold mb-3">Variables de plantilla</p>

                                            <div className="m-b-24">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="d-flex align-items-center mb-3">
                                                            <p className="text-dark fw-bold text-uppercase font-12 mb-1">variables desde archivo <span className="form-label-secondary">(Opcional)</span></p>
                                                            {
                                                                watch('segmentations').length > 0 && (
                                                                    <div className="ms-auto">
                                                                        <label className="btn btn-simple btn-sm " htmlFor={fileId} >Agregar archivo</label>
                                                                        <input type="file" id={fileId} className="d-none" {...register('whatsapp.file')} />
                                                                    </div>
                                                                )
                                                            }
                                                            
                                                        </div>

                                                        {/* <p className="mb-3">Si deseas agregar variables dinámicas, primero debes descargar la plantilla de contactos para agregar las variables asociadas a cada contacto. </p> */}
                                                        
                                                        {
                                                            watch('segmentations').length  ? (
                                                                <p>Para usar variables personalizadas descarga <button className="btn btn-link-alternative fw-semibold p-0 m-0" onClick={downloadContacts}>aquí</button> la plantilla de los segmentos seleccionados.</p>
                                                            ):
                                                                <>
                                                                    <div className="alert alert-blue">Añade uno o varios segmentos para permitir la descarga de la plantilla de contactos.</div>
                                                                </>
                                                        }

                                                        {
                                                            file[0] && headerColumns.length === 0 && (
                                                                <div className="alert alert-warning">
                                                                    El archivo no contiene columnas nuevas
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            { 
                                                fields.map(( field: any, index) => (
                                                        <div className="row" key={field.id}>
                                                            <div className="col-12"><p className="text-dark fw-semibold mb-3">{field.token}</p></div>

                                                            <div className="col-6">
                                                                <div className="m-b-24">
                                                                    <label className="form-label" >Origen *</label>
                                                                    <Controller
                                                                        control={control}
                                                                        name={`whatsapp.tokens.${index}.source`}
                                                                        rules={{ required: true }}
                                                                        render={({
                                                                            field: { onChange, onBlur, value },
                                                                            fieldState: { error },
                                                                        }) => (
                                                                            <Select
                                                                                placeholder='Origen'
                                                                                options={varsSource}
                                                                                className={error ? 'form-select-custom is-invalid' : ''}
                                                                                classNamePrefix='form-select-custom'
                                                                                onBlur={onBlur}
                                                                                onChange={(value: any) => onChange(value.value)}
                                                                                value={varsSource.find( v => v.value === value) || ''}
                                                                            />
                                                                        )}
                                                                    />
                                                                    <span className="invalid-feedback">Campo requerido.</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-6">
                                                                <div className="m-b-24">
                                                                    <label className="form-label" >Valor *</label>
                                                                    
                                                                    { (!watch(`whatsapp.tokens.${index}.source`) || watch(`whatsapp.tokens.${index}.source`) === 'const') && <input  className={'form-control form-control-lg ' + (errors.whatsapp?.tokens?.[index]?.['const'] ? 'is-invalid' : '')} {...register(`whatsapp.tokens.${index}.const`, { required: true, disabled: watch(`whatsapp.tokens.${index}.source`) !== 'const' })} /> }

                                                                    {
                                                                        watch(`whatsapp.tokens.${index}.source`) === 'system' && (
                                                                            <>
                                                                                <Controller
                                                                                    control={control}
                                                                                    name={`whatsapp.tokens.${index}.system`}
                                                                                    rules={{ required: true }}
                                                                                    render={({
                                                                                        field: { onChange, onBlur, value },
                                                                                        fieldState: { error },
                                                                                    }) => (
                                                                                        <Select
                                                                                            placeholder='Variable'
                                                                                            options={systemVars}
                                                                                            className={error ? 'form-select-custom is-invalid' : ''}
                                                                                            classNamePrefix='form-select-custom'
                                                                                            onBlur={onBlur}
                                                                                            onChange={(value: any) => onChange(value.value)}
                                                                                            value={systemVars.find( v => v.value === value) || ''}
                                                                                        />
                                                                                    )}
                                                                                />
                                                                            </>
                                                                        )
                                                                    }
                                                                    {
                                                                        watch(`whatsapp.tokens.${index}.source`) === 'custom' && (
                                                                            <>
                                                                                <Controller
                                                                                    control={control}
                                                                                    name={`whatsapp.tokens.${index}.custom`}
                                                                                    rules={{ required: true }}
                                                                                    render={({
                                                                                        field: { onChange, onBlur, value },
                                                                                        fieldState: { error },
                                                                                    }) => (

                                                                                        <Select
                                                                                            placeholder='Columna'
                                                                                            options={headerColumns}
                                                                                            className={error ? 'form-select-custom is-invalid' : ''}
                                                                                            classNamePrefix='form-select-custom'
                                                                                            onBlur={onBlur}
                                                                                            onChange={(value: any) => onChange(value.value)}
                                                                                            value={headerColumns.find( v => v.value === value ) || ''}
                                                                                        />
                                                                                    )}
                                                                                />
                                                                            </>
                                                                        )
                                                                    }

                                                                    <span className="invalid-feedback">Campo requerido.</span>
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                ) 
                                            }
                                            
                                        </>
                                    )
                                }

                            </div>

                            <div className={'col-6 separator-left'}>

                                <div>
                                    <p className="text-dark fw-semibold text-uppercase font-12 mb-3">Previsualización</p>

                                    {
                                        selectedTemplate ? (
                                            <div className="preview-wa">
                                                <div className="preview-wa-message">
                                                    {selectedTemplate.header && <p className="wa-title">{selectedTemplate.header.text}</p>}
                                                    <p className="wa-body" dangerouslySetInnerHTML={{ __html: selectedTemplate.body.text }} />
                                                    {selectedTemplate.footer && <p className="wa-footer">{selectedTemplate.footer.text}</p>}
                                                </div>

                                                {
                                                    selectedTemplate.buttons && selectedTemplate.buttons.buttons.map((b, i) => (<div className="preview-wa-button" key={i}>{b.text}</div>))
                                                }
                                            </div>
                                        ): 
                                        (
                                            <div className="alert alert-blue">Elige una plantilla para ver una vista previa.</div>
                                        )
                                    }
                                </div>

                                {/* <div className="text-center mt-5">
                                    <button type="button" className="btn btn-outline-primary btn-sm me-2" onClick={() => setShowModal(true)} >Enviar mensaje de prueba</button>
                                </div> */}

                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default WhatsAppPartial

