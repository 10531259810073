import { useState } from "react"
import { STORAGE } from "../constants"
import { LocalStorageService } from "../services/localStoraService"

function useSession() {

    const [session, setSession] = useState(LocalStorageService.get(STORAGE.SESSION))


    return { session }
}
export default useSession