import { useEffect, useRef, useState } from 'react'
import Aside from '../../components/Aside'
import Header from '../../components/Header'
import MessagesWidget from './messages/MessagesWidget';
import MaterialsWidget from './materials/MaterialsWidget';
import ActivitySpaceWidget from './space/ActivitySpaceWidget';
import useSession from '../../hooks/useSession';
import moment from 'moment';
import 'moment/locale/es'
import { ReportService } from '../../services/reportService';
import HTMLWidget from './htmls/HTMLWidget';

moment.locale('es')

declare const $: any;

function Dashboard() {

  const filtersOptions = [
    { value: 'month', label: 'Mes' },
    { value: 'range_time', label: 'Rango de tiempo' },
    { value: 'accumulated', label: 'Acumulado total' }
  ]

  const rangeElement = useRef(null)
  const { session } = useSession();

  const [rangeFilter, setRangeFilter] = useState({
    start: moment().subtract(6, 'days'),
    end: moment(),
  })

  const [messages, setMessages] = useState({
    avg_per_agent: 0,
    avg_per_chat: 0,
    total_free_outbound: 0,
    total_inbound: 0,
    total_materials: 0,
    total_templates: 0,
    conversion_rate: {
      total: 0,
      total_success: 0,
      total_failed: 0
    }
  })

  const [content, setContent] = useState({
    total_materials: 0,
    total_unique_sent: 0,
    total_opened: 0,
    total_viewed: 0
  })

  const [htmls, setHTMLS] = useState({
    total_sessiones: 0,
    total_unique_sessions: 0,
    total_per_day: 0,
    total_avg: 0
  })


  const getDashboardMessages = async () => {
    try {
      const request = await ReportService.getDashboardMessages(rangeFilter.start.format('YYYY-MM-DD'), rangeFilter.end.format('YYYY-MM-DD'))
      setMessages(request)
    } catch (error) {
      console.error(error)
    }
  }

  const getDashboardMaterials = async () => {
    try {
      const request = await ReportService.getDashboardContent(rangeFilter.start.format('YYYY-MM-DD'), rangeFilter.end.format('YYYY-MM-DD'))
      setContent(request)
    } catch (error) {
      console.error(error)
    }
  }

  const getDashboardHTMLS = async () => {
    try {
      const request = await ReportService.getDashboardHTMLS(rangeFilter.start.format('YYYY-MM-DD'), rangeFilter.end.format('YYYY-MM-DD'))
      setHTMLS(request)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getDashboardMessages();
    getDashboardMaterials();
    getDashboardHTMLS();
  }, [rangeFilter])


  useEffect(() => {
    if (!rangeElement || !rangeElement.current) return;

    $(rangeElement.current).find('.js-daterangepicker-predefined-preview').html(rangeFilter.start.format('MMMM D, YYYY') + ' - ' + rangeFilter.end.format('MMMM D, YYYY'));

    const cb = (start, end) => {
      setRangeFilter({
        start,
        end
      })

      $(rangeElement.current).find('.js-daterangepicker-predefined-preview').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
    }

    $(rangeElement.current).daterangepicker({
      startDate: rangeFilter.start,
      endDate: rangeFilter.end,
      ranges: {
        'Hoy': [moment(), moment()],
        'Ayer': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Últimos 7 días': [moment().subtract(6, 'days'), moment()],
        'Últimos 30 días': [moment().subtract(29, 'days'), moment()],
        'Mes actual': [moment().startOf('month'), moment().endOf('month')],
        'Mes pasado': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      },
      locale: {
        applyLabel: 'Aplicar',
        cancelLabel: 'Cancelar',
        customRangeLabel: 'Personalizado',
        daysOfWeek: [
          "Do",
          "Lu",
          "Ma",
          "Mi",
          "Ju",
          "Vi",
          "Sa"
        ],
        monthNames: [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ],
      }
    }, cb);

  }, [rangeElement])

  return (
    <>
      <Header>
        <div className='d-flex w-100'>
          <h4 className='flex-grow-1'>Hola, {session?.first_name} {session?.last_name}</h4>

          <button ref={rangeElement} className="btn btn-primary btn-sm">
            <i className="bi-calendar-week me-2"></i>
            <span className="js-daterangepicker-predefined-preview"></span>
          </button>

        </div>
      </Header>

      <Aside />

      <main role="main" className="main">
        <div className="content container px-5">

          <div className="row m-b-20">
            <div className="col-12">
              <MessagesWidget messages={messages} />
            </div>
          </div>

          <div className="row m-b-20">
            <div className="col-md-6">
              <MaterialsWidget highlights={content} range={rangeFilter} />
            </div>
            <div className="col-md-6">
              <HTMLWidget content={htmls} range={rangeFilter} />
            </div>
          </div>

          <div className="row m-b-20">
            <div className="col-12">
              <ActivitySpaceWidget />
            </div>
          </div>

        </div>
      </main>
    </>
  )
}

export default Dashboard