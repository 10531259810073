import { useNavigate } from "react-router-dom"
import Aside from "../../components/Aside"
import EmptyState from "../../components/EmptyState"
import Footer from "../../components/Footer"
import HandleNoData from "../../components/HandleNoData"
import Header from "../../components/Header"
import { CampaignService } from "../../services/campaignService";
import { useEffect, useState } from "react"
import moment from 'moment';
import 'moment/locale/es'

moment.locale('es')

function CampaignHomePage() {

    const navigate = useNavigate();

    const [rows, setRows] = useState([])

    const getCampaigns = async () => {
        const data = await CampaignService.getAll();
        setRows(data)
    }

    useEffect(() => {
        getCampaigns()
    }, [])
    

    
    return (
        <>
            <Header>
                <div>
                    <h4>Campañas</h4>
                    <p>Administra y crea nuevas campañas</p>
                </div>

                <div className="ms-auto">
                    <div className="dropdown">
                        <button className="btn btn-primary dropdown-toggle" type="button"  data-bs-toggle="dropdown" aria-expanded="false">
                            Crear nueva campaña
                        </button>
                        <div className="dropdown-menu mt-2" >
                            <button className="dropdown-item" onClick={() => navigate('/campaigns/form', { state: { type: 'whatsapp' } })}>WhatsApp</button>
                            <button className="dropdown-item" onClick={() => navigate('/campaigns/form', { state: { type: 'sms' } })}>SMS</button>
                            {/* <button className="dropdown-item" onClick={() => navigate('/campaigns/form', { state: { type: 'email' } })}>Email</button> */}
                        </div>
                    </div>
                </div>
            </Header>

            <Aside />

            <main role="main" className="main">

                <div className="bg-white border-bottom">
                    <div className="container p-t-12 p-b-12 px-5">
                        <div className="d-flex align-items-center">
                            <i className="bi bi-search m-l-8"></i>
                            <input className="form-control input-group-borderless form-control-flush" type="text" placeholder="Buscar" />
                        </div>
                    </div>
                </div>

                <div className="content container px-5">

                    <HandleNoData
                        condition={rows.length}
                        emptyComponent={<EmptyState />}
                    >
                        <table className="card-table table">
                            <thead>
                                <tr>
                                    <th scope="col">Nombre de campaña</th>
                                    <th scope="col">Tipo</th>
                                    <th scope="col">Fecha de envío</th>
                                    <th scope="col">Segmentación</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    rows.map( row => (
                                        <tr key={row._id}>
                                            <td>{row.name}</td>
                                            <td>{row.type}</td>
                                            <td>{moment(row.sent_at).format('D MMMM YYYY HH:mm')}</td>
                                            <td>{row.segmentations.map(s => s.title ).join(', ')}</td>
                                            <td></td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>

                    </HandleNoData>
                </div>

                <Footer />
            </main>
        </>
    )
}
export default CampaignHomePage