import SyntaxHighlighter from 'react-syntax-highlighter';


function InstallPartial() {

    const currentVersion = '1.0.0';
    const currentVersionFile = '1.0.0';

    return (
        <div className="card">

            <div className="card-body">
                <h5 className="card-title">Instalación</h5>
                <p className="card-text">Para asegurar el correcto rastreo y registro de la actividad de los materiales de e-detailing es imprescindible que el proyecto contenga la biblioteca de eventos <a href='#'>bundle-v{currentVersionFile}.js</a> de Projecter Bridge.  Los pasos son los siguientes:</p>
                
                <p  className="card-text mt-3">Descargar archivo <a href='#'>bundle-v{currentVersionFile}.js</a> e incluir en proyecto. Una práctica recomendada es incluir los archivos JavaScript justo antes de cerrar la etiqueta {'<body>'}.</p>
                <div className='mt-3'>
                    <SyntaxHighlighter language="javascript" showInlineLineNumbers >
                        {
                            `
<!DOCTYPE html>
<html>
<head>
    <title>Mi página</title>
</head>
<body>
    <!-- Contenido de la página -->

    <script src="ruta/archivo/bundle-v${currentVersionFile}.js"></script>
</body>
</html>

`
                        }
                    </SyntaxHighlighter>
                </div>
                
                <p  className="card-text mt-3">A veces, es necesario incluir archivos JavaScript en el {'<head>'} del documento, especialmente si se utilizan funciones o variables globales que deben estar disponibles antes de cargar el contenido del cuerpo. Sin embargo, ten en cuenta que esto puede ralentizar el tiempo de carga de la página, ya que el código JavaScript se descargará y ejecutará antes de que se muestre el contenido.</p>
                <div className='mt-3'>
                    <SyntaxHighlighter language="javascript" showInlineLineNumbers >
                        {
                            `
<!DOCTYPE html>
<html>
<head>
    <title>Mi página</title>

    <script src="ruta/archivo/bundle-v${currentVersionFile}.js"></script>
</head>
<body>
    <!-- Contenido de la página -->
</body>
</html>

`
                        }
                    </SyntaxHighlighter>
                </div>
               
                <p  className="card-text mt-3"><strong>¿Cómo saber si funciona?</strong> Si todo va bien al ser visualizado en navegador y abrir la consola de desarrollar se debe ver el mensaje <code>PROJECTER BRIDGE: current version {currentVersionFile}</code></p>
                <p  className="card-text mt-3"><strong>¿No se ve el mensaje?</strong> Abrir la consola de desarrollar y refrescar la página, si aún no se ve el mensaje, verificar que el archivo <a href='#'>bundle-v{currentVersionFile}.js</a> se agregó correctamente </p>
                
            </div>
        </div>
    )
}
export default InstallPartial