import useSession from "../hooks/useSession";

interface AllowProps {
    access: Array<'admin'|'agent'|'reviewer'|'agency'>;
    children: any;
}

function Allow({ access, children }: AllowProps) {
    
    const { session } = useSession()

    return access.indexOf(session.role.key) !== -1 ? children: null
}
export default Allow