import { useEffect, useMemo, useState } from 'react'
import Select from 'react-select'
import EmptyState from '../../../components/EmptyState'

function ContactTableSurvey({ contacts, data }) {
    const [selected, setSelected] = useState(null)

    const answers = useMemo(() => {
        if (selected) {
            return data.find( d => d.contact_detail.contact._id === selected.value).answers || []
        }

        return [];
    }, [selected])


    return (
        <>
            <div className="row">
                <div className="col-12 m-b-16">
                    <div className="d-flex align-items-end">
                        <div className="w-25">
                            <label className="form-label" >Contacto</label>
                            <Select
                                placeholder='Seleccionar un contacto'
                                options={contacts}
                                className={false ? 'form-select-custom is-invalid' : ''}
                                classNamePrefix='form-select-custom'
                                onChange={(value: any) => setSelected(value)}
                                value={selected}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <table className="card-table table">
                <thead>
                    <tr>
                        <th scope="col">Pregunta </th>
                        <th scope="col" className="text-center">Respuesta</th>
                    </tr>
                </thead>
                <tbody>
                    { answers.length === 0 && <tr><td colSpan={2}><EmptyState text={'Selecciona un contacto para mostrar información.'} className='m-t-1'/></td></tr> }
                    {
                        answers.map((row, index) => (
                            <tr key={index}>
                                <td>{`${row.question} ${row.is_mandatory ? '*':''}`}</td>
                                <td className="text-center">
                                    {
                                        !row.my_answers ? (
                                            <span className="badge bg-soft-danger text-danger">Sin respuesta</span>
                                        ): (
                                            Array.isArray(row.my_answers) ? row.my_answers.join(', '): row.my_answers 
                                        )
                                    }
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </>
    )
}
export default ContactTableSurvey