import { useMemo, useState } from 'react'
import MessageIcon from '@mui/icons-material/Message';
import {
  Chart as ChartJS, ArcElement, Tooltip, Legend,
  CategoryScale,
  Title
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import MessageDetail from './MessageDetail';
import CustomTooltip from '../../../components/Tooltip';
import { round } from '../../../utils/number';

ChartJS.register(
  ArcElement,
  CategoryScale,
  Title,
  Tooltip,
  Legend
);


function MessagesWidget({ messages }) {

  const [showDetail, setShowDetail] = useState(false)

  const dataChart = useMemo(() => {
    return {
      labels: ['Libres salientes', 'Entrantes', 'Materiales', 'Templates'],

      datasets: [
        {
          label: ' Total',
          data: [
            messages.total_free_outbound,
            messages.total_inbound,
            messages.total_materials,
            messages.total_templates,
          ],
          backgroundColor: [
            'rgba(0, 61, 115, 1)',
            // 'rgba(73, 165, 244, 1)',
            'rgba(0, 130, 242, 1)',
            'rgba(123, 173, 217, 1)',
            'rgba(192, 215, 235, 1)',
          ],
          borderColor: [
            'rgba(255, 255, 255, 1)',
          ],
          borderWidth: 4,
        },
      ],
    }
  }, [messages])

  const total = useMemo(() => (
    messages.total_free_outbound +
    messages.total_inbound +
    messages.total_materials +
    messages.total_templates
  ), [messages])

  const conversion_rate = useMemo(() => {
    if(!messages.conversion_rate.total) return 0;


    const rate = (messages.conversion_rate.total_success * 100) / messages.conversion_rate.total

    return round(rate)
  }, [messages])


  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="d-flex flex-between-center m-b-5">
            <div className="d-flex align-items-center">
              <div className="icon-item icon-item-sm m-r-8 bg-soft-blue text-blue">
                <MessageIcon fontSize="inherit" />
              </div>
              <h6 className="m-b-0">Mensajes</h6>
            </div>
            <button className="btn  btn-custom-link btn-sm" type="button" onClick={() => setShowDetail(true)}>
              Ver detalle
            </button>
          </div>
          <div className="row">
            <div className="col-md-5 border-lg-end">
              <div className="row align-items-center">
                <div className="col-md-6 text-center">
                  <h2 className="text-center">{total}</h2>
                  <span>Mensajes totales</span>
                </div>
                <div className="col-md-6">
                  <div style={{ width: '180px' }}>
                    <Doughnut data={dataChart} options={{ plugins: { legend: { display: false } } }} />
                  </div>

                </div>
              </div>
            </div>
            <div className="col-md-2 d-flex flex-column justify-content-center text-center border-lg-end">
              <h3 className="text-center">{messages.avg_per_chat}</h3>
              <span>Mensajes promedio por conversación</span>
            </div>
            <div className="col-md-2 d-flex flex-column justify-content-center text-center border-lg-end">
              <h3 className="text-center">{messages.avg_per_agent}</h3>
              <span>Mensajes promedio por agente</span>
            </div>
            <div className="col-md-3 d-flex flex-column justify-content-center text-center">
              <h3 className="text-center">{conversion_rate}%</h3>
              <div>
                <MessageIcon fontSize="small" />
                <span>&nbsp;Tasa de conversión <CustomTooltip title='Porcentaje de conversaciones abiertas a partir de envíos de plantilla.' ><i className="bi-info-circle-fill"></i></CustomTooltip></span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <MessageDetail show={showDetail} onRequestClose={() => setShowDetail(false)} />
    </>
  )
}

export default MessagesWidget