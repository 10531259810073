import { useEffect, useMemo, useRef, useState } from "react"
import Select from 'react-select'
import Audio from "../../../components/Audio";
import MessageParseService from "../../../services/messageParseService";
import { AssignmentService } from "../../../services/assignmentService";
import { ReportService } from "../../../services/reportService";
import moment from 'moment';
import EmptyState from "../../../components/EmptyState";

declare const bootstrap: any;

function MessageDetail({ show, onRequestClose }) {

    const domRef = useRef<HTMLInputElement>(null)
    const headerRef = useRef<HTMLInputElement>(null)
    const containerRef = useRef<HTMLInputElement>(null)
    const listChatContainerRef = useRef<HTMLInputElement>(null)
    const [offcanvas, setOffcanvas] = useState<any>()
    const [mainHeight, setMainHeight] = useState<any>(0)

    const [idSelected, setIdSelected] = useState<any>()
    const [asigments, setAsigments] = useState([])
    
    const [chats, setChats] = useState([])
    const [chatSelected, setChatSelected] = useState<any>()

    const [messages, setMessages] = useState([])

    const messageParse = MessageParseService.getInstance();

    const highlights = useMemo(() => {
        return {
            total: messages.length,
            inbound: messages.filter( m => m.type === 'INBOUND').length,
            outbound: messages.filter( m => m.type === 'OUTBOUND').length,
            templates:  messages.filter( m => m.type === 'OUTBOUND' && m.template).length,
            content_repository: messages.filter( m => m.type === 'OUTBOUND' && m.content_repository).length,
        }
    }, [messages])

    const hide = () => {
        offcanvas.hide()
    }

    const getAssigments = async() => {
        const response = await AssignmentService.getAssignmentsActive();

        setAsigments(response.map( a => ({ value: a._id, label: a.name })))
    }

    const getChatList = async() => {
        if(!idSelected) return setChats([]);

        const response = await ReportService.getMessagesByExternalID(idSelected.value);
        setChats(response)
        setMessages([])
        setChatSelected(null)
    }

    const getMessageList = async() => {
        if(!chatSelected) return setMessages([]);

        const response = await ReportService.getMessagesByChat(chatSelected._id);
        setMessages(response)
    }

    const scrollToBottom = () => {
        setTimeout( () => listChatContainerRef.current.scrollIntoView(), 100)
    }

    useEffect(() => {
        getMessageList()
    }, [chatSelected])

    useEffect(() => {
        getChatList()
    }, [idSelected])

    useEffect(() => {
        if (show) {
            offcanvas.show()
        }
    }, [show])

    useEffect(() => {
        if (domRef.current) {
            const offcanvasInstance = new bootstrap.Offcanvas(domRef.current, {
                // backdrop: false,
                keyboard: false,
                // scroll: true,
            })

            domRef.current.addEventListener('hidden.bs.offcanvas', function () {
                onRequestClose()
                setIdSelected(null);
            })

            setOffcanvas(offcanvasInstance);
        }

        getAssigments()
    }, [])

    useEffect(() => {
        const updateSize = () => {
            if(containerRef.current && headerRef.current) {
                setMainHeight(containerRef.current.clientHeight - headerRef.current.clientHeight)
            }
        }

        updateSize();

        window.addEventListener('resize', updateSize);

        return () => window.removeEventListener('resize', updateSize);
    }, [containerRef.current, headerRef.current])


    return (
        <div className="offcanvas offcanvas-end offcanvas-projecter" tabIndex={-1} id="offcanvasRight" aria-labelledby="offcanvasRightLabel" ref={domRef}>
            <div className="offcanvas-header border-botton">
                <h5 id="offcanvasRightLabel">Detalle de mensajes</h5>
                <button type="button" className="btn-close" onClick={hide}></button>
            </div>
            <div className={`offcanvas-body  ${!idSelected ? 'd-flex justify-content-center' : 'p-0'}`} style={{ overflow: 'hidden' }} ref={containerRef}>
                {
                    !idSelected ? (
                        <div className="w-50 text-center align-self-center">
                            <img src="/assets/img/messages-detail.png" />
                            <h5>Selecciona una Ruta o ID</h5>
                            <p>Visualiza la información detallada por Ruta o ID</p>

                            <div className="mt-3">
                                <label className="form-label" >Ruta o ID</label>
                                <Select
                                    placeholder='Seleccionar una ruta o id'
                                    options={asigments}
                                    className={false ? 'form-select-custom is-invalid' : ''}
                                    classNamePrefix='form-select-custom'
                                    onChange={(value: any) => setIdSelected(value)}
                                    value={idSelected}
                                />
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="row  g-0  py-5 border-botton" ref={headerRef}>
                                <div className="col-5">
                                    <div className="mx-3">
                                        <label className="form-label" >Agente</label>
                                        <Select
                                            placeholder='Seleccionar un agente'
                                            options={asigments}
                                            className={false ? 'form-select-custom is-invalid' : ''}
                                            classNamePrefix='form-select-custom'
                                            onChange={(value: any) => setIdSelected(value)}
                                            value={idSelected}
                                        />
                                    </div>
                                </div>
                                <div className="col-7">
                                    <div className="mx-3">
                                        {
                                            chatSelected && (
                                                <>
                                                    <h5 className="mb-4">Mensajes con {chatSelected?.contact?.first_name} {chatSelected?.contact?.last_name}</h5>

                                                    <div className="d-flex justify-content-between">
                                                        <div className="text-center ">
                                                            <h6>{highlights.total}</h6>
                                                            <p>Mensajes totales</p>
                                                        </div>
                                                        <div className="text-center ">
                                                            <h6>{highlights.outbound}</h6>
                                                            <p>Salientes</p>
                                                        </div>
                                                        <div className="text-center ">
                                                            <h6>{highlights.inbound}</h6>
                                                            <p>Entrantes</p>
                                                        </div>
                                                        <div className="text-center ">
                                                            <h6>{highlights.templates}</h6>
                                                            <p>Templates</p>
                                                        </div>
                                                        <div className="text-center ">
                                                            <h6>{highlights.content_repository}</h6>
                                                            <p>Materiales</p>
                                                        </div>
                                                        <div className="text-center ">
                                                            <h6>{highlights.outbound - highlights.templates}</h6>
                                                            <p>Libres</p>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                        
                                    </div>

                                </div>
                            </div>

                            <div className="row g-0" style={{ height: `${mainHeight}px`, overflow: 'hidden' }}>
                                <div className="col-5 h-100-scroll">

                                    <div className="messages-header" >
                                        <h5>Chats de la ruta o ID: {idSelected.label}</h5>
                                    </div>

                                    <div className="list-chat-container" >

                                        {
                                            chats.length === 0 && <div className="p-5"><EmptyState text='No se han generado chats para esta Ruta o ID.' /></div>
                                        }

                                        {
                                            chats.map( chat => (
                                                <div className={`chat-item d-flex ${chatSelected?._id === chat._id ? 'active':''}`} key={chat._id} onClick={ () => setChatSelected(chat)}>
                                                    <span className="avatar avatar-primary avatar-circle">
                                                        <img className="avatar-img" src={chat.contact.avatar} alt={`${chat.contact.first_name} ${chat.contact.last_name}`} />
                                                    </span>
                                                    <div className="px-2 flex-grow-1 d-flex">
                                                        <div className="flex-grow-1">
                                                            <h4 className="mb-0">{chat.contact.first_name} {chat.contact.last_name}</h4>
                                                            {/* <span className="badge bg-soft-primary text-primary ">Sesión activa</span> */}
                                                        </div>
                                                        <span className="date">{moment(chat.updated_at).format('DD/MM/YY HH:mm')}</span>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className="col-7 messages h-100-scroll" >

                                    <div className="messages-container" >
                                        {
                                            messages.length === 0 && <div className="p-5"><EmptyState title='' text='Selecciona un chat para ver los mensajes.' /></div>
                                        }

                                        {
                                            messages.map( (message, index) => {
                                                if(index === messages.length - 1) {
                                                    scrollToBottom()
                                                }

                                                return (
                                                    <div className={`message-item ${message.type === 'OUTBOUND' ? '': 'left'} ${message.format === 'STICKER' ? 'sticker':''}`} key={message._id}>
                                                        
                                                        {( message.format === 'TEXT' || message.format ===  'CONTENT_REPOSITORY' )&& <div dangerouslySetInnerHTML={{ __html: messageParse.parseTxt(message.message) }} /> }
                                                        { message.format === 'IMAGE' && (
                                                            <div>
                                                                <div className="d-flex message-multimedia">
                                                                    <div className="icon"><i className="bi bi-camera-fill"></i></div>
                                                                    <div className="flex-grow-1 ms-2">
                                                                        <h6>Imagen recibido</h6>
                                                                        <button className="btn-link">Ver imagen</button>
                                                                    </div>
                                                                </div>
                                                                {message.message && <div dangerouslySetInnerHTML={{ __html: messageParse.parseTxt(message.message) }} /> }
                                                            </div>
                                                        ) }
                                                        { message.format === 'VIDEO' && (
                                                            <div>
                                                                <div className="d-flex message-multimedia">
                                                                    <div className="icon"><i className="bi bi-camera-video-fill"></i></div>
                                                                    <div className="flex-grow-1 ms-2">
                                                                        <h6>Video recibido</h6>
                                                                        <button className="btn-link">Ver video</button>
                                                                    </div>
                                                                </div>
                                                                {message.message && <div dangerouslySetInnerHTML={{ __html: messageParse.parseTxt(message.message) }} /> }
                                                            </div>
                                                        ) }
                                                        { message.format === 'DOCUMENT' && (
                                                            <div>
                                                                <div className="d-flex message-multimedia">
                                                                    <div className="icon"><i className="bi bi-file-earmark-fill"></i></div>
                                                                    <div className="flex-grow-1 ms-2">
                                                                        <h6>{message.message}</h6>
                                                                        <button className="btn-link">Descargar archivo</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) }
                                                        { message.format === 'AUDIO' && (
                                                            <div>
                                                                <Audio />
                                                            </div>
                                                        ) }
                                                        { message.format === 'LOCATION' && (
                                                            <div>
                                                                <div className="">
                                                                    <iframe src={`https://maps.google.com/maps?q=${message.format_detail.latitude},${message.format_detail.longitude}&hl=es;z=10&output=embed`} width="100%" height="200" loading="lazy" allowFullScreen referrerPolicy="no-referrer-when-downgrade" title='Location'></iframe>
                                                                </div>
                                                            </div>
                                                        ) }
                                                        { message.format === 'STICKER' && (
                                                             <div className="message-multimedia sticker">
                                                                <img src={message.format_detail.url} alt='Sticker' />
                                                            </div>
                                                        ) }
                                                        
                                                        <p>{moment(message.created_at).format('DD/MM/YY HH:mm')}</p>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <div  ref={listChatContainerRef}></div>
                                </div>
                            </div>
                        </>
                    )
                }
            </div>
        </div>
    )
}
export default MessageDetail