import { useEffect, useId, useState } from "react"
import TextArea from "../../components/TextArea"
import MessateTestModal from "./MessateTestModal"
import { Controller } from "react-hook-form"
import Select from 'react-select'
import { CampaignService } from "../../services/campaignService"
import * as XLSX from 'xlsx';

function SMSPartial({ errors, register, getValues, setValue, watch, control, contentRepositories }) {

    // sms: {
    //     message: '',
    // }

    const fileId = useId()

    const file = watch('sms.file')

    const [showModal, setShowModal] = useState(false)
    const [headerColumns, setHeaderColumns] = useState([])

    const appendSystemVar = (variable: string) => {

        const message = getValues('sms.message')

        setValue('sms.message', `${message.trim()} {{${variable}}} `)
    }

    const formatTypeContact = (type) => {

        switch (type) {
            case 'DOCTOR':
                return 'Doctor'
            case 'PHARMACY':
                return 'Farmacia'
            case 'OTHER':
                return 'Otro'

            default:
                return type;
        }

    }

    const downloadContacts = async () => {
        const segmentations = getValues('segmentations')

        const response = await CampaignService.getContacts({ segmentations: segmentations.join(',') })

        const workbook = XLSX.utils.book_new();

        const header = [
            'contact.id',
            'contact.first_name',
            'contact.last_name',
            'contact.email',
            'contact.phone_number',
            'contact.type',
            'contact.medical_specialty',
            'contact.segments',
        ]

        const data = response.map(row => {
            return {
                'contact.id': row._id,
                'contact.first_name': row.first_name,
                'contact.last_name': row.last_name,
                'contact.email': row.email,
                'contact.phone_number': `${row.phones[0].phone['country_code']}${row.phones[0].phone['phone_number']}`,
                'contact.type': formatTypeContact(row.type),
                'contact.medical_specialty': row?.medical_specialty?.name || '',
                'contact.segments': row.segmentations ? row.segmentations.map( s => s.title).join(', '): [] ,
            }
        });

        const worksheet = XLSX.utils.json_to_sheet(data, { header })

        XLSX.utils.book_append_sheet(workbook, worksheet);

        XLSX.writeFile(workbook, `contacts.xlsx`);

    }

    useEffect(() => {

        if (file[0]) {

            const header = [
                'contact.id',
                'contact.first_name',
                'contact.last_name',
                'contact.email',
                'contact.phone_number',
                'contact.type',
                'contact.medical_specialty',
            ]

            const reader = new FileReader();

            reader.onload = (event: any) => {

                const workbook = XLSX.read(event.target.result, { type: 'binary' });
                const sheet_name_list = workbook.SheetNames;

                const xlData = XLSX.utils.sheet_to_json(workbook.Sheets[sheet_name_list[0]], { blankrows: false });

                const headerFile = Object.keys(xlData[0])
                
                let headerColumnsTMP = [];

                for (const row of headerFile) {
                    if(!header.includes(row)) {
                        headerColumnsTMP.push(row)
                    }
                }

                setHeaderColumns(headerColumnsTMP);


            };
            reader.onerror = function (ex) {
                console.log(ex);
            };
            reader.readAsBinaryString(file[0]);

        }


    }, [file])


    return (
        <>
            <div className='row mt-5'>
                <div className='col-3'>
                    <p className="text-dark fw-bold text-uppercase mb-3">mensaje</p>
                    <p>Personaliza el contenido del mensaje, puedes usar las variables para crear un mensaje personalizado.</p>
                </div>
                <div className='col-9'>
                    <div className="card">
                        <div className="card-body">
                            <div className="m-b-24">
                                <label className="form-label" >Variables</label>
                                <p>Usa las variables para redactar tu mensaje o agrega un .CSV con variables personalizadas. Da click en una variable para agregarla al mensaje.</p>

                                <div className="var-container mt-3">
                                    <div className="var-item" onClick={() => appendSystemVar('contact.first_name')}>contact.first_name</div>
                                    <div className="var-item" onClick={() => appendSystemVar('contact.last_name')}>contact.last_name</div>
                                    <div className="var-item" onClick={() => appendSystemVar('contact.email')}>contact.email</div>
                                    <div className="var-item" onClick={() => appendSystemVar('contact.phone_number')}>contact.phone_number</div>
                                    <div className="var-item" onClick={() => appendSystemVar('contact.type')}>contact.type</div>
                                    <div className="var-item" onClick={() => appendSystemVar('contact.medical_specialty')}>contact.medical_specialty</div>
                                </div>
                            </div>

                            <div className="m-b-24">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="d-flex align-items-center mb-3">
                                            <p className="text-dark fw-bold text-uppercase font-12 mb-1">variables desde archivo <span className="form-label-secondary">(Opcional)</span></p>
                                            {
                                                watch('segmentations').length > 0 && (
                                                    <div className="ms-auto">
                                                        <label className="btn btn-simple btn-sm " htmlFor={fileId} >Agregar archivo</label>
                                                        <input type="file" id={fileId} className="d-none" {...register('sms.file')} />
                                                    </div>
                                                )
                                            }
                                            
                                        </div>
                                        
                                        {
                                            watch('segmentations').length  ? (
                                                <p>Para usar variables personalizadas descarga <button className="btn btn-link-alternative fw-semibold p-0 m-0" onClick={downloadContacts}>aquí</button> la plantilla de los segmentos seleccionados.</p>
                                            ):
                                                <>
                                                    <div className="alert alert-blue">Añade uno o varios segmentos para permitir la descarga de la plantilla de contactos.</div>
                                                </>
                                        }

                                        <div className="var-container mt-3">
                                            {
                                                headerColumns.map( (header, index) => <div className="var-item" key={index}  onClick={() => appendSystemVar(header)}>{header}</div>)
                                            }
                                        </div>

                                        {
                                            file[0] && headerColumns.length === 0 && (
                                                <div className="alert alert-warning">
                                                    El archivo no contiene columnas nuevas
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="m-b-24">
                                <TextArea label='Mensaje *' errors={errors.sms?.message} {...register('sms.message', { required: true })} />
                            </div>

                            <div className="m-b-24">
                                <div className="card">
                                    <div className="card-body">

                                        <p className="text-dark fw-bold text-uppercase font-12 mb-1">Vincular material <span className="form-label-secondary">(Opcional)</span></p>
                                        <p className="m-b-24">Puedes vincular un material, aparecerá como un enlace al final en el SMS (ej.  https://shortty.link/cR1d)</p>

                                        {
                                            !watch('segmentations').length &&  (
                                                <div className="alert alert-blue">Para elegir un material, agrega uno o más segmentos para buscar los materiales asociados a dichos segmentos.</div>
                                            )
                                        }

                                        <div>
                                            <label className="form-label" >Material </label>
                                            <Controller
                                                control={control}
                                                name='content_repository'
                                                rules={{ required: false }}
                                                render={({
                                                    field: { onChange, onBlur, value },
                                                    fieldState: { error },
                                                }) => (
                                                    <Select
                                                        isClearable
                                                        placeholder='Seleccionar un material'
                                                        options={contentRepositories}
                                                        className={error ? 'form-select-custom is-invalid' : ''}
                                                        classNamePrefix='form-select-custom'
                                                        onBlur={onBlur}
                                                        onChange={(value: any) => onChange(value?.value || '')}
                                                        value={contentRepositories.filter(c => c.value === value)}
                                                    />
                                                )}
                                            />
                                            <span className="invalid-feedback">Campo requerido.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="m-b-24">
                                <button type="button" className="btn btn-outline-primary btn-sm me-2" onClick={() => setShowModal(true)} >Enviar mensaje de prueba</button>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

            <MessateTestModal
                message={getValues('sms.message')}
                open={showModal}
                onSave={() => setShowModal(false)}
            />
        </>
    )
}
export default SMSPartial