import { END_POINT } from "../constants";
import { HTTP } from "./http";

export const SettingsService = {

    setDefaultMessage: (message: string) => {
        return HTTP.put<any, any>(`${END_POINT}settings/change/default-message`, { message });
    },
    getDefaultMessage: () => {
        return HTTP.get<any, any>(`${END_POINT}settings/default-message`);
    },
}