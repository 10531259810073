

function Header({ children }: any) {
    return (
        <header className="navbar navbar-expand-lg navbar-fixed navbar-height navbar-container navbar-bordered bg-white">
            <div className="navbar-nav-wrap">
                <div className="w-100 d-flex align-items-center">
                    {children}
                </div>
            </div>
        </header>
    )
}

export default Header