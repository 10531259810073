import { useEffect, useMemo, useState } from 'react'
import Select from 'react-select'
import EmptyState from '../../../components/EmptyState'

function QuestionTableSurvey({ questions, data }) {

    const [selected, setSelected] = useState(null)
    
    const list = useMemo(() => {
        let answers = []

        if(selected) {
            
            for (const row of data) {
                if(row.answers) {
                    answers.push({
                        contact_detail: row.contact_detail,
                        answers:  row.answers.find( a => a.question === selected.value)
                    })
                }
            }
        }

        return answers;
    }, [selected])


    return (
        <>
            <div className="row">
                <div className="col-12 m-b-16">
                    <div className="d-flex align-items-end">
                        <div className="w-25">
                            <label className="form-label" >Pregunta</label>
                            <Select
                                placeholder='Seleccionar una pregunta'
                                options={questions}
                                className={false ? 'form-select-custom is-invalid' : ''}
                                classNamePrefix='form-select-custom'
                                onChange={(value: any) => setSelected(value)}
                                value={selected}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <table className="card-table table">
                <thead>
                    <tr>
                        <th scope="col">Contacto </th>
                        <th scope="col" className="text-center">Ruta o ID </th>
                        <th scope="col" className="text-center">Segmento(s)</th>
                        <th scope="col" className="text-center">Respuesta</th>
                    </tr>
                </thead>
                <tbody>
                    { list.length === 0 && <tr><td colSpan={4}><EmptyState text={'Selecciona una pregunta para mostrar información.'} className='m-t-1'/></td></tr> }
                    {
                        list.map( (row, index) => (
                            <tr key={index}>
                                <td>{row.contact_detail.contact.first_name} {row.contact_detail.contact.last_name}</td>
                                <td className="text-center">{row.contact_detail.assigned_id}</td>
                                <td className="text-center">{row.contact_detail.segmentations.map( s => s.title).join(', ')}</td>
                                <td  className="text-center">
                                    {
                                        !row.answers.my_answers ? (
                                            <span className="badge bg-soft-danger text-danger">Sin respuesta</span>
                                        ): (
                                            Array.isArray(row.answers.my_answers) ? row.answers.my_answers.join(', '): row.answers.my_answers 
                                        )
                                    }
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </>
    )
}
export default QuestionTableSurvey