import { useEffect, useRef } from "react"

declare const bootstrap: any;

function Tooltip({ children, title, className='' }) {

    const domRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
      if(domRef.current) {
        new bootstrap.Tooltip(domRef.current)
      }
    }, [domRef])
    

    return (
        <span data-bs-toggle="tooltip" title={title} ref={domRef} className={` ${className}`}>
            {children}
        </span>
    )
}
export default Tooltip