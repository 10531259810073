import SyntaxHighlighter from 'react-syntax-highlighter';

function SlidesPartial() {

    return (
        <div className="card">

            <div className="card-body">
                <h5 className="card-title">Slides</h5>
                <p className="card-text">Cada presentación de e-detailing está compuesta por slides. Para que la plataforma registre la actividad de las sesiones y obtenga información detallada sobre los slides visitados es importante preparar el archivo de acuerdo a las especificaciones.</p>
                
                <p  className="card-text mt-3">Además de registrar los slides visitados, se mide el tiempo de permanencia en ellos. La fecha de inicio comienza cuando se invoca la función y termina cuando se invoca el registro de un slide diferente o cuando se cierra la sesión de la visita.</p>
                
                <p  className="card-text mt-3">Para hacer uso de esta función solo se requiere el nombre del slide a registrar: </p>
                <div className='mt-3'>
                    <SyntaxHighlighter language="javascript" showInlineLineNumbers >
                        {
                            `
windows.ProjecterBridge.slide("Home")
windows.ProjecterBridge.slide("Gráfica resultados")

`
                        }
                    </SyntaxHighlighter>
                </div>
                
            </div>
        </div>
    )
}
export default SlidesPartial