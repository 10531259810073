import { useEffect, useMemo, useRef, useState } from "react"
import HandleNoData from "../../components/HandleNoData"
import EmptyState from "../../components/EmptyState"
import { TemplatesService } from "../../services/templatesService"
import Button from "../../components/Button";
import useRequest from "../../hooks/useRequest";

declare const bootstrap: any;

function TemplatesPartial() {


    const { request, isPending } =  useRequest();

    const modalRef = useRef<HTMLDivElement>(null)
    const [modal, setModal] = useState<any>(null)

    const [templates, setTemplates] = useState([])
    const [templateSelected, setTemplateSelected] = useState<any>(null)

    const header = useMemo(() => templateSelected?.components?.find( c => c.type === 'HEADER'), [templateSelected])
    const body = useMemo(() => templateSelected?.components?.find( c => c.type === 'BODY'), [templateSelected])
    const footer = useMemo(() => templateSelected?.components?.find( c => c.type === 'FOOTER'), [templateSelected])
    const buttons = useMemo(() => templateSelected?.components?.find( c => c.type === 'BUTTONS'), [templateSelected])

    const formatCategory = (category) => {
        switch (category) {
            case 'UTILITY':

                return 'Servicios'
            case 'MARKETING':

                return 'Marketing'
            case 'AUTHENTICATION':

                return 'Autenticación'

            default:
                return category
        }
    }

    const formatStatus = (status) => {
        switch (status) {
            case 'APPROVED':
                return <span className="badge bg-soft-success text-dark">Activa</span>
            // case 'IN_APPEAL':
            //     return <span className="badge bg-soft-dark text-dark">{status}</span>
            case 'PENDING':
                return <span className="badge bg-soft-warning text-dark">En revisión</span>
            case 'REJECTED':
                return <span className="badge bg-soft-danger text-dark">Rechazada</span>
            case 'PENDING_DELETION':
                return <span className="badge bg-soft-danger text-dark">Borrado pendiente</span>
            case 'DELETED':
                return <span className="badge bg-soft-dark text-dark">Borrado</span>
            case 'DISABLED':
                return <span className="badge bg-soft-warning text-dark">Inactivo</span>
            case 'PAUSED':
                return <span className="badge bg-soft-warning text-dark">Pausada</span>
            case 'LIMIT_EXCEEDED':
                return <span className="badge bg-soft-danger text-dark">Límite excedido</span>

            default:
                return <span className="badge bg-soft-warning text-dark">{status}</span>

        }
    }

    const getTemplates = async () => {
        const response = await TemplatesService.getAll();
        setTemplates(response)
    }

    const showModal = (data) => {
        setTemplateSelected(data)
        modal.show()
    }

    const updateTemplates = async() => {
        try {
            const x = await request(TemplatesService.update);
            getTemplates()
        } catch (error) {
            console.error(error)
            alert('No se actualizar las plantillas de WhatsApp')
        }
    }

    useEffect(() => {
        if (modalRef.current) {
            const modalInstance = new bootstrap.Modal(modalRef.current, {})
            setModal(modalInstance)
        }

    }, [modalRef])

    useEffect(() => {
        getTemplates();
    }, [])


    return (
        <>
            <div className='d-flex align-items-center mb-3'>
                <h5>Plantillas de WhatsApp</h5>
                <Button type="submit" className="btn btn-outline-primary ms-auto" isLoading={isPending} onClick={updateTemplates}>Actualizar</Button>
            </div>

            <table className="card-table table">
                <thead>
                    <tr>
                        <th scope="col">Nombre de la plantilla </th>
                        <th scope="col">Idioma </th>
                        <th scope="col">Estado </th>
                        <th scope="col"> </th>
                    </tr>
                </thead>
                <tbody>
                    <HandleNoData
                        condition={templates.length}
                        emptyComponent={<tr><td colSpan={4}><EmptyState /></td></tr>}
                    >
                        {
                            templates
                                .map((row: any) => (
                                    <tr key={row._id}>
                                        <td>
                                            <p className="text-dark fw-semibold">{row.name}</p>
                                            <p className="small">{formatCategory(row.category)}</p>
                                        </td>
                                        <td>{row.language}</td>
                                        <td>{formatStatus(row.status)}</td>
                                        <td>
                                            
                                            <button type="button" className="btn btn-outline-primary btn-outline-edit btn-icon m-l-8" onClick={() => showModal(row)}>
                                                <i className="bi bi-eye-fill"></i>
                                            </button>
                                        </td>
                                    </tr>
                                ))
                        }
                    </HandleNoData>
                </tbody>
            </table>


            <div className="modal fade" ref={modalRef} tabIndex={-1} data-bs-backdrop="static" data-bs-keyboard="false" >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" >Vista previa</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row ">
                                <div className="col">

                                    <div className="template-preview">
                                        {/* <h6 className="mb-3">share_content_opt_in (es_MX)</h6> */}
                                        <div className="template-preview-container mb-1">
                                            {header && <div className="template-preview-header mb-2"><p className="text-dark fw-semibold">{header.text}</p></div>}
                                            <div className="template-preview-body">
                                                <p>{body?.text}</p>
                                            </div>
                                            
                                            { footer  && <div className="template-preview-footer mt-2"> <p className="small">{footer.text}</p> </div> }
                                        </div>

                                        {
                                            buttons?.buttons?.map( (btn, i ) => (
                                                <div className="template-preview-btn" key={i}>
                                                    <p>{btn.text}</p>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="modal-footer">
                            <button type="button" className="btn btn-soft-primary btn-sm" data-bs-dismiss="modal">Cancelar</button>
                            <Button type="submit" className="btn btn-primary btn-sm" disabled={!isValid} isLoading={isSubmitting}>{watch('_id') ? 'Guardar cambios' : 'Guardar nueva ruta'}</Button>
                        </div> */}
                    </div>
                </div>
            </div>

        </>
    )
}
export default TemplatesPartial