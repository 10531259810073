import { END_POINT } from "../constants";
import { HTTP } from "./http";

export const ReportService = {

    getDashboardMessages: (start_date: string, end_date: string) => {
        return HTTP.get<any, any>(`${END_POINT}dashboard/messages`, { params: { start_date, end_date} });
    },
    getDashboardContent: (start_date: string, end_date: string) => {
        return HTTP.get<any, any>(`${END_POINT}dashboard/content-repository`, { params: { start_date, end_date} });
    },
    getDashboardHTMLS: (start_date: string, end_date: string) => {
        return HTTP.get<any, any>(`${END_POINT}dashboard/htmls`, { params: { start_date, end_date} });
    },
    
    // Mensajes
    getMessagesByExternalID: (external_id: string) => {
        return HTTP.get<any, any>(`${END_POINT}dashboard/messages/chats/${external_id}`);
    },
    getMessagesByChat: (chat_id: string) => {
        return HTTP.get<any, any>(`${END_POINT}dashboard/messages/conversations/${chat_id}`);
    },
    // Fin Mensajes
   
    // Materiales
    getMaterialsDetail: (start_date: string, end_date: string) => {
        return HTTP.get<any, any>(`${END_POINT}dashboard/content-repository/detail`, { params: { start_date, end_date} });
    },
    getMaterialDetail: (content_repository: string) => {
        return HTTP.get<any, any>(`${END_POINT}dashboard/content-repository/detail/${content_repository}`,);
    },
    // Fin Materiales
   
   
    // HTMLS
    getHTMLSByAgent: (agent_id: string, start_date: string, end_date: string) => {
        return HTTP.get<any, any>(`${END_POINT}dashboard/htmls/agent/${agent_id}`, { params: { start_date, end_date} });
    },
    getHTMLSByContact: (contact_id: string, start_date: string, end_date: string) => {
        return HTTP.get<any, any>(`${END_POINT}dashboard/htmls/contact/${contact_id}`, { params: { start_date, end_date} });
    },
    getHTMLSByContentRepository: (content_repository: string, start_date: string, end_date: string) => {
        return HTTP.get<any, any>(`${END_POINT}dashboard/htmls/content_repository/${content_repository}`, { params: { start_date, end_date} });
    },
    getSessionDetail: (session_id: string) => {
        return HTTP.get<any, any>(`${END_POINT}dashboard/htmls/content_repository/session-detail/${session_id}`);
    },
    // Fin HTMLS

    // Space
    getSpaceDau: (start_date: string, end_date: string) => {
        return HTTP.get<any, any>(`${END_POINT}dashboard/space/dau`, { params: { start_date, end_date} });
    },
    getSpaceContacts: () => {
        return HTTP.get<any, any>(`${END_POINT}dashboard/space/contacts`);
    },
    // Fin Space

}