import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import Input from "../../components/Input";
import { STORAGE } from "../../constants";
import { AuthService } from "../../services/authService";
import { LocalStorageService } from "../../services/localStoraService";

function LoginPage() {

    const navigate = useNavigate();

    const { register, handleSubmit, formState: { errors, isValid, isSubmitting, submitCount  } } = useForm({
        mode: 'onChange',
        defaultValues: {
            email: '',
            password: ''
        },
    });

    const goToMainPage = ({ user, token}) => {
        LocalStorageService.save(STORAGE.SESSION, user);
        LocalStorageService.save(STORAGE.TOKEN, token);

        if(user.role.key === 'reviewer' || user.role.key === 'agency') {
            navigate('/materials');
        } else {
            navigate('/');
        }
    }

    const submit = async (data: any) => {
        try {
            const response = await AuthService.login(data);

            if(response.two_step_verification) {
                navigate('/auth/two-factor/verify', { state: { token: response.token, email: data.email  } });
            } else {
                goToMainPage(response)
            }

        } catch (error) {
            console.error(error);
            // alert(error);
        }
    }


    useEffect(() => {
        document.body.className = '';

        return () => {
            document.body.className = 'has-navbar-vertical-aside navbar-vertical-aside-show-xl footer-offset';
        };
    }, [])


    return (
        <div className="d-flex align-items-center min-h-100">
            <main id="content" role="main" className="main bg-white pt-0">

                <div className="container-fluid px-0">

                    <div className="row mx-0">
                        <div className="col-lg-6 d-flex justify-content-center align-items-center min-vh-lg-100">
                            <div className="w-100 content-space-t-4 content-space-t-lg-2 content-space-b-1" style={{ maxWidth: '25rem' }} >

                                <form className="js-validate needs-validation" onSubmit={handleSubmit(submit)}>
                                    <div className="">
                                        <img className="m-b-40" src="/assets/img/logo.png" alt="Projecter" />

                                        <div className="m-b-16">
                                            <h1 className="display-5 m-b-8">Inicio de sesión</h1>
                                            <p>Ingresa a tu cuenta</p>

                                            { submitCount > 0 && <p className="text-danger fw-semibold m-t-16"><i className="bi bi-info-circle"></i> Correo electrónico o contraseña incorrectos.</p>}
                                            
                                        </div>
                                    </div>

                                    <div className="m-b-36">
                                        <Input label='Correo' errors={errors.email} {...register('email',{ required: true })} />
                                    </div>
                                    <div className="m-b-32">
                                        <Input label='CONTRASEÑA' type='password' errors={errors.password} {...register('password',{ required: true })} />
                                    </div>

                                    <div className="d-grid m-b-24">
                                        <Button type="submit" className="btn btn-primary btn-lg p-t-19 p-b-19" disabled={!isValid}  isLoading={isSubmitting}>Iniciar sesión</Button>
                                    </div>
                                    <div className="d-grid">
                                        <Link to='/auth/reset-password' className="btn btn-link text-info text-decoration-underline fw-semibold">
                                            ¿Olvidaste tu contraseña?
                                        </Link>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div
                            className="col-lg-6 d-none d-lg-flex justify-content-center align-items-center min-vh-lg-100 position-relative bg-light px-0"
                            style={{
                                backgroundImage: 'url(/assets/img/auth-bg.jpg)',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center'
                            }}
                        >
                        </div>

                    </div>
                </div>
            </main >
        </div >
    );
}

export default LoginPage;