import React, { useEffect, useMemo, useState } from 'react'
import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';
import moment from 'moment';
import 'moment/locale/es'
import CustomTooltip from '../../../components/Tooltip';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import ActivitySpaceDetail from './ActivitySpaceDetail';
import { ReportService } from '../../../services/reportService';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);


const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false
    },
    title: {
      display: false,
    },
  },
  maintainAspectRatio: false,
};

function ActivitySpaceWidget() {

  const [labels, setLabels] = useState([])
  const [dataset, setDataset] = useState([])
  const [showDetail, setShowDetail] = useState(false)

  const data = useMemo(() => {

    const canvas: any = document.getElementById('canvas');
    let gradient;
    if (canvas) {
      const ctx = canvas.getContext("2d")
      gradient = ctx.createLinearGradient(canvas.width / 2, 0, canvas.width / 2, canvas.height);
      gradient.addColorStop(0, 'rgba(249, 238, 249, 1)')
      gradient.addColorStop(1, 'rgba(249, 238, 249, 0.27)')
    }

    return {
      labels: labels.map(l => l.format),
      datasets: [
        {
          fill: true,
          label: 'DAU',
          data: labels.map(l => dataset.find(d => d._id === l.date)?.total || 0),
          borderColor: '#FF40B4',
          backgroundColor: gradient || 'rgba(249, 238, 249, 0.80)',
        },
      ]
    }
  }, [labels, dataset])


  const getDashboardHTMLS = async () => {
    try {
      const start = moment().format('YYYY-MM-DD');
      const end = moment().subtract(13, 'days').format('YYYY-MM-DD');
      const request = await ReportService.getSpaceDau(end, start)

      setDataset(request)
    } catch (error) {
      console.error(error)
    }
  }


  useEffect(() => {
    getDashboardHTMLS()

    let days = []

    for (let index = 13; index >= 0; index--) {
      days.push({
        format: moment().subtract(index, 'days').format('ddd DD'),
        date: moment().subtract(index, 'days').format('YYYY-MM-DD')
      })
    }

    setLabels(days)
  }, [])


  return (
    <>
      <div className="card h-100">
        <div className="card-body">
          <div className="d-flex flex-between-center m-b-20">
            <div className="d-flex align-items-center">
              <div className="icon-item icon-item-sm m-r-8 bg-soft-pink text-pink">
                <ViewAgendaIcon fontSize="inherit" />
              </div>
              <h6 className="m-b-0">Usuarios activos por día (DAU) <CustomTooltip className='ms-1 color-icon' title='Total de usuarios activos por día, durante los últimos 14 días.' ><i className="bi-info-circle-fill"></i></CustomTooltip></h6>
            </div>
            <button className="btn  btn-custom-link btn-sm" type="button" onClick={() => setShowDetail(true)} >
              Ver detalle del space
            </button>
          </div>

          <div className="row align-items-center">
            <div className="col-md-12">
              <Line id='canvas' options={options} data={data} height={250} />
            </div>
          </div>
        </div>
      </div>

      <ActivitySpaceDetail show={showDetail} onRequestClose={() => setShowDetail(false)} />
    </>
  )
}

export default ActivitySpaceWidget