
function Footer({ className = ''}) {
    return null
    // return (
    //     <div className={`footer bg-white ${className}`}>
    //         <div className="row justify-content-between align-items-center">
    //             <div className="col">
    //                 <p className="fs-6 mb-0">Projecter 2022</p>
    //             </div>
    //         </div>
    //     </div>
    // )
}

export default Footer