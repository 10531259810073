import { useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form";
import Alert from "../../components/Alert";
import Button from "../../components/Button";
import EmptyState from "../../components/EmptyState";
import HandleNoData from "../../components/HandleNoData";
import Input from "../../components/Input";
import { AssignmentService } from "../../services/assignmentService";

declare const bootstrap: any;

function RoutesPartial() {

    const modalRef = useRef<HTMLDivElement>(null)

    const [modal, setModal] = useState<any>(null)
    const [assignments, setAssignments] = useState([])

    const defaultValues = {
        _id: '',
        name: ''
    }
    const { register, handleSubmit, reset, setError, watch, formState: { errors, isValid, isSubmitting } } = useForm({
        mode: 'onChange',
        defaultValues
    });

    const getAssignments = async () => {
        const response = await AssignmentService.getAssignment();
        setAssignments(response);
    }

    const showModal = (data?: any) => {
        
        if (data) {
            reset(data)
        } else {
            reset(defaultValues);
        }

        modal.show()
    }

    const submit = async (data: any) => {
        try {
            if(data._id) {
                await AssignmentService.update(data);
            } else {
                delete data._id;
                await AssignmentService.save(data);
            }

            getAssignments();

            Alert.fire({
                title: 'Segmento guardado',
                text: `Se guardo correctamente el segmento`
            });

            modal.hide()
        } catch (error: any) {
            setError('name', { type: 'custom', message: error.message })
        }
    }

    useEffect(() => {
        if (modalRef.current) {
            const modalInstance = new bootstrap.Modal(modalRef.current, {})
            setModal(modalInstance)
        }

    }, [modalRef])

    useEffect(() => {
        getAssignments()
    }, [])


    return (
        <>
            <div className='d-flex align-items-center mb-3'>
                <h5>Rutas o IDs</h5>
                <button type="button" className="btn btn-outline-primary ms-auto" onClick={() => showModal()}>nueva ruta</button>
            </div>


            <HandleNoData
                condition={assignments.length}
                emptyComponent={<EmptyState />}
            >
                
                {
                    assignments.map((a: any) => (
                        <div className='card m-b-16' key={a._id}>
                            <div className='card-body'>
                                <div className='d-flex align-items-start'>
                                    <div>
                                        <h6>{a.name}</h6>
                                        {a.agent && <p>Asignado a: { a.agent.first_name } { a.agent.last_name } ({ a.agent.email })</p> }
                                        {!a.agent && <span className="badge bg-soft-danger text-danger">Sin asignar</span> }
                                    </div>
                                    <div className='ms-auto'>
                                        {/* <button type="button" className="btn btn-outline-primary btn-outline-trash  btn-icon ">
                                            <i className="bi bi-trash-fill"></i>
                                        </button> */}
                                        <button type="button" className="btn btn-outline-primary btn-outline-edit btn-icon m-l-8" onClick={() => showModal(a)}>
                                            <i className="bi bi-pencil-fill"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </HandleNoData>


            <div className="modal fade" ref={modalRef} tabIndex={-1} data-bs-backdrop="static" data-bs-keyboard="false" >
                <div className="modal-dialog" role="document">
                    <form onSubmit={handleSubmit(submit)}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" >{watch('_id') ? 'Actualizar': 'Nueva' } ruta</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="row ">
                                    <div className="m-b-24">
                                        <Input label='Nombre de ruta o ID *' errors={errors.name} {...register('name', { required: true })} />
                                    </div>
                                </div>


                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-soft-primary btn-sm" data-bs-dismiss="modal">Cancelar</button>
                                <Button type="submit" className="btn btn-primary btn-sm" disabled={!isValid} isLoading={isSubmitting}>{watch('_id') ? 'Guardar cambios': 'Guardar nueva ruta' }</Button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default RoutesPartial