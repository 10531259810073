import { useMemo, useState } from "react";
import AudioPlayer from "../../components/AudioPlayer";
import PreviewMediaContent from "../../components/PreviewMediaContent";
import FormModal from "./FormModal";
import PollModal from "./PollModal";
import moment from "moment";

function MaterialDetailPartial({ material }: any) {

    const [showModal, setShowModal] = useState(false)

    const [modalForm, setModalForm] = useState<any>({
        show: false,
        data: null
    })
    
    const [modalPoll, setModalPoll] = useState<any>({
        show: false,
        data: null
    })

    const materialName = useMemo(() => {
        switch (material.type) {
            case 'PDF':
                return 'PDF';
            case 'IMAGE':
                return 'Imagen';
            case 'VIDEO':
                return 'Video';
            case 'AUDIO':
                return 'Audio';
            case 'LINK':
                return 'Link';
            case 'FORM':
                return 'Encuesta';
            case 'POLL':
                return 'Votación';
            case 'HTML':
                return 'HTML';

            default:
                return material.type
        }
    }, [material])

    return (
        <>
            <div className="m-b-24">
                <label className="form-label" >NOMBRE </label>
                <input type="text" className="form-control-plaintext p-0" value={material.name} readOnly />
            </div>
            <div className="m-b-24">
                <label className="form-label" >DESCRIPCIÓN </label>
                <div className='form-control-plaintext p-0'>
                    {material.description}
                </div>
            </div>
            <div className="m-b-24">
                <label className="form-label" >SEGMENTACIÓN </label>
                <div>
                    {
                        material.segmentations.map(s => <span className="badge bg-soft-primary text-primary px-3 py-1 me-2" key={s._id}>{s.title}</span>)
                    }
                </div>
            </div>
            <div className="m-b-24">
                <label className="form-label" >VALIDADORES </label>
                <div>
                    {
                        material.validators.map(v => <span className="badge bg-soft-primary text-primary px-3 py-1 me-2" key={v.collaborator._id}>{v.collaborator.first_name} {v.collaborator.last_name}</span>)
                    }
                </div>
            </div>
            <div className="m-b-24">
                <label className="form-label" >FECHA DE EXPIRACIÓN </label>
                <div>
                    { moment(material.expiration_date).format('DD-MM-YYYY') }
                </div>
            </div>
            <div className="m-b-24">
                <label className="form-label" >POSTER </label>
                <div className="poster-preview" style={{ backgroundImage: `url(${material.poster})` }} onClick={() => setShowModal(true)}></div>
            </div>

            <div className="m-b-24">
                <label className="form-label" >TIPO DE MATERIAL </label>
                <input type="text" className="form-control-plaintext p-0" value={materialName} readOnly />
            </div>

            <div className="m-b-24">
                {
                    (
                        material.type === 'PDF' ||
                        material.type === 'IMAGE' ||
                        material.type === 'VIDEO' ||
                        material.type === 'HTML'
                    ) && <PreviewMediaContent material={material} title='Ver material' />
                }

                {
                    material.type === 'AUDIO' && <AudioPlayer material={material} />
                }
                {
                    material.type === 'LINK' && <a href={material.content.url} target='_blank' rel="noreferrer">{material.content.url} <i className="bi bi-box-arrow-up-right"></i></a>
                }
                {
                    material.type === 'FORM' &&
                        material.content.map((row, index) => (
                            <div className="m-b-8" key={index}>
                                <div className="card">
                                    <div className="card-body p-t-4 p-b-4">
                                        <div className={'d-flex align-items-center '}>
                                            <p className="text-dark fw-bold font-12 text-uppercase">{row.question}</p>
                                            <div className="ms-auto">
                                                <button type="button" className={'btn btn-link text-info text-decoration-underline fw-semibold '} onClick={() => setModalForm({ show: true, data: row })}>
                                                    Ver
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                }
                {
                    material.type === 'POLL' && (
                        <div className="m-b-24">
                            <div className="card">
                                <div className="card-body p-t-4 p-b-4">
                                    <div className={'d-flex align-items-center justify-content-center '}>
                                        <p className="text-dark fw-bold font-12 text-uppercase">{material.content.question}</p> 
                                        
                                        <button type="button" className={'btn btn-link text-info text-decoration-underline fw-semibold ms-auto' } onClick={() => setModalPoll({ show: true, data: material.content}) }>
                                            Ver
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>


            {
                showModal && (
                    <div className="preview">
                        <div className="preview-navbar">
                            <div className="preview-toolbar">
                                <div className="preview-toolbar-button preview-flex-centered" title="Close" onClick={() => setShowModal(false)}>
                                    <svg width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#fff">
                                        <path className="preview-svg-path" d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z"></path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className="preview-content">
                            <div className="preview-img">
                                <img src={material.poster} alt={material.name} />
                            </div>
                        </div>
                    </div >
                )
            }

            <FormModal 
                open={modalForm.show}
                onSave={() => setModalForm({ show: false, data: null}) }
                defaultData={modalForm.data}
                readOnly
            />

            <PollModal 
                open={modalPoll.show}
                onSave={() => setModalPoll({ show: false, data: null})}
                defaultData={modalPoll.data}
                readOnly
            />

        </>
    )
}
export default MaterialDetailPartial