import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import Input from '../../components/Input';
import { STORAGE } from '../../constants';
import { AuthService } from '../../services/authService';
import { LocalStorageService } from '../../services/localStoraService';

function TwoStepAuthPage() {

    const { state } = useLocation()
    const navigate = useNavigate();

    const secondsToResend = 30;

    const [seconds, setSeconds] = useState(secondsToResend)
    const [messageError, setMessageError] = useState('')

    const { register, setValue, getValues,  handleSubmit, formState: { errors, isValid, isSubmitting, submitCount  } } = useForm({
        mode: 'onChange',
        defaultValues: {
            code: '',
            token: ''
        },
    });

    const goToMainPage = ({ user, token}) => {
        LocalStorageService.save(STORAGE.SESSION, user);
        LocalStorageService.save(STORAGE.TOKEN, token);

        if(user.role.key === 'reviewer') {
            navigate('/materials');
        } else {
            navigate('/');
        }
    }
    
    const submit = async(data) => {
        try {
            const response = await AuthService.validate2AuthCode(data);
            goToMainPage(response)
        } catch (error) {
            console.error(error);
        }
    } 

    const resend = async() => {
        try {
            await AuthService.resend2AuthCode({ token: getValues('token')});
            setSeconds(secondsToResend)
        } catch (error) {
            console.error(error);
            setMessageError('El token de verificación ha caducado, por favor vuelve a iniciar sesión para generar un token nuevo')
        }
    }

    const handleSeconds = () => {
        setTimeout(() => setSeconds(seconds => seconds - 1), 1000)
    }
    
    useEffect(() => {
        if(seconds > 0) handleSeconds()
    }, [seconds])
    

    useEffect(() => {
        setValue('token', state.token)
    }, [state])
    
    useEffect(() => {
        document.body.className = '';

        return () => {
            document.body.className = 'has-navbar-vertical-aside navbar-vertical-aside-show-xl footer-offset';
        };
    }, [])


    return (
        <div className="d-flex align-items-center min-h-100">
            <main id="content" role="main" className="main bg-white pt-0">

                <div className="container-fluid px-0">

                    <div className="row mx-0">
                        <div className="col-lg-6 d-flex justify-content-center align-items-center min-vh-lg-100">
                            <div className="w-100 content-space-t-4 content-space-t-lg-2 content-space-b-1" style={{ maxWidth: '25rem' }} >

                                <form className="js-validate needs-validation" onSubmit={handleSubmit(submit)}>
                                    <div className="">
                                        <img className="m-b-40" src="/assets/img/logo.png" alt="Projecter" />

                                        <div className="m-b-16">
                                            <h1 className="display-5 m-b-8">Autenticación 2 pasos</h1>
                                            <p>Proteger tu información es muy importante para nosotros, confirma tu cuenta con el código de autorización enviado a tu correo <b>{state.email}</b></p>

                                            { submitCount > 0 && <p className="text-danger fw-semibold m-t-16"><i className="bi bi-info-circle"></i> El código proporcionado es inválido o ha caducado</p>}
                                            
                                            { messageError && <p className="text-danger fw-semibold m-t-16"><i className="bi bi-info-circle"></i> {messageError}</p>}

                                        </div>
                                    </div>

                                    <div className="m-b-32">
                                        <Input label='Código' type='number' errors={errors.code} {...register('code',{ required: true })} />
                                    </div>

                                    <div className="d-grid m-b-40">
                                        <Button type="submit" className="btn btn-primary btn-lg p-t-19 p-b-19" disabled={!isValid}  isLoading={isSubmitting}>VERIFICAR CÓDIGO</Button>
                                    </div>

                                    <div className="d-grid">
                                        <button type="button" className="btn btn-link text-info text-decoration-underline fw-semibold" disabled={seconds > 0 } onClick={resend}>Reenviar código {seconds > 0  && <>(en {seconds} segundos)</>}</button>
                                    </div>

                                </form>
                            </div>
                        </div>


                    </div>
                </div>
            </main >
        </div >
    );
}

export default TwoStepAuthPage