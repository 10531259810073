import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom"
import { ReactComponent as IconDashboard } from '../assets/icons/navbar/dashboard.svg';
import { ReactComponent as IconCollaborator } from '../assets/icons/navbar/collaborator.svg';
import { ReactComponent as IconContacts } from '../assets/icons/navbar/contacts.svg';
import { ReactComponent as IconMaterial } from '../assets/icons/navbar/materials.svg';
import { ReactComponent as IconSetting } from '../assets/icons/navbar/settings.svg';
import { ReactComponent as IconCampaign } from '../assets/icons/navbar/campaigns.svg';
import { ReactComponent as IconInbox } from '../assets/icons/navbar/inbox.svg';
import { LocalStorageService } from "../services/localStoraService";
import useSession from "../hooks/useSession";
import Allow from "../guards/Allow";

function Aside() {

    const location = useLocation();
    const navigate = useNavigate();

    const { session } = useSession();

    const logout = () => {
        LocalStorageService.clear();
        navigate('/auth/login', { replace: true });
    }
    
    return (
        <aside className="js-navbar-vertical-aside navbar navbar-vertical-aside navbar-vertical navbar-vertical-fixed navbar-expand-xl bg-white  ">
            <div className="navbar-vertical-container">
                <div className="navbar-vertical-footer-offset">

                    <Link to={'/'} className="navbar-brand">
                        <img className="navbar-brand-logo" src="/assets/img/logo.png" alt="Logo" data-hs-theme-appearance="default" />
                        <img className="navbar-brand-logo-mini" src="/assets/img/logo-sm.png" alt="Logo" data-hs-theme-appearance="default" />
                    </Link>

                    <button type="button" className="js-navbar-vertical-aside-toggle-invoker navbar-aside-toggler">
                        <i className="bi-arrow-bar-left navbar-toggler-short-align" data-bs-template='<div class="tooltip d-none d-md-block" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>' data-bs-toggle="tooltip" data-bs-placement="right" title="Collapse"></i>
                        <i className="bi-arrow-bar-right navbar-toggler-full-align" data-bs-template='<div class="tooltip d-none d-md-block" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>' data-bs-toggle="tooltip" data-bs-placement="right" title="Expand"></i>
                    </button>

                    <div className="navbar-vertical-content">
                        <div id="navbarVerticalMenu" className="nav nav-pills nav-vertical card-navbar-nav">

                            <Allow access={['admin']}>
                                <div className="nav-item m-b-16">
                                    <Link to={'/'} className={'nav-link mb-0 ' + (location.pathname === '/' ? 'active' : '')} data-placement="left">
                                        <i className="nav-icon"><IconDashboard /></i>
                                        <span className="nav-link-title">Dashboard</span>
                                    </Link>
                                </div>
                            </Allow>
                            <Allow access={['admin']}>
                                <div className="nav-item m-b-16">
                                    <Link to={'/inbox'} className={'nav-link mb-0 ' + (location.pathname.startsWith('/inbox') ? 'active': '')} data-placement="left">
                                        <i className="nav-icon"><IconInbox /></i>
                                        <span className="nav-link-title">Bandeja de entrada</span>
                                    </Link>
                                </div>
                            </Allow>
                            <span className="dropdown-header fw-bold">ADMINISTRAR</span>
                            <small className="bi-three-dots nav-subtitle-replacer"></small>

                            <Allow access={['admin']}>
                                <div className="nav-item m-b-16">
                                    <Link to={'/collaborators'} className={'nav-link mb-0 ' + (location.pathname.startsWith('/collaborators') ? 'active': '')} data-placement="left">
                                        <i className="nav-icon"><IconCollaborator  /></i>
                                        <span className="nav-link-title">Colaboradores</span>
                                    </Link>
                                </div>
                            </Allow>
                            
                            <Allow access={['admin']}>
                                <div className="nav-item m-b-16">
                                    <Link to={'/contacts'} className={'nav-link mb-0 ' + (location.pathname.startsWith('/contacts') ? 'active': '')}   data-placement="left">
                                        <i className="nav-icon"><IconContacts  /></i>
                                        <span className="nav-link-title">Contactos</span>
                                    </Link>
                                </div>
                            </Allow>                                                       
                            
                            <div className="nav-item m-b-16">
                                <Link to={'/materials'} className={'nav-link mb-0 ' + (location.pathname.startsWith('/materials') ? 'active': '')} data-placement="left">
                                    <i className="nav-icon"><IconMaterial  /></i>
                                    <span className="nav-link-title">Materiales</span>
                                </Link>
                            </div>

                            <Allow access={['admin']}>
                                <div className="nav-item m-b-16">
                                    <Link to={'/campaigns'} className={'nav-link mb-0 ' + (location.pathname.startsWith('/campaigns') ? 'active': '')}   data-placement="left">
                                        <i className="nav-icon"><IconCampaign  /></i>
                                        <span className="nav-link-title">Campañas</span>
                                    </Link>
                                </div>
                            </Allow>  

                            <Allow access={['agency']}>
                                <div className="nav-item m-b-16">
                                    <Link to={'/documentation'} className={'nav-link mb-0 ' + (location.pathname.startsWith('/documentation') ? 'active': '')}   data-placement="left">
                                        <i className="bi-file-code-fill nav-icon"></i>
                                        <span className="nav-link-title">Documentación</span>
                                    </Link>
                                </div>
                            </Allow>  

                            <Allow access={['admin']}>
                                <span className="dropdown-header fw-bold">PLATAFORMA</span>
                                <small className="bi-three-dots nav-subtitle-replacer"></small>
                                <div className="nav-item">
                                    <Link to={'/config'} className={'nav-link mb-0 ' + (location.pathname.startsWith('/config') ? 'active': '')} data-placement="left">
                                        <i className="nav-icon"><IconSetting  /></i>
                                        <span className="nav-link-title">Configuración</span>
                                    </Link>
                                </div>
                            </Allow>

                            
                        </div>

                    </div>

                    <div className="navbar-vertical-footer p-0">
                        <div className="d-flex flex-column">
                            <Link to={'/profile'}>
                                <div className="p-t-20 p-b-20 p-l-20 p-r-20 d-flex align-items-center cursor-pointer">
                                    <span className="avatar avatar-sm avatar-primary avatar-circle">
                                        <img className="avatar-img" src={session?.avatar} alt={`${session?.first_name} ${session?.last_name}`} />
                                    </span>
                                    <p className="m-l-8 fw-semibold fs-6 text-dark">{session?.first_name} {session?.last_name}</p>
                                    {/* <div className="ms-auto ">
                                        <i className="bi bi-chevron-right text-dark"></i>
                                    </div> */}
                                </div>
                            </Link>
                            <div className="p-t-20 p-b-20 p-l-20 p-r-20 fw- text-danger cursor-pointer border-top" onClick={logout}>
                                Cerrar sesión
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </aside>
    )
}

export default Aside