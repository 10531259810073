import { END_POINT } from "../constants";
import { HTTP } from "./http";

export const MaterialService = {

    getAll: () => {
        return HTTP.get<any, any>(`${END_POINT}content-repository` );
    },
    getAllForSpace: (segmentations) => {
        return HTTP.get<any, any>(`${END_POINT}content-repository/to-space`, { params: { segmentations } } );
    },
    getById: (_id: string) => {
        return HTTP.get<any, any>(`${END_POINT}content-repository/${_id}` );
    },
    save: (form: FormData, onUploadProgress) => {
        return HTTP.post<any, any>(`${END_POINT}content-repository`, form, { onUploadProgress:  progressEvent => onUploadProgress(progressEvent) }  );
    },
    update: (form: FormData) => {
        return HTTP.put<any, any>(`${END_POINT}content-repository`, form );
    },
    addComment: (data: any) => {
        return HTTP.post<any, any>(`${END_POINT}content-repository/comment`, data );
    },
    getComments: (content: string) => {
        return HTTP.get<any, any>(`${END_POINT}content-repository/comments/${content}` );
    },
    getForReports: () => {
        return HTTP.get<any, any>(`${END_POINT}content-repository/html` );
    },
    expire: (content: string) => {
        return HTTP.post<any, any>(`${END_POINT}content-repository/expire`, { content } );
    },
    release: (content: string) => {
        return HTTP.post<any, any>(`${END_POINT}content-repository/release`, { content } );
    },
    setStatusByReviewer: (data: any) => {
        return HTTP.post<any, any>(`${END_POINT}content-repository/reviewer/handle-status`, data );
    },
    getUrlSigned: (contentRepository: string) => {
        return HTTP.post<any, any>(`${END_POINT}content-repository/url-signed/${contentRepository}`, {} );
    },
    getURLHTML: (contentRepository: string) => {
        return HTTP.post<any, any>(`${END_POINT}content-repository/preview/${contentRepository}`, {} );
    },
    toggleVisibility: (content: string, status: boolean) => {
        return HTTP.post<any, any>(`${END_POINT}content-repository/toggle-visibility`, { content, status } );
    },
    requestChanges: (content: string) => {
        return HTTP.post<any, any>(`${END_POINT}content-repository/requests/changes`, { content } );
    },
    requestReview: (content: string) => {
        return HTTP.post<any, any>(`${END_POINT}content-repository/requests/review`, { content } );
    },
}