import { END_POINT } from "../constants";
import { HTTP } from "./http";

export const InboxService = {

    getInbox: () => {
        return HTTP.get<any, any>(`${END_POINT}inbox`);
    },
    getMessages: (chat_id: string) => {
        return HTTP.get<any, any>(`${END_POINT}inbox/messages/${chat_id}`);
    },
    sendMessage: (data: any) => {
        return HTTP.post<any, any>(`${END_POINT}inbox/send-message`, data);
    },

}