import { END_POINT } from "../constants";
import { HTTP } from "./http";

export const AssignmentService = {

    getAssignment: () => {
        return HTTP.get<any, any>(`${END_POINT}assignment`);
    },
    getAssignmentsActive: () => {
        return HTTP.get<any, any>(`${END_POINT}assignment/active`);
    },
    getAssignmentsAvailable: () => {
        return HTTP.get<any, any>(`${END_POINT}assignment/available`);
    },
    save: (data: any) => {
        return HTTP.post<any, any>(`${END_POINT}assignment`, data);
    },
    update: (data: any) => {
        return HTTP.put<any, any>(`${END_POINT}assignment`, data);
    },
}