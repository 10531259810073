import { useEffect, useRef, useState } from "react"
import { useFieldArray, useForm } from "react-hook-form"
import Button from "../../components/Button"
import Input from "../../components/Input"

declare const bootstrap: any;

function FormModal({ open = false, onSave, defaultData, readOnly = false }: any) {

    const modalRef = useRef<HTMLDivElement>(null)
    const [modal, setModal] = useState<any>(null)

    const defaultValues = {
        id: 0,
        question: '',
        is_mandatory: false,
        type: '',
        answers: [
            {
                value: ''
            }
        ]
    }
    const { control, register, watch, handleSubmit, reset,  formState: { errors, isValid, isSubmitting } } = useForm({
        mode: 'onChange',
        defaultValues
    })

    const type = watch('type')

    const { fields, append, remove } = useFieldArray({
        control,
        rules: { minLength: 1 },
        name: 'answers'
    });

    const submit = (data: any) => {
        if(data.type === 'INPUT' ) {
            delete data.answers
        } else {
            data.answers = data.answers.map(d => d.value);
        }
        
        onSave(data)
    }

    const handleResetForm = () => {
        reset({
            id: defaultData?.id || 0,
            question: defaultData?.question || '',
            is_mandatory: defaultData?.is_mandatory || false,
            type: defaultData?.type || '',
            answers: defaultData?.answers ? defaultData.answers.map( a => ({ value: a })) : [{ value: ''}]
        })
    }

    useEffect(() => {
        if (open) {
            modal.show()
        } else {
            if (modal) {
                modal.hide()
            }
        }

        handleResetForm()
    }, [open])

    useEffect(() => {
        if (modalRef.current) {
            const modalInstance = new bootstrap.Modal(modalRef.current, {})
            setModal(modalInstance)

            modalRef.current.addEventListener('hidden.bs.modal', _ => onSave(null) )
        }

    }, [modalRef])


    return (
        <div className="modal fade" ref={modalRef} tabIndex={-1} data-bs-backdrop="static" data-bs-keyboard="false" >
            <div className="modal-dialog" role="document">
                <form onSubmit={handleSubmit(submit)}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" >Configuración de pregunta</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="m-b-24">
                                    <Input label='Título de la pregunta *' errors={errors.question} {...register('question', { required: true })} disabled={readOnly} />
                                </div>
                                <div className="m-b-24">
                                    <div className="form-check form-switch ">
                                        <label className="form-check-label" htmlFor="is_mandatory">OBLIGATORIO</label>
                                        <input type="checkbox" className="form-check-input form-check-info" id="is_mandatory" {...register('is_mandatory')} disabled={readOnly}/>
                                    </div>
                                </div>
                                
                                <div className="m-b-24">
                                    <label className="form-label" >Tipo *</label>
                                    <select  className={'form-control form-control-lg ' + (errors.type ? 'is-invalid' : '')}  {...register('type', { required: true })} disabled={readOnly}>
                                        <option value={''}>Elige un tipo de pregunta</option>
                                        <option value='INPUT'>Respuesta corta</option>
                                        <option value='SELECT'>Desplegable</option>
                                        <option value='MULTISELECT'>Varias opciones</option>
                                    </select>
                                    <span className="invalid-feedback">Campo obligatorio.</span>
                                </div>

                                {
                                    (type === 'SELECT' || type === 'MULTISELECT') && (
                                        <>
                                        { 
                                            fields.map((item, index) => (
                                                    <div className="m-b-24" key={item.id}>
                                                        <Input label={`Respuesta ${index + 1} *`} errors={errors.answers?.[index]} {...register(`answers.${index}.value`, { required: true })} disabled={readOnly}>
                                                            {
                                                                !readOnly && index >= 1 && (
                                                                    <button type="button" className="btn btn-outline-danger btn-icon m-l-8" onClick={() => remove(index)}>
                                                                        <i className="bi bi-trash3"></i>
                                                                    </button>
                                                                )
                                                            }
                                                        </Input>
                                                    </div>
                                                ))
                                            }

                                            {
                                                !readOnly && (
                                                    <div className="m-b-24">
                                                        <button type="button" className="btn btn-link text-info text-decoration-underline fw-semibold px-0" onClick={() => append({ value: '' })}>
                                                            <i className="bi bi-plus"></i> agregar respuesta
                                                        </button>
                                                    </div>
                                                )
                                            }
                                            
                                        </>
                                    )
                                }

                            </div>


                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-soft-primary btn-sm" onClick={() => modal.hide()}>{readOnly ? 'Cerrar': 'Cancelar'}</button>
                            {!readOnly && <Button type="submit" className="btn btn-primary btn-sm" disabled={!isValid} isLoading={isSubmitting}>Guardar pregunta</Button>}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
export default FormModal