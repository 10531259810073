import { Outlet, useNavigate } from "react-router-dom"
import useSession from "../hooks/useSession"
import { useEffect } from "react"

interface RolesGuardProps {
    allow: Array<'admin'|'reviewer'|'agency'>
}

function RolesGuard({ allow }: RolesGuardProps) {

    const navigate = useNavigate();
    
    const { session } = useSession()

    useEffect(() => {
      const role = session.role.key;

      if(allow.length > 0 && allow.indexOf(role) === -1 ) {
        navigate('/404')
      }

    }, [session, allow])
    
  return (
    <>
        <Outlet />
    </>
  )
}
export default RolesGuard