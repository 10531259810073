import { useState } from "react";
import Button from "./Button"


function AlertDanger({ title, text, onConfirm, confirmText = 'continuar', cancelText = 'regresar' }: any) {

    const [isLoading, setIsLoading] = useState(false)

    const handleLoading = () => {
        setIsLoading(true)
        onConfirm()
    }

    return (
        <div id="projecter-alert-container-alert-danger" className="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" >
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-body text-center">
                        <img src='/assets/img/alert-danger.png' className='mx-auto' style={{ width: '336px' }} alt={title} />
                        <h3>{title}</h3>
                        <p>{text}</p>

                        <p className="text-danger fw-semibold m-t-16" style={{ display: 'none' }} id='projecter-alert-container-alert-danger-error-message'>
                            <i className="bi bi-info-circle"></i> <span className="message"></span>
                        </p>

                        <div className='text-center m-t-60'>
                            <button type="button" className="btn btn-primary btn-sm d-block mx-auto m-b-16" data-bs-dismiss="modal" >{cancelText}</button>
                            <Button type="button" className="btn btn-ghost-success  mx-auto" onClick={handleLoading} isLoading={isLoading} id='projecter-alert-container-alert-danger-btn'>{confirmText}</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AlertDanger