import { useEffect, useRef, useState } from "react"
import { ReportService } from "../../../services/reportService";
import moment from 'moment';
import 'moment/locale/es'
import Tooltip from "../../../components/Tooltip";

declare const bootstrap: any;

function ActivitySpaceDetail({ show, onRequestClose }) {

    const domRef = useRef<HTMLInputElement>(null)

    const [offcanvas, setOffcanvas] = useState<any>()

    const [contacts, setContacts] = useState([])


    const hide = () => {
        offcanvas.hide()
    }

    const getDetail = async() => {
        const response = await ReportService.getSpaceContacts();
        setContacts(response)
    }

    useEffect(() => {
        if (show) {
            offcanvas.show()
        }
    }, [show])

    useEffect(() => {
        if (domRef.current) {
            const offcanvasInstance = new bootstrap.Offcanvas(domRef.current, {
                // backdrop: false,
                keyboard: false,
                // scroll: true,
            })

            domRef.current.addEventListener('hidden.bs.offcanvas', function () {
                onRequestClose()
            })

            setOffcanvas(offcanvasInstance);
        }

        getDetail()
    }, [])


    return (
        <div className="offcanvas offcanvas-end offcanvas-projecter" tabIndex={-1} id="offcanvasRight" aria-labelledby="offcanvasRightLabel" ref={domRef}>
            <div className="offcanvas-header border-botton">
                <h5 id="offcanvasRightLabel">Detalle de Space</h5>
                <button type="button" className="btn-close" onClick={hide}></button>
            </div>
            <div className="offcanvas-body " >

                <div className="row">
                    <div className="col-12">
                        <div className="d-flex justify-content-center">
                            <div className="text-center py-5 ">
                                <h3>{contacts.length}</h3>
                                <p>Total contactos</p>
                            </div>
                            <div className='border-lg-end mx-5'></div>
                            <div className="text-center py-5 ">
                                <h3>{contacts.filter( c => c.has_invitation_space).length}</h3>
                                <p>Total contactos<br />con cuenta Space <Tooltip className='ms-1 color-icon' title='Total contactos con cuenta Space' ><i className="bi-info-circle-fill"></i></Tooltip></p>
                            </div>
                            
                        </div>
                    </div>

                    <div className="col-12">

                        <table className="card-table table mt-4">
                            <thead>
                                <tr>
                                    <th scope="col">Contacto</th>
                                    <th scope="col">Ruta o ID</th>
                                    <th scope="col">Segmento(s)</th>
                                    <th scope="col">Última sesión</th>
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    contacts.map( row => (
                                        <tr key={row._id}>
                                            <td>
                                                <p>{row.first_name} {row.last_name}</p>
                                                {
                                                    row.has_invitation_space && !row.last_session_space && (
                                                        <button className="btn  btn-custom-link btn-sm p-0">
                                                            Enviar recordatorio SMS
                                                        </button>
                                                    )
                                                }
                                                {
                                                    !row.has_invitation_space && (
                                                        <span className="badge badge-image">Sin invitación a Space</span>
                                                    )
                                                }
                                                
                                            </td>
                                            <td>{ row.segmentations.map( s => s.title).join(', ')}</td>
                                            <td>{ row.assigned_id?.name}</td>
                                            <td>{ row.last_session_space ? moment(row.last_session_space).format('DD MMMM YY, HH:mm')+' hrs': '-'}</td>
                                        </tr>
                                    ))
                                }
                                

                            </tbody>
                        </table>


                    </div>
                </div>
            </div>
        </div>
    )
}
export default ActivitySpaceDetail