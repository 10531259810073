import { useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import Input from "../../components/Input"
import Button from "../../components/Button"
import { CampaignService } from "../../services/campaignService";
import Alert from "../../components/Alert";

declare const bootstrap: any;

function MessateTestModal({ message, open = false, onSave }: any) {

    const dummyData = {
        '{{contact.first_name}}': 'Luis',
        '{{contact.last_name}}': 'Zamudio',
        '{{contact.email}}': 'lzamudio@domain.com',
        '{{contact.phone_number}}':  '+52 5544332211',
        '{{contact.type}}': 'Médico',
        '{{contact.medical_specialty}}': 'Medicina General',
    }
    const systemVars = [
        '{{contact.first_name}}',
        '{{contact.last_name}}',
        '{{contact.email}}',
        '{{contact.phone_number}}',
        '{{contact.type}}',
        '{{contact.medical_specialty}}',
    ]

    const modalRef = useRef<HTMLDivElement>(null)
    const [modal, setModal] = useState<any>(null)
    const [variables, setVariables] = useState<any>([])

    const defaultValues = {
        phone: '',
        message: '',        
    }
    const { control, register, watch, handleSubmit, reset, setValue, formState: { errors, isValid, isSubmitting } } = useForm({
        mode: 'onChange',
        defaultValues
    })

    const submit = async(data: any) => {

        try {
            await CampaignService.testSMS(data)

            Alert.fire({
                title: 'Mensaje enviado',
                text: `Se ha enviado el mensaje correctamente`
            });

            reset(defaultValues)
            modal.hide()
        } catch (error) {
            console.error(error)
            alert(error.message)
        }
    }


    useEffect(() => {
        if (open) {
            modal.show()
        } else {
            if (modal) {
                modal.hide()
            }
        }

    }, [open])

    useEffect(() => {
        if (modalRef.current) {
            const modalInstance = new bootstrap.Modal(modalRef.current, {})
            setModal(modalInstance)

            modalRef.current.addEventListener('hidden.bs.modal', _ => onSave(null) )
        }

    }, [modalRef])

    useEffect(() => {
       

        let vars = []
        let messageParsed = message;

        for (const systemVar of systemVars) {
            if(message.match(new RegExp(systemVar))) {
                vars.push({
                    var: systemVar,
                    value: dummyData[systemVar]
                })

                messageParsed = messageParsed.replace(new RegExp(systemVar,'g'), dummyData[systemVar])
            }
        }

        setVariables(vars)
        setValue('message', messageParsed)
        
    }, [message])

    return (
        <div className="modal fade" ref={modalRef} tabIndex={-1} data-bs-backdrop="static" data-bs-keyboard="false" >
            <div className="modal-dialog" role="document">
                <form onSubmit={handleSubmit(submit)}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" >Enviar mensaje de prueba</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="m-b-24">
                                    <Input label='Número de receptor *' errors={errors.phone} {...register('phone', { required: true, pattern: { value: /^(\+\d{1,3}[- ]?)?(\d{2,4}[- ]?)?\d{7,10}$/, message: 'Formato de número incorrecto' } })} />
                                    <p className="small">Agrega el prefijo de área (lada) al número de teléfono</p>
                                </div>
                                <div className="m-b-24">
                                    <label className="form-label" >Mensaje</label>
                                    <textarea  className={'form-control form-control-lg '} rows={2} disabled value={message} />
                                </div> 


                                <div className="m-b-16">
                                    <p className="text-dark fw-bold m-b-8 font-12 text-uppercase">Variables</p>
                                    <p className="font-12">Las variables de sistema se llenan con información dummy</p>
                                </div>
                                
                                {
                                    variables.map( (v,i) => (
                                        <div className="m-b-24" key={i}>
                                            <label className="form-label text-initial" >{v.var}</label>
                                            <input className={'form-control form-control-lg ' } value={v.value} disabled />
                                        </div>
                                    ))
                                }

                                <div className="m-b-24">
                                    <label className="form-label" >Mensaje final</label>
                                    <textarea  className={'form-control form-control-lg '} rows={2} disabled value={watch('message')} />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-soft-primary btn-sm" onClick={() => modal.hide()}>Cancelar</button>
                            <Button type="submit" className="btn btn-primary btn-sm" disabled={!isValid} isLoading={isSubmitting}>Enviar prueba</Button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
export default MessateTestModal