import { END_POINT } from "../constants";
import { HTTP } from "./http";

export const AuthService = {

    login: (data: any) => {
        return HTTP.post<any, any>(`${END_POINT}auth/login`, data);
    },
    forgotPassword: (data: any) => {
        return HTTP.post<any, any>(`${END_POINT}auth/forgot-password`, data);
    },
    validateToken: (data: any) => {
        return HTTP.post<any, any>(`${END_POINT}auth/forgot-password/validate`, data);
    },
    changePassword: (data: any) => {
        return HTTP.post<any, any>(`${END_POINT}auth/forgot-password/change`, data);
    },
    resend2AuthCode: (data: any) => {
        return HTTP.post<any, any>(`${END_POINT}auth/2-step-verification/resend`, data);
    },
    validate2AuthCode: (data: any) => {
        return HTTP.post<any, any>(`${END_POINT}auth/2-step-verification/validate`, data);
    },
}