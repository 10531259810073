import { useEffect, useMemo, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import Aside from "../../components/Aside"
import Footer from "../../components/Footer"
import Header from "../../components/Header"
import Input from "../../components/Input"
import StatusMaterial from "../../components/StatusMaterial"
import TextArea from "../../components/TextArea"
import { MaterialService } from "../../services/materialService"
import moment from 'moment';
import { Controller, useForm } from "react-hook-form"
import { CollaboratorService } from "../../services/collaboratorService"
import { SegmentService } from "../../services/segmentService"
import Select from 'react-select'
import DragAndDrop from "../../components/DragAndDrop"
import PollModal from "./PollModal"
import FormModal from "./FormModal"
import Alert from "../../components/Alert"
import Button from "../../components/Button"
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { LocalStorageService } from "../../services/localStoraService"
import { STORAGE } from "../../constants"
import ReviewerAccess from "../../components/ReviewerAccess"
import HandleNoData from "../../components/HandleNoData"
import AudioPlayer from "../../components/AudioPlayer"
import PreviewMediaContent from "../../components/PreviewMediaContent"
import MaterialDetailPartial from "./MaterialDetailPartial"
import Allow from "../../guards/Allow"

declare const flatpickr: any;

function MaterialDetailPage() {

    const datepickerRef = useRef()

    const [quill, setQuill] = useState('');

    const typeMaterial = [
        {
            value: 'PDF',
            label: 'PDF',
        },
        {
            value: 'IMAGE',
            label: 'Imagen',
        },
        {
            value: 'VIDEO',
            label: 'Video',
        },
        {
            value: 'AUDIO',
            label: 'Audio',
        },
        {
            value: 'LINK',
            label: 'Link',
        },
        {
            value: 'FORM',
            label: 'Encuesta',
        },
        {
            value: 'POLL',
            label: 'Votación',
        },
        {
            value: 'HTML_SPACE',
            label: 'HTML',
        },
        {
            value: 'HTML',
            label: 'CLM iPad',
        },
    ]

    const defaultValues = {
        _id: '',
        name: '',
        description: '',
        segmentations: [],
        validators: [],
        expiration_date: '',
        poster: '',
        type: '',
        
        file: '',
        link: '',
        poll: {
            question: '',
            answers: []
        },
        form: []
    }
    const { control, reset, register, watch, handleSubmit, setValue, getValues, formState: { errors, isValid, isSubmitting } } = useForm({
        mode: 'onChange',
        defaultValues
    })
    
    const typeMaterialSelected = watch('type');
    const pollData = watch('poll');
    const formData = watch('form');
    const file: any = watch('file');

    const [modalPoll, setModalPoll] = useState<any>({
        show: false,
        data: null
    })
    const [modalForm, setModalForm] = useState<any>({
        show: false,
        data: null
    })


    const navigate = useNavigate();

    let { id } = useParams();

    const [material, setMaterial] = useState<any>()
    const [reviewers, setReviewers] = useState([])
    const [segmentations, setSegmentations] = useState([])
    const [comments, setComments] = useState<Array<any>>([])
    const [session, setSession] = useState<any>(null)
    const [isSubmittingComment, setIsSubmittingComment] = useState<any>(false)

    const canPublish = useMemo(() => {
        if(!material) return false
        return material.validators.length && material.validators.filter( v => v.status === 'APPROVED' ).length === material.validators.length
    }, [material])

    const canSetStatusReviewer = useMemo(() => {
        if(!material) return false
        
        const status = material.validators.find( v => v.collaborator._id === session._id)
        return status && status.status === 'IN_REVIEW' && material.status === 'DRAFT' ;
    }, [material, session])


    const getMaterial = async() => {
        const response = await MaterialService.getById(id as string);
        console.log(response)
        setMaterial(response);

        reset({
            _id: response._id,
            name: response.name,
            description: response.description,
            segmentations: response.segmentations ? response.segmentations.map( (s: any) => s._id): [],
            validators: response.validators.map( (s: any) => s.collaborator._id),
            poster: response.poster,
            type: response.type,
            expiration_date: response.expiration_date ? moment(response.expiration_date).format('DD-MM-YYYY'): '',
            
            file: response.content.file_name || '',
            link: response.content.url || '',
            poll: {
                question: response?.content?.question || '',
                answers: response?.content?.answers || []
            },
            form: response.type === 'FORM' ?  response.content.map( (c, i) => ({
                id: i+1,
                question: c.question,
                is_mandatory: c.is_mandatory,
                type: c.type,
                answers: c.answers
            })) : []
        })
    }

    const getSegmentations = async() => {
        const response = await SegmentService.getActive()
        setSegmentations(response.map((a: any) => ({ value: a._id, label: a.title })))
    }

    const getReviewers = async() => {
        const response = await CollaboratorService.getReviewers();
        setReviewers(response.map((a: any) => ({ value: a._id, label: `${a.first_name} ${a.last_name}` })))
    }
    
    const getComments = async() => {
        const response = await MaterialService.getComments(id as string);
        setComments(response)
    }

    const savePollData = (data) => {
        if(data) {
            setValue('poll', data)
        }

        setModalPoll({
            show: false,
            data: null
        })
    }


    const saveFormData = (data) => {
        if(data) {
            let form: any = getValues('form');

            if(data.id) {
                form = form.map( i => i.id === data.id ? data: i)
            } else {
                form.push({ ...data, id: form.length + 1, })
            }

            setValue('form', form)
        }

        
        
        setModalForm({
            show: false,
            data: null
        })
    }

    const submit = async (data: any) => {
        try {
            const form = new FormData()

            form.append('_id', data._id)
            form.append('name', data.name)
            form.append('description', data.description)
            form.append('poster', data.poster)
            form.append('type', data.type)
            form.append('expiration_date', data.expiration_date)

            data.segmentations.forEach((s: any) => form.append('segmentations[]', s));
            data.validators.forEach((v: any) => form.append('validators[]', v));

            if (
                data.type === 'PDF' ||
                data.type === 'IMAGE' ||
                data.type === 'VIDEO' ||
                data.type === 'AUDIO' ||
                data.type === 'HTML'
            ) {
                form.append('file', data.file)
            }

            if (data.type === 'LINK') {
                form.append('link', data.link)
            }
            
            if (data.type === 'POLL') {
                form.append('poll[question]', data.poll.question)
                for (const answer of data.poll.answers) {
                    form.append('poll[answers][]', answer)    
                }
            }
            
            if (data.type === 'FORM') {
                for (let index = 0; index < data.form.length; index++) {
                    const item = data.form[index];
                    form.append(`form[${index}][question]`, item.question)   
                    form.append(`form[${index}][is_mandatory]`, item.is_mandatory)   
                    form.append(`form[${index}][type]`, item.type)   

                    if(item.type === 'SELECT' || item.type === 'MULTISELECT') {
                        for (const answer of item.answers) {  
                            form.append(`form[${index}][answers][]`, answer)
                        }
                    }
                }
            }

            await MaterialService.update(form);
            await getMaterial()
            await getComments()

            Alert.fire({
                title: 'Material actualizado',
                text: `El material se encuentra como borrador en revisión.`
            });

            

        } catch (error) {
            console.error(error);
        }
    }

    const addComment = async() => {
        setIsSubmittingComment(true)

        await MaterialService.addComment({
            content_repository: id,
            comment: quill
        });
        await getComments()

        setQuill('');
        setIsSubmittingComment(false)
    }

    const requestChanges = async() => {
        const isOk = await Alert.danger({
            title: 'Solicitar cambios', 
            text: '¿Estás seguro de querer solicitar cambios de este material? Al solicitar cambios en el material, las revisiónes de validadores tienen que volverse a solicitar para poder publicar el material',
            cancelText: 'Cancelar',
            confirmText: 'Solicitar cambios',
        });

        if(isOk) {
            try {
                await MaterialService.requestChanges(id as string);
                await getMaterial()
                await getComments()
                Alert.close();
            } catch (error: any ) {
                Alert.setMessageError(error.message)
            }
        }
    }
    const requestReview = async() => {
        const isOk = await Alert.danger({
            title: 'Solicitar revisión', 
            text: '¿Estás seguro de querer solicitar la revisión de este material?',
            cancelText: 'Cancelar',
            confirmText: 'Solicitar revisión',
        });

        if(isOk) {
            try {
                await MaterialService.requestReview(id as string);
                await getMaterial()
                await getComments()
                Alert.close();
            } catch (error: any ) {
                Alert.setMessageError(error.message)
            }
        }
    }

    const expireMaterial = async() => {
        const isOk = await Alert.danger({
            title: 'Expirar material', 
            text: 'Por favor confirma que deseas expirar este material. El material dejará de estar disponible para todos los usuarios.',
            cancelText: 'Cancelar',
            confirmText: 'Expirar',
        });

        if(isOk) {
            try {
                await MaterialService.expire(id as string);
                await getMaterial()
                await getComments()
                Alert.close();
            } catch (error: any ) {
                Alert.setMessageError(error.message)
            }
        }
    }

    const save = async() => {
        const isOk = await Alert.danger({
            title: 'Actualizar material', 
            text: 'Al confirmar la actualización de este material, los validadores serán notificados nuevamente para que revisen y validen esta nueva versión.',
            cancelText: 'Cancelar',
            confirmText: 'Continuar',
        });

        if(isOk) {
            try {
                await handleSubmit(submit)()
                Alert.close();
            } catch (error: any ) {
                Alert.setMessageError(error.message)
            }
        }
    }

    const release = async() => {
        const isOk = await Alert.danger({
            title: 'Publicar material', 
            text: 'Ya no podrás realizar cambios de ningún tipo, solamente activar o desactivar su disponibilidad.',
            cancelText: 'Cancelar',
            confirmText: 'Publicar material',
        });

        if(isOk) {
            try {
                await MaterialService.release(id as string);
                await getMaterial()
                Alert.close();
            } catch (error: any ) {
                Alert.setMessageError(error.message)
            }
        }
    }

    const setStatusByReviewer = async(status: boolean) => {
        const isOK = await Alert.danger({
            title: status ? 'Aprobar version' : 'Rechazar version', 
            text: status ? '¡Muchas gracias por tu revisión! Ten en cuenta que si algún otro validador involucrado rechaza esta versión, serás notificado para que lo revises nuevamente.' : 'Tus comentarios, así como los de los demás validadores que hayan sido asignados a este material, servirán para que el administrador gestione las correcciones necesarias. Cuando el material haya sido actualizado, serás notificado por correo para que lo revises nuevamente.',
            cancelText: 'Cancelar',
            confirmText: status ? 'Aprobar version' : 'Rechazar version',
        });

        if(isOK) {
            try {
                await MaterialService.setStatusByReviewer({
                    content: id,
                    status: status ? 'APPROVED':'REJECTED'
                });

                await getMaterial()
                Alert.close();
            } catch (error: any ) {
                Alert.setMessageError(error.message)
            }
        }
    }

    const deleteItemForm = async(index: number) => {
        const isOK = await Alert.danger({
            title:'Borrar pregunta', 
            text: '¿Estás seguro de querer esta pregunta?',
            cancelText: 'Cancelar',
            confirmText: 'Borrar',
        });

        if(isOK) {
            let form: any = getValues('form');

            setValue('form', form.filter( (f, i) => i !== index ))
            Alert.close();
        }
    }

    const toggleVisibility = async() => {
        const isOK = await Alert.danger({
            title: material.status === 'ACTIVE' ? 'Desactivar material': 'Activar material', 
            text: material.status === 'ACTIVE' ?  'Al desactivar este contenido, tanto agentes como sus contactos a los que se les haya compartido dejarán de visualizarlo.': 'Al activar este este contenido, estará nuevamente disponible para que los agentes lo compartan a sus contactos, así como visible para los contactos a los que previamente se les hubiera compartido.',
            cancelText: 'Cancelar',
            confirmText:  material.status === 'ACTIVE' ? 'Desactivar': 'Activar',
        });

        if(isOK) {
            await MaterialService.toggleVisibility(material._id, material.status === 'ACTIVE' ? false: true)
            await getMaterial()
            Alert.close();
        }
    }

    const isValidForm = useMemo(() => {  
        if(typeMaterialSelected === 'FORM' ) {
            return isValid && formData.length > 0
        }
        
        if(typeMaterialSelected === 'POLL' ) {
            return isValid && pollData.question
        }

        return isValid 
    }, [isValid, formData, pollData.question, typeMaterialSelected])
    
    const acceptExt = useMemo(() => {
        switch (typeMaterialSelected) {
            case 'PDF':
                return '.pdf'
            case 'IMAGE':
                return '.jpg,.jpeg,.png'
            case 'VIDEO':
                return '.mp4'
            case 'AUDIO':
                return '.mp3'
            case 'HTML':
                return '.zip'
        
            default:
                return '*'
        }
    }, [typeMaterialSelected])
    

    useEffect(() => {
        const getData = async() => {
            await getMaterial()
            getComments()
            getSegmentations()
            getReviewers()
        }

        getData()

        const ses = LocalStorageService.get(STORAGE.SESSION);
        setSession(ses);

        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if(datepickerRef.current) {
            flatpickr(datepickerRef.current, {
                // enableTime: true,
                dateFormat: 'd-m-Y',
                onChange: (selectedDates, dateStr) => {
                    console.log(dateStr)
                    setValue('expiration_date', dateStr)
                }
            });
        }
    }, [datepickerRef.current])
    
      


    return (
        <>
            <Header>
                <div>
                    <h4>Editar material</h4>
                </div>

                <Allow access={['admin', 'agency']}>
                    {
                        material?.status === 'DRAFT' && (
                            <div className="ms-auto d-flex align-items-center">
                                {material?.is_request_changes && 
                                    <Allow access={['agency']}>
                                        <div className="alert alert-soft-warning mb-0 me-2 py-2" role="alert">
                                            <i className="bi bi-exclamation-diamond-fill"></i> Se realizó una solicitud de cambios
                                        </div>
                                    </Allow>
                                }

                                {/* {!material?.is_request_changes && <Allow access={['admin']}><button type="button" className={`btn btn-outline-warning btn-sm me-2`} onClick={requestChanges} ><i className="bi bi-dash-circle"></i> solicitar cambios</button></Allow>}
                                {material?.is_request_changes && <Allow access={['admin']}><div className="alert alert-warning mb-0 py-2 me-2 fw-semibold" role="alert"><i className="bi bi-dash-circle"></i> Cambios solicitados</div></Allow>}
                                */}

                                {/* {!material?.is_request_review && <Allow access={['admin']}><button type="button" className={`btn btn-outline-success btn-sm me-2`} onClick={requestReview} ><i className="bi bi-check-circle"></i> solicitar revisión a validadores</button></Allow>}
                                {material?.is_request_review && <Allow access={['admin']}><div className="alert alert-success mb-0 py-2 me-2 fw-semibold" role="alert"><i className="bi bi-check-circle"></i> Se solicitó una revisión a validadores</div></Allow>} */}

                                <Allow access={['admin']}><button type="button" className="btn btn-outline-danger btn-sm me-2" onClick={expireMaterial} ><i className="bi bi-x-circle"></i> expirar</button></Allow>
                                <Button type="button" className="btn btn-primary btn-sm me-2" disabled={!isValidForm} isLoading={isSubmitting} onClick={save}>Actualizar</Button>
                                <Allow access={['admin']}><Button type="button" className="btn btn-primary btn-sm" disabled={!canPublish} onClick={release} >Publicar</Button></Allow>
                            </div>
                        )
                    }
                    
                    {
                        (material?.status === 'ACTIVE' || material?.status === 'INACTIVE')  && (
                            <div className="ms-auto">
                                <button type="button" className={'btn  btn-sm me-2 ' + (material?.status === 'ACTIVE' ? 'btn-outline-danger': 'btn-primary')} onClick={toggleVisibility} >{material?.status === 'ACTIVE' ? 'Desactivar': 'Activar'}</button>
                            </div>
                        )
                    }
                </Allow>

                <ReviewerAccess>
                    {
                        canSetStatusReviewer && (
                            <>
                                <div className="ms-auto">
                                    <Button className="btn btn-outline-danger btn-sm me-2" isLoading={isSubmitting} onClick={() => setStatusByReviewer(false)}>Rechazar</Button>
                                    <Button className="btn btn-primary btn-sm" onClick={() => setStatusByReviewer(true)} >Aprobar</Button>
                                </div>
                            </>
                        )
                    }
                    
                </ReviewerAccess>
                
                
            </Header>

            <Aside />

            <main role="main" className="main splitted-content-main">

                <div className="splitted-content-fluid content-space">
                    <div className="content container p-0">
                        <div className="row">
                            <div className="col-12 m-b-32">
                                <button type="button" className="btn btn-link text-dark text-decoration-underline px-0" onClick={() => navigate(`/materials`)} ><i className="bi bi-arrow-left"></i>  Materiales</button>
                            </div>

                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">

                                        { material && (session?.role?.key === 'reviewer' || material.status !== 'DRAFT') && <MaterialDetailPartial material={material} /> }

                                        {
                                            (session?.role?.key === 'admin' || session?.role?.key === 'agency') && material && material.status === 'DRAFT' && (
                                                <>
                                                    <div className="m-b-24">
                                                        <Input label='nombre *' errors={errors.name} {...register('name', { required: true, disabled: material?.status !== 'DRAFT' })} />
                                                    </div>
                                                    <div className="m-b-24">
                                                        <TextArea label='descripción *' errors={errors.description} {...register('description', { required: true, disabled: material?.status !== 'DRAFT' })}  />
                                                    </div>
                                                    <div className="m-b-24">
                                                        <label className="form-label" >Segmentación *</label>
                                                        <Controller
                                                            control={control}
                                                            name='segmentations'
                                                            rules={{ required: false }}
                                                            render={({
                                                                field: { onChange, onBlur, value },
                                                                fieldState: { error },
                                                            }) => (
                                                                <Select
                                                                    placeholder='Seleccionar segmentos'
                                                                    options={segmentations}
                                                                    isMulti
                                                                    className={error ? 'form-select-custom is-invalid' : ''}
                                                                    classNamePrefix='form-select-custom'
                                                                    onBlur={onBlur}
                                                                    onChange={(values: any) => onChange(values.map((v: any) => v.value))}
                                                                    value={segmentations.filter((s: any) => (value as Array<any>).indexOf(s.value) !== -1)}
                                                                    isDisabled={material?.status !== 'DRAFT' ||  session?.role?.key === 'agency'}
                                                                />
                                                            )}
                                                        />
                                                        <span className="invalid-feedback">Campo requerido.</span>
                                                    </div>
                                                
                                                    <div className="m-b-24">
                                                        <label className="form-label" >Validadores *</label>
                                                        <Controller
                                                            control={control}
                                                            name='validators'
                                                            rules={{ required: false }}
                                                            render={({
                                                                field: { onChange, onBlur, value },
                                                                fieldState: { error },
                                                            }) =>  {
                                                                return (
                                                                    <Select
                                                                        placeholder='Seleccionar validadores'
                                                                        options={reviewers}
                                                                        isMulti
                                                                        className={error ? 'form-select-custom is-invalid' : ''}
                                                                        classNamePrefix='form-select-custom'
                                                                        onBlur={onBlur}
                                                                        onChange={(values: any) => onChange(values.map((v: any) => v.value))}
                                                                        value={reviewers.filter((s: any) => (value as Array<any>).indexOf(s.value) !== -1)}
                                                                        isDisabled={material?.status !== 'DRAFT' ||  session?.role?.key === 'agency'}
                                                                    />
                                                                )
                                                            }}
                                                        />
                                                        <span className="invalid-feedback">Campo requerido.</span>
                                                    </div>

                                                    <div className="m-b-24">
                                                            <label className="form-label" >Fecha de expiración *</label>
                                                            <input type="text" className={'form-control form-control-lg ' + (errors.expiration_date ? 'is-invalid' : '')} value={getValues('expiration_date')} readOnly ref={datepickerRef} />
                                                        </div>

                                                    <div className="m-b-24">
                                                        <Controller
                                                            control={control}
                                                            name='poster'
                                                            rules={{ required: true }}
                                                            render={({
                                                                field: { onChange },
                                                                fieldState: { error },
                                                            }) => (
                                                                <DragAndDrop
                                                                    label='poster *'
                                                                    sublabel='IMAGEN DE poster'
                                                                    title='Arrastra una imagen aquí'
                                                                    subtitle='Tamaño recomendado 800x800px'
                                                                    className={error ? 'is-invalid' : ''}
                                                                    onChange={(file: any) => onChange(file)}
                                                                    thumbnail={getValues('poster')}
                                                                    accept='.png,.jpg,.jpeg'
                                                                />
                                                            )}
                                                        />
                                                    </div>

                                                    <div className="m-b-24">
                                                        <label className="form-label" >Tipo de material *</label>
                                                        <input type="text" className="form-control" value={typeMaterial.find((a: any) => a.value === getValues('type'))?.label || '' } disabled></input>
                                                    </div>

                                                    <HandleNoData condition={typeMaterialSelected === 'POLL'}>
                                                        <div className="m-b-24">
                                                            <div className="card">
                                                                <div className="card-body p-t-8 p-b-8">
                                                                    <div className={'d-flex align-items-center ' + (!pollData.question ? 'justify-content-center': '' )}>
                                                                        { pollData.question &&  <p className="text-dark fw-bold font-12 text-uppercase">{pollData.question}</p> }
                                                                        
                                                                        <button type="button" className={'btn btn-link text-info text-decoration-underline fw-semibold ' + (pollData.question ? 'ms-auto': '' )} onClick={() => setModalPoll({ show: true, data: pollData}) }>
                                                                            { material?.status === 'DRAFT'? 'Administrar': 'Ver' }
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </HandleNoData>
                                                    
                                                    <HandleNoData condition={typeMaterialSelected === 'FORM'}>
                                                            {
                                                                formData.map( (f: any, i) => (
                                                                    <div className="m-b-24" key={i}>
                                                                        <div className="card">
                                                                            <div className="card-body p-t-8 p-b-8">
                                                                                <div className={'d-flex align-items-center ' }>
                                                                                    <p className="text-dark fw-bold font-12 text-uppercase">{f.question}</p>
                                                                                    <div className="ms-auto">
                                                                                        <button type="button" className={'btn btn-link text-info text-decoration-underline fw-semibold ' } onClick={() => setModalForm({ show: true, data: f }) }>
                                                                                        { material?.status === 'DRAFT'? 'Administrar': 'Ver' }
                                                                                        </button>

                                                                                        <button type="button" className="btn btn-outline-danger btn-sm me-2 " onClick={() => deleteItemForm(i)} ><i className="bi bi-trash-fill"></i></button>
                                                                                    </div>
                                                                                    
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }
                                                            <button type="button" className={'btn btn-link text-info text-decoration-underline fw-semibold px-0' } onClick={() =>  setModalForm({ show: true, data: null }) }>
                                                                <i className="bi bi-plus"></i> Agregar pregunta
                                                            </button>
                                                    </HandleNoData>
                                                    
                                                    <HandleNoData condition={typeMaterialSelected === 'LINK'} >
                                                        <div className="m-b-24">
                                                            <Input label='enlace *' errors={errors.link} {...register('link', { required: typeMaterialSelected === 'LINK' })} />
                                                        </div>
                                                    </HandleNoData>

                                                    <HandleNoData 
                                                        condition={
                                                            typeMaterialSelected === 'PDF' ||
                                                            typeMaterialSelected === 'IMAGE' ||
                                                            typeMaterialSelected === 'VIDEO' ||
                                                            typeMaterialSelected === 'AUDIO' ||
                                                            typeMaterialSelected === 'HTML'  ||
                                                            typeMaterialSelected === 'HTML_SPACE'
                                                        }
                                                    >
                                                        <div className="m-b-24">
                                                                <Controller
                                                                    control={control}
                                                                    name='file'
                                                                    // rules={{ required: true }}
                                                                    render={({
                                                                        field: { onChange },
                                                                        fieldState: { error },
                                                                    }) => {
                                                                        return (
                                                                            <DragAndDrop
                                                                                label='Archivo *'
                                                                                sublabel='Archivo de material'
                                                                                title='Si deseas cambiar el archivo actual, arrastra el archivo aquí'
                                                                                subtitle=''
                                                                                className={error ? 'is-invalid' : ''}
                                                                                onChange={(file: any) => onChange(file)}
                                                                                // thumbnail={typeMaterialSelected === 'IMAGE' ? getValues('file'): ''}
                                                                                accept={acceptExt}
                                                                            />
                                                                        )
                                                                    }}
                                                                />
                                                            </div>
                                                    </HandleNoData>
                                                    
                                                    <HandleNoData condition={typeMaterialSelected === 'AUDIO'}>
                                                        <div className="m-b-24">
                                                            <AudioPlayer material={material} />
                                                        </div>
                                                    </HandleNoData>

                                                    <HandleNoData condition={
                                                        (typeMaterialSelected === 'PDF' ||
                                                        typeMaterialSelected === 'HTML' ||
                                                        typeMaterialSelected === 'HTML_SPACE' ||
                                                        typeMaterialSelected === 'IMAGE' ||
                                                        typeMaterialSelected === 'VIDEO' ) &&
                                                        !(file instanceof File)
                                                    }>
                                                        <div className="m-b-24">
                                                            <PreviewMediaContent material={material} title={typeMaterialSelected === 'HTML' ? 'Ver material': 'Archivo de material'} />
                                                        </div>
                                                    </HandleNoData>
                                                </>
                                            )
                                        }                                        

                                    </div>
                                </div>

                                <div className="divider-center-full m-b-24 m-t-42"></div>

                                <div>
                                    <p className="fw-semibold text-dark font-12 m-b-24">COMENTARIOS</p>
                                    {
                                         material?.status === 'DRAFT' && ( 
                                            <div className="d-flex ">
                                                <div className="avatar avatar-circle flex-shrink-0">
                                                    <img className="avatar-img" src={session?.avatar} alt={`${session?.first_name} ${session?.last_name}`} />
                                                </div>
                                                <div className="m-l-16 m-r-16 flex-grow-1 ">
                                                    <div className="quill-custom">
                                                        <ReactQuill 
                                                            theme="snow" 
                                                            modules={{
                                                                toolbar:  [
                                                                    ['bold', 'italic', 'underline', 'strike'], 
                                                                    ['blockquote'],
                                                                    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                                                                    [{ 'script': 'sub'}, { 'script': 'super' }],
                                                                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                                                    [{ 'color': [] }, { 'background': [] }],
                                                                ]
                                                            }}
                                                            value={quill} 
                                                            onChange={setQuill}
                                                            placeholder='Escribe para agregar un comentario'
                                                            className="js-quill bg-white"
                                                        />
                                                    </div>

                                                    <Button type="submit" className="btn btn-primary btn-sm mt-3" isLoading={isSubmittingComment}  disabled={!quill || quill === '<p><br></p>'} onClick={addComment}>Guardar</Button>
                                                </div>
                                            </div>
                                         )
                                    }

                                    
                                    {
                                        comments.map( c => (
                                            <div key={c._id}>
                                                <div className="divider-center-full m-b-32 m-t-32"></div>
                                                <div className="d-flex ">
                                                    <div className="avatar avatar-circle flex-shrink-0">
                                                        <img className="avatar-img" src={c.validator.avatar} alt={`${c.validator.first_name} ${c.validator.last_name}`} />
                                                    </div>
                                                    <div className="m-l-16 m-r-16 flex-grow-1 ">
                                                        <div className="d-flex mb-2">
                                                            <p className="text-dark fw-semibold ">{c.validator.first_name} {c.validator.last_name}</p>
                                                            <p className="ms-auto">{moment(c.commented_at).format('DD/MM/YY HH:mm')}</p>
                                                        </div>
                                                        
                                                        <div className="bg-white comments-material p-3" dangerouslySetInnerHTML={{ __html: c.comment }}></div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        ))
                                    }

                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="offcanvas offcanvas-start splitted-content-small splitted-content-bordered d-flex flex-column">
                    <div className="offcanvas-body">
                        <div className="">
                            <h4 className="m-b-40">Detalle</h4>
                            <div>
                                <p className="fw-semibold text-dark font-10 m-b-16">ESTATUS</p>
                                <StatusMaterial  status={material?.status} validators={material?.validators} />
                            </div>
                            <div className="divider-center-full m-b-24 m-t-24"></div>

                            <div>
                                <p className="fw-semibold text-dark font-10 m-b-16">VALIDADORES</p>

                                <div className="mb-3">
                                    {!material?.is_request_review && <Allow access={['admin']}><button type="button" className={`btn btn-outline-success btn-sm me-2`} onClick={requestReview} ><i className="bi bi-check-circle"></i> solicitar revisión a validadores</button></Allow>}
                                    {material?.is_request_review && <Allow access={['admin']}><div className="alert alert-success mb-0 py-2 me-2 fw-semibold" role="alert"><i className="bi bi-check-circle"></i> Se solicitó una revisión a validadores</div></Allow>}
                                </div>

                                {
                                    (!material?.validators || material?.validators.length === 0) && (
                                        <div className="alert alert-soft-primary" role="alert">
                                            Aún no hay validadores de este material
                                        </div>
                                    )
                                }

                                {
                                    material?.validators.map((v: any) => (
                                        <div className="d-flex align-items-center m-b-16"  key={v.collaborator._id}>
                                            <div className="avatar avatar-circle ">
                                                <img className="avatar-img" src={v.collaborator.avatar} alt={`${v.collaborator.first_name} ${v.collaborator.last_name}`} />
                                                { v.status === 'IN_REVIEW' && <span className="avatar-status avatar-sm-status avatar-status-info"></span> }
                                                { v.status === 'REJECTED' && <span className="avatar-status avatar-sm-status avatar-status-danger"></span> }
                                                { v.status === 'APPROVED' && <span className="avatar-status avatar-sm-status avatar-status-success"></span> }
                                            </div>
                                            <div className=" m-l-16">
                                                <p className="text-dark fw-semibold">{`${v.collaborator.first_name} ${v.collaborator.last_name}`}</p>
                                                <p className="font-12">{`${v.collaborator.email}`}</p>
                                            </div>
                                        </div>
                                    ))
                                }

                            </div>
                            <div className="divider-center-full m-b-24 m-t-24"></div>

                            <div>
                                <p className="fw-semibold text-dark font-10 m-b-16">CREACIÓN</p>
                                <p className="text-dark">{moment(material?.created_at).format('DD/MM/YY')}</p>
                            </div>
                            <div className="divider-center-full m-b-24 m-t-24"></div>

                            <div>
                                <p className="fw-semibold text-dark font-10 m-b-16">CREADOR</p>
                                <div className="d-flex align-items-center  m-b-16">
                                    <div className="avatar avatar-circle ">
                                        <img className="avatar-img" src={material?.who_published.avatar} alt={`${material?.who_published.first_name} ${material?.who_published.last_name}`} />
                                    </div>
                                    
                                    <div className=" m-l-16">
                                        <p className="text-dark fw-semibold">{`${material?.who_published.first_name} ${material?.who_published.last_name}`}</p>
                                        <p className="font-12">{`${material?.who_published.email}`}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="divider-center-full m-b-24 m-t-24"></div>

                            <div>
                                <p className="fw-semibold text-dark font-10 m-b-16">HISTORIAL DE ACTUALIZACIONES</p>
                                {
                                    material?.history && !material.history.length  && (
                                        <div className="alert alert-soft-primary" role="alert">
                                            No existen actualizaciones previas de este material
                                        </div>
                                    )
                                }
                                {
                                    material?.history && material.history.map( (h, i) => (
                                        <div className="d-flex align-items-center m-b-16" key={i}>
                                            <div className="icon-version"><div className="icon-version-container"></div></div>
                                            <p className="text-dark fw-semibold m-l-8">{moment(h.versioned_at).format('DD/MM/YY HH:mm')}</p>
                                            {/* <p className="ms-auto">{moment(h.versioned_at).format('DD/MM/YY HH:mm')}</p> */}
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </main>


            <PollModal 
                open={modalPoll.show}
                onSave={savePollData}
                defaultData={modalPoll.data}
            />

            <FormModal 
                open={modalForm.show}
                onSave={saveFormData}
                defaultData={modalForm.data}
            />


        </>
    )
}

export default MaterialDetailPage