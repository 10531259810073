import { END_POINT } from "../constants";
import { HTTP } from "./http";

export const ContactService = {

    getContacts: () => {
        return HTTP.get<any, any>(`${END_POINT}contacts`);
    },
    save: (data: any) => {
        return HTTP.post<any, any>(`${END_POINT}contacts`, data);
    },
    update: (data: any) => {
        return HTTP.put<any, any>(`${END_POINT}contacts`, data);
    },
    getMedicalSpecialties: () => {
        return HTTP.get<any, any>(`${END_POINT}medical-specialties`);
    },
}