import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Alert from '../../components/Alert';
import Button from '../../components/Button';
import Input from '../../components/Input';
import { AuthService } from "../../services/authService";

function ChangePasswordPage() {

    const navigate = useNavigate();
    let { token } = useParams();


    const { register, reset, handleSubmit, setValue, watch, formState: { errors, isValid, isSubmitting } } = useForm({
        mode: 'onChange',
        defaultValues: {
            token: '',
            password: '',
            confirmPassword: '',
        },
    });

    register('token', { required: true })

    const password = watch('password')
    const confirmPassword = watch('confirmPassword')

    const [typeUser, setTypeUser] = useState<any>(null)
    const [rulesPassword, setRulesPassword] = useState({
        minLength: false,
        hasNumber: false,
        hasUpperLetter: false,
        hasSpecialLetter: false,
    })

    const [canLogIn, setCanLogIn] = useState(false)
    const [isNewUser, setIsNewUser] = useState(false)

    const isValidForm = useCallback(() => {
        return isValid &&
            rulesPassword.minLength &&
            rulesPassword.hasNumber &&
            rulesPassword.hasUpperLetter &&
            rulesPassword.hasSpecialLetter &&
            password === confirmPassword;

    }, [isValid, rulesPassword, password, confirmPassword])

    const submit = async (data: any) => {
        try {
            const response = await AuthService.changePassword(data);
        
            Alert.fire({
                title: 'Contraseña establecida exitosamente',
                text: `Ahora puedes iniciar sesión con tu nueva contraseña`
            });

            reset()
            setTypeUser(response)

            setCanLogIn(true);
        } catch (error) {
            console.error(error)
            alert(error);
        }
    }


    useEffect(() => {
        const minLength = () => password.length >= 8;
        const hasNumber = () => (new RegExp('[0-9]')).test(password);
        const hasUpperLetter = () => (new RegExp('[A-Z]')).test(password);
        const hasSpecialLetter = () => (new RegExp('[$|%|&|#|!]')).test(password);

        setRulesPassword({
            minLength: minLength(),
            hasNumber: hasNumber(),
            hasUpperLetter: hasUpperLetter(),
            hasSpecialLetter: hasSpecialLetter(),
        })

    }, [password])


    useEffect(() => {
        const validateToken = async () => {
            try {
                const response = await AuthService.validateToken({ token })
                setIsNewUser(response.collaborator.is_new)
                setValue('token', `${token}`)
            } catch (error: any) {
                console.error(error.message)
                navigate('/404')
            }
        }

        validateToken()
    }, [token])

    useEffect(() => {
        document.body.className = '';

        return () => {
            document.body.className = 'has-navbar-vertical-aside navbar-vertical-aside-show-xl footer-offset';
        };
    }, [])


    return (
        <div className="d-flex align-items-center min-h-100">
            <main id="content" role="main" className="main bg-white pt-0">

                <div className="container-fluid px-0">

                    <div className="row mx-0">
                        <div className="col-lg-6 d-flex justify-content-center align-items-center min-vh-lg-100">
                            <div className="w-100 content-space-t-4 content-space-t-lg-2 content-space-b-1" style={{ maxWidth: '25rem' }} >

                                <form className="js-validate needs-validation" onSubmit={handleSubmit(submit)}>
                                    <div className="">
                                        <img className="m-b-40" src="/assets/img/logo.png" alt="Projecter" />

                                        <div className="m-b-16">
                                            <h1 className="display-5 m-b-8">
                                                {canLogIn ? 'Contraseña creada exitosamente.': `${isNewUser ? 'Establecer': 'Reestablecer'} contraseña` }
                                            </h1>
                                            {
                                                typeUser?.rol?.key === 'agent' && <p>Ahora puedes ingresar a tu app con tu nueva contraseña</p>
                                            }
                                            {
                                                (!typeUser || typeUser?.rol?.key !== 'agent') && <p>Ingresa tu nueva contraseña.</p>
                                            }
                                            
                                        </div>
                                    </div>

                                    {
                                        canLogIn && (
                                            <>
                                            {
                                                typeUser?.rol?.key === 'agent' && (
                                                    <div className="d-flex justify-content-evenly m-b-40">
                                                        <a href='https://www.google.com/' target='_blank' rel="noreferrer"><img src='/assets/img/appstore.png'  alt='App Store'/></a>
                                                        <a href='https://www.google.com/' target='_blank' rel="noreferrer"><img src='/assets/img/playstore.png' alt='Play Store' /></a>
                                                    </div>
                                                )
                                            }
                                            {
                                                typeUser?.rol?.key !== 'agent' && (
                                                    <div className="d-grid m-b-40">
                                                        <Link to='/auth/login' className="btn btn-primary btn-lg p-t-19 p-b-19">
                                                            Iniciar sesión
                                                        </Link>
                                                    </div>
                                                )
                                            }
                                            </>
                                        )
                                    }

                                    {
                                        !canLogIn && (
                                            <>
                                                <div className='card m-b-32'>
                                                    <div className='card-body'>
                                                        <p className='text-dark fw-semibold'>Contraseña segura</p>
                                                        <p className='m-b-16'>Debe incliuir almenos:</p>

                                                        <p className={'m-b-8 ' + (rulesPassword.minLength ? 'text-success' : 'text-danger')}>{rulesPassword.minLength ? <i className="bi bi-check-circle-fill"></i> : <i className="bi bi-x-circle-fill"></i>} Mínimo 8 caracteres</p>
                                                        <p className={'m-b-8 ' + (rulesPassword.hasNumber ? 'text-success' : 'text-danger')}>{rulesPassword.hasNumber ? <i className="bi bi-check-circle-fill"></i> : <i className="bi bi-x-circle-fill"></i>} 1 número</p>
                                                        <p className={'m-b-8 ' + (rulesPassword.hasUpperLetter ? 'text-success' : 'text-danger')}>{rulesPassword.hasUpperLetter ? <i className="bi bi-check-circle-fill"></i> : <i className="bi bi-x-circle-fill"></i>} 1 letra mayúscula</p>
                                                        <p className={'m-b-8 ' + (rulesPassword.hasSpecialLetter ? 'text-success' : 'text-danger')}>{rulesPassword.hasSpecialLetter ? <i className="bi bi-check-circle-fill"></i> : <i className="bi bi-x-circle-fill"></i>} 1 caracter especial ($,%,&,#,!)</p>
                                                        <p className={(password && password === confirmPassword ? 'text-success' : 'text-danger')}>{password && password === confirmPassword ? <i className="bi bi-check-circle-fill"></i> : <i className="bi bi-x-circle-fill"></i>} Nueva contraseña y repetir contraseña deben coincidir</p>

                                                    </div>
                                                </div>

                                                <div className="m-b-36">
                                                    <Input label='Nueva contraseña' type='password' errors={errors.password} {...register('password', { required: true })} />

                                                </div>
                                                <div className="m-b-32">
                                                    <Input label='Repetir contraseña' type='password' errors={errors.confirmPassword} {...register('confirmPassword', { required: true })} />
                                                </div>



                                                <div className="d-grid m-b-40">
                                                    <Button type="submit" className="btn btn-primary btn-lg p-t-19 p-b-19" disabled={!isValidForm()} isLoading={isSubmitting}>{isNewUser ? 'establecer':'restablecer'} contraseña</Button>
                                                </div>
                                            </>
                                        )
                                    }

                                </form>
                            </div>
                        </div>


                        <div
                            className="col-lg-6 d-none d-lg-flex justify-content-center align-items-center min-vh-lg-100 position-relative bg-light px-0"
                            style={{
                                backgroundImage: 'url(/assets/img/auth-alt-bg.jpg)',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center'
                            }}
                        >
                        </div>


                    </div>
                </div>
            </main >
        </div >
    )
}

export default ChangePasswordPage