import { useEffect, useMemo, useState } from "react"
import Aside from "../../../components/Aside"
import Footer from "../../../components/Footer"
import Header from "../../../components/Header"
import { useLocation, useNavigate } from "react-router-dom"
import { ReportService } from '../../../services/reportService'
import QuestionTableSurvey from './QuestionTableSurvey'
import ContactTableSurvey from './ContactTableSurvey'
import Tooltip from '../../../components/Tooltip'
import * as XLSX from 'xlsx';

function MaterialsDetailSurvey() {

    const [filter, setFilter] = useState<'QUESTION' | 'CONTACT'>('QUESTION')
    const navigate = useNavigate();

    const { state: { material } } = useLocation()

    const [list, setList] = useState([])

    const questions = useMemo(() => {
        return material.content_repository.content.map(c => ({ value: c.question, label: `${c.question} ${c.is_mandatory ? '*':''}` }))
    }, [material])

    const contacts = useMemo(() => {
        return list.map(c => ({ value: c.contact_detail.contact._id, label: `${c.contact_detail.contact.first_name} ${c.contact_detail.contact.last_name} (${c.contact_detail.assigned_id})` }))
    }, [list])

    const formatType = (type) => {
        switch (type) {
            case 'PDF':
                return <span className="badge badge-pdf">PDF</span>
            case 'IMAGE':
                return <span className="badge badge-image">Imagen</span>
            case 'VIDEO':
                return <span className="badge badge-video">Video</span>
            case 'AUDIO':
                return <span className="badge badge-audio">Audio</span>
            case 'LINK':
                return <span className="badge badge-link">Link</span>
            case 'FORM':
                return <span className="badge badge-form">Encuesta</span>
            case 'POLL':
                return <span className="badge badge-poll">Votación</span>

            default:
                break;
        }
    }

    const getDetail = async () => {
        const response = await ReportService.getMaterialDetail(material.content_repository._id)
        setList(response)
    }


    const dowload = () => {
        const workbook = XLSX.utils.book_new();

        let header = [
            'CONTACTO',
            'RUTA O ID',
            'SEGMENTO(S)',
        ]

        const qs = questions.map( q => q.value )

        header = header.concat(qs)
    
        const data = list.map( row => {

            const answers = row.answers.reduce( (acc, a) => {
                return Object.assign(acc, {[a.question]: Array.isArray(a.my_answers) ? a.my_answers.join(', '): a.my_answers  })
            }, {})

            return {
                'CONTACTO': row.contact_detail.contact.first_name +' ' +row.contact_detail.contact.last_name ,
                'RUTA O ID': row.contact_detail.assigned_id ,
                'SEGMENTO(S)': row.contact_detail.segmentations.map( s => s.title).join(', ') ,
                ...answers
            }
        });
        
        const worksheet = XLSX.utils.json_to_sheet(data, { header })

        XLSX.utils.book_append_sheet(workbook, worksheet);

        XLSX.writeFile(workbook, `survey.xlsx`);
    }

    useEffect(() => {
        getDetail()
    }, [material])

    return (
        <>
            <Header>
                <div>
                    <h4>{material.content_repository.name}</h4>
                    <p>{formatType(material.content_repository.type)}</p>
                </div>

                <div className="ms-auto">
                    <button type="button" className="btn btn-primary btn-sm nowrap" onClick={dowload}><i className="bi-download"></i> Descargar</button>
                </div>
            </Header>

            <Aside />

            <main role="main" className="main">

                <div className="bg-white border-bottom">
                    <div className="container p-t-12 p-b-12 px-0 px-5">
                        <ul className="nav nav-pills ">
                            <li className="nav-item font-12 m-0 me-2">
                                <button className={'nav-link fw-bold ' + (filter === 'QUESTION' ? 'active' : 'text-reset')} onClick={() => setFilter('QUESTION')}>
                                    POR PREGUNTA
                                </button>
                            </li>
                            <li className="nav-item font-12 m-0 me-2"  >
                                <button className={'nav-link fw-bold ' + (filter === 'CONTACT' ? 'active' : 'text-reset')} onClick={() => setFilter('CONTACT')}>
                                    POR CONTACTO
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>



                <div className="content container px-5">
                    <div className="row">
                        <div className="col-12 m-b-32">
                            <button type="button" className="btn btn-link text-dark text-decoration-underline px-0" onClick={() => navigate(-1)} ><i className="bi bi-arrow-left me-2"></i>  Detalle de materiales</button>
                        </div>

                        <div className='col-12 m-b-24'>
                            <div className="card ">
                                <div className="card-body d-flex">
                                    <div className='mx-4 text-center flex-grow-1 '>
                                        <h3 className="text-center">{material.total_sent}</h3>
                                        <p className='nowrap'>Total envios <Tooltip className='ms-1 color-icon' title='Total envios' ><i className="bi-info-circle-fill"></i></Tooltip></p>
                                    </div>
                                    <div className='border-lg-end'></div>
                                    <div className='mx-4 text-center flex-grow-1 '>
                                        <h3 className="text-center">{material.unique_sent}</h3>
                                        <p className='nowrap'>Envios únicos <Tooltip className='ms-1 color-icon' title='Envios únicos' ><i className="bi-info-circle-fill"></i></Tooltip></p>
                                    </div>
                                    <div className='border-lg-end'></div>
                                    <div className='mx-4 text-center flex-grow-1 '>
                                        <h3 className="text-center">{material.open_link}</h3>
                                        <p className='nowrap'>Clics <Tooltip className='ms-1 color-icon' title='Clics' ><i className="bi-info-circle-fill"></i></Tooltip></p>
                                    </div>
                                    <div className='border-lg-end'></div>
                                    <div className='mx-4 text-center flex-grow-1 '>
                                        <h3 className="text-center">{material.open_content}</h3>
                                        <p className='nowrap'>Aperturas <Tooltip className='ms-1 color-icon' title='Aperturas' ><i className="bi-info-circle-fill"></i></Tooltip></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className={filter === 'QUESTION' ? '' : 'd-none'}><QuestionTableSurvey questions={questions} data={list} /></div>
                    <div className={filter === 'CONTACT' ? '' : 'd-none'}> <ContactTableSurvey contacts={contacts} data={list} /></div>
                </div>

                <Footer />
            </main>
        </>
    )
}
export default MaterialsDetailSurvey