import { useMemo } from "react"

interface StatusMaterialProps {
    status: 'DRAFT'|'EXPIRED'|'ACTIVE'|'INACTIVE',
    validators?: Array<any>
}

function StatusMaterial({ status, validators = [] }: StatusMaterialProps) {

  const isReadyToPublish = useMemo(() => {
    if(!validators.length ) return false;

    return validators.every( v => v.status === 'APPROVED' )
  }, [validators])
  
  return (
    <>
        {status === 'DRAFT' && !isReadyToPublish && <span className="badge bg-soft-info text-info">EN VALIDACIÓN</span> }
        {status === 'DRAFT' && isReadyToPublish && <span className="badge bg-soft-success text-success">LISTO PARA PUBLICAR</span> }
        {status === 'EXPIRED' && <span className="badge bg-soft-danger text-danger">EXPIRADO</span> }
        {status === 'ACTIVE' && <span className="badge bg-soft-success text-info">ACTIVO</span> }
        {status === 'INACTIVE' && <span className="badge bg-soft-warning text-info">INACTIVO</span> }
    </>

  )
}

export default StatusMaterial