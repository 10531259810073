let _END_POINT_ = 'http://localhost:8080/';
let _END_POINT_WS_ = 'localhost:9090';
let env: any  = process.env.REACT_APP_STAGE;

switch (env) {
    case 'dev':
        _END_POINT_ = 'https://dev-api.projecter.media/';
        _END_POINT_WS_ = 'dev-api.projecter.media';
        break;
    case 'beta':
        _END_POINT_ = 'https://beta-api.projecter.media/';
        _END_POINT_WS_ = 'beta-api.projecter.media';
        break;
    case 'prod':
        _END_POINT_ = 'https://bridge-api.projecter.media/';
        _END_POINT_WS_ = 'bridge-api.projecter.media';
        break;

    default:
        break;
}

export const END_POINT = _END_POINT_;
export const END_POINT_WS = _END_POINT_WS_;


export const STORAGE = {
    TOKEN: 'projecter-token',
    SESSION: 'projecter-session',
}