export interface TemplateHeader {
    type:   string;
    format: string;
    text:   string;
}

export interface TemplateBody {
    type:    string;
    text:    string;
}

export interface TemplateFooter {
    type: string;
    text: string;
}

export interface TemplateButtons {
    type:    string;
    buttons: TemplateButton[];
}

export interface TemplateButton {
    type: string;
    text: string;
}

export const TemplateParse = (components: Array<TemplateHeader | TemplateBody | TemplateFooter | TemplateButtons>) => {
    const header = components.find( c => c.type === 'HEADER') as TemplateHeader
    const body = components.find( c => c.type === 'BODY') as TemplateBody
    const footer = components.find( c => c.type === 'FOOTER') as TemplateFooter
    const buttons = components.find( c => c.type === 'BUTTONS') as TemplateButtons

    const expresion = /{{[0-9]}}/g
    const tokens = body.text.match(expresion) || [];
    

    return {
        header,
        body,
        bodyTokens: tokens,
        footer,
        buttons,
    }

}

export const EscapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}
