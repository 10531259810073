import React, { useMemo } from 'react'
import WebIcon from '@mui/icons-material/Web';
import Tooltip from '../../../components/Tooltip';
import { useNavigate } from 'react-router-dom';
import { round } from '../../../utils/number';
import { clone } from '../../../utils/clone';

function HTMLWidget({ content, range }) {

    const navigate = useNavigate();

    const avg = useMemo(() => {

        if(content.total_avg < 60) {
            return `${round(content.total_avg)}seg`
        }
        
        let min = content.total_avg/60

        return `${round(min)}min`

    }, [content.total_avg])
    
    return (
        <div className="card h-100">
            <div className="card-body">
                <div className="d-flex flex-between-center m-b-5">
                    <div className="d-flex align-items-center">
                        <div className="icon-item icon-item-sm m-r-8 bg-soft-purple text-purple">
                            <WebIcon fontSize="inherit" />
                        </div>
                        <h6 className="m-b-0">E-detailing</h6>
                    </div>
                    <button className="btn  btn-custom-link btn-sm" type="button"  onClick={() => navigate('/dashboard/htmls', { state: { prevHighlights: clone(content), range: clone(range) } })}>
                        Ver detalle <i className="bi-box-arrow-up-right ms-2"></i>
                    </button>
                </div>

                <div className='d-flex my-5'>
                    <div className='mx-4 '>
                        <h1 className="text-center">{content.total_sessiones}</h1>
                        <p className='nowrap'>Total sesiones <Tooltip className='ms-1 color-icon' title='Total sesiones' ><i className="bi-info-circle-fill"></i></Tooltip></p>
                    </div>
                    <div className='border-lg-end'></div>
                    <div className='flex-grow-1 mx-4'>

                        <div className='d-flex align-items-center'>
                            <h4>{content.total_unique_sessions}</h4>
                            <p className='ms-3 nowrap'>Contactos únicos <Tooltip className='ms-1 color-icon' title='Contactos únicos que tuvieron al menos una sesión en el periodo seleccionado.' ><i className="bi-info-circle-fill"></i></Tooltip></p>
                        </div>
                        <div className='d-flex align-items-center'>
                            <h4>{content.total_per_day}</h4>
                            <p className='ms-3 nowrap'>Promedio de sesiones x agente <Tooltip className='ms-1 color-icon' title='Promedio de sesiones registradas por agente activo por día.' ><i className="bi-info-circle-fill"></i></Tooltip></p>
                        </div>
                        <div className='d-flex align-items-center'>
                            <h4>{avg}</h4>
                            <p className='ms-3 nowrap'>Tiempo promedio por sesión. <Tooltip className='ms-1 color-icon' title='Duración promedio de las sesiones registradas.' ><i className="bi-info-circle-fill"></i></Tooltip></p>
                        </div>

                    </div>

                </div>



            </div>
        </div>
    )
}
export default HTMLWidget