import { useNavigate, useLocation } from "react-router-dom";
import Aside from "../../../components/Aside"
import Footer from "../../../components/Footer"
import Header from "../../../components/Header"
import Tooltip from "../../../components/Tooltip"
import { round } from "../../../utils/number";
import { formatTime } from "../../../utils/dateTime";
import moment from 'moment';
import 'moment/locale/es'
import { useEffect, useRef, useState } from "react";
import { ReportService } from "../../../services/reportService";

declare const bootstrap: any;

function SessionDetailModal({ open, material, onClose }: any) {

    const modalRef = useRef<HTMLDivElement>(null)
    const [modal, setModal] = useState<any>(null)

    const navigate = useNavigate();

    // const { state: { material } } = useLocation()

    const [detail, setDetail] = useState(null)

    const getTypeFormat = (type) => {
        switch (type) {
            case 'slide':
                return <><i className="bi-easel2 me-1"></i> Slide</>
            case 'click':
                return <><i className="bi-hand-index me-1"></i> Clic</>
            case 'survey':
                return <><i className="bi-clipboard2-data me-1"></i> Encuesta</>
            case 'share':
                return <><i className="bi-share me-1"></i> Campartir</>

            default:
                type;
        }
    }

    const getClassType = (type) => {
        switch (type) {
            case 'slide':
                return 'step-icon-soft-success';
            case 'click':
                return 'step-icon-soft-primary';
            case 'survey':
                return 'step-icon-soft-info';
            case 'share':
                return 'step-icon-soft-warning';

            default:
                return 'step-icon-soft-dark';
        }
    }

    const convertObjectToArray = (answers) => {
        const keys = Object.keys(answers);

        let answersArray = [];
        let index = 0;
        for (const key of keys) {
            answersArray.push((
                <li className="list-group-item list-group-item-light" key={index++}>
                    <p className="d-block text-dark text-truncate">{key}</p>
                    <p className="d-block small">{answers[key]}</p>
                </li>
            ))


        }

        return answersArray;
    }

    const getSessionDetail = async () => {
        const response = await ReportService.getSessionDetail(material._id)
        setDetail(response)
    }

    useEffect(() => {
        if (material) {
            getSessionDetail();
        }
    }, [material])

    useEffect(() => {
        if (open) {
            modal.show()
        } else {
            if (modal) {
                modal.hide()
            }
        }
    }, [open])


    useEffect(() => {
        if (modalRef.current) {
            const modalInstance = new bootstrap.Modal(modalRef.current, {})
            setModal(modalInstance);

            modalRef.current.addEventListener('hidden.bs.modal', _ => onClose())
        }

    }, [modalRef])


    return (
        <div className="modal fade" ref={modalRef} tabIndex={-1} data-bs-backdrop="static" data-bs-keyboard="false" >
            <div className="modal-dialog" role="document">
                <div className="modal-content border-0">

                    <div className="modal-body p-0">
                        <div className="card rounded-0 shadow-none">
                            <div className="card-header">
                                <span className="step-divider last"><i className="bi-hourglass-top me-2"></i> Inicio de sesión {moment(material?.started_at).format('D MMMM YYYY HH:mm a')}</span>
                            </div>
                            <div className="card-body card-body-height" style={{ height: '30rem' }}>
                                <ul className="step step-icon-xs mb-0">
                                    {
                                        detail?.events?.map((e, i) => (
                                            <li className="step-item" key={i}>
                                                <div className="step-content-wrapper">
                                                    <span className={`step-icon ${getClassType(e.type)} step-icon-pseudo`}></span>

                                                    <div className="step-content">
                                                        <p>{getTypeFormat(e.type)}</p>
                                                        <h6 className="mb-1 text-dark">
                                                            {
                                                                e.type === 'slide' ? (
                                                                    <>{e.slide.slide_name}</>
                                                                ) : e.type === 'click' ? (
                                                                    <>{e.click.object_name}</>
                                                                ) : e.type === 'survey' ? (
                                                                    <>{e.survey.surver_name}</>
                                                                ) : (
                                                                    <></>
                                                                )
                                                            }
                                                        </h6>

                                                        {
                                                            e.type === 'survey' && (
                                                                <ul className="list-group my-3">
                                                                    {convertObjectToArray(e.survey.answers)}
                                                                </ul>
                                                            )
                                                        }

                                                        {/* {e.type === 'slide' && <p className="fs-5 mb-1">De {moment(e.slide.start_timestamp).format('HH:mm:ss a')} a {moment(e.slide.end_timestamp).format('HH:mm:ss a')}</p>} */}
                                                        {e.type === 'slide' && <p className="fs-5 mb-1">Duración {formatTime((e.slide.end_timestamp-e.slide.start_timestamp) / 1000)}</p>}

                                                        <p className="text-muted small text-uppercase">{moment(e.timestamp).format('HH:mm a')}</p>

                                                    </div>
                                                </div>
                                            </li>
                                        ))
                                    }


                                </ul>
                            </div>
                            <div className="card-footer shadow-none">
                                <span className="step-divider last"><i className="bi-hourglass-bottom me-2"></i> Fin de sesión {moment(material?.ended_at).format('D MMMM YYYY HH:mm a')}</span>
                            </div>
                        </div>
                    </div>

                    <div className="modal-footer border-0">
                        <button type="button" className="btn btn-soft-primary btn-sm" onClick={() => modal.hide()}>Cerrar</button>
                    </div>
                </div>
            </div>
        </div>
    )

}
export default SessionDetailModal