import { useEffect, useState } from "react"
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { MaterialService } from "../services/materialService";
import HandleNoData from "./HandleNoData";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface PreviewMediaContentProps {
    title?: string;
    material: any;
}

function PreviewMediaContent({ title = 'Archivo de material', material }: PreviewMediaContentProps) {

    const [url, setUrl] = useState<string>()
    const [showModal, setShowModal] = useState(false)
    const [numPages, setNumPages] = useState(null)

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }


    const getURL = async () => {
        const { url } = await MaterialService.getUrlSigned(material._id);
        setUrl(url)
    }

    const getURLHTML = async () => {
        const { url } = await MaterialService.getURLHTML(material._id);
        setUrl(url)
    }

    const handleURL = () => {
        if(material.type === 'HTML') {
            getURLHTML()           
        } else {
            getURL()
        }
    }

    useEffect(() => {
        const root = document.getElementsByTagName('html')[0]
        root.setAttribute('class', showModal ? 'preview-open' : '');

        if(!showModal) {
            setUrl('')
        } else {
            handleURL();
        }

    }, [showModal])

    return (
        <>
            <div className="card">
                <div className="card-body p-t-12 p-b-12">
                    <div className="d-flex align-items-center">
                        <p className="text-dark font-12 fw-semibold m-r-16 text-uppercase"  >{title}</p>

                        <div className="d-flex align-items-center ms-auto">
                            <button className="btn btn-outline-primary btn-outline-trash  btn-icon" onClick={() => setShowModal(true)}>
                                <i className="bi bi-eye-fill"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <HandleNoData condition={showModal}>
                <div className="preview">
                    <div className="preview-navbar">
                        <div className="preview-toolbar">
                            <div className="preview-toolbar-button preview-flex-centered" title="Close" onClick={() => setShowModal(false)}>
                                <svg width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#fff">
                                    <path className="preview-svg-path" d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z"></path>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className="preview-content">
                        <HandleNoData 
                            condition={url} 
                            emptyComponent={
                                <div className="preview-loader">
                                    <div className="spinner-border text-light" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            }
                        >
                            <HandleNoData condition={material.type  === 'PDF' }>
                                <div className="preview-pdf">
                                    <Document
                                        file={
                                            { url: url }
                                        }
                                        // pageMode={{
                                        //     fullScreen: true
                                        // }}
                                        loading={
                                            <div className="preview-content">
                                                <div className="preview-loader">
                                                    <div className="spinner-border text-light" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        onLoadSuccess={onDocumentLoadSuccess} >
                                            {Array.from(new Array(numPages), (el, index) =>
                                                <Page className='mb-4' width={1000}  key={`page_${index + 1}`} pageNumber={index + 1} />
                                            )}
                                    </Document>
                                </div>
                            </HandleNoData> 

                            <HandleNoData condition={material.type  === 'IMAGE' }>
                                <div className="preview-img">
                                    <img src={url} alt={material.name} />
                                </div>
                            </HandleNoData> 
                            
                            <HandleNoData condition={material.type  === 'VIDEO' }>
                                <div className="preview-video">
                                    <video  controls controlsList="nodownload" onContextMenu={(e)=> e.preventDefault()}>
                                        <source src={url} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            </HandleNoData>
                            
                            <HandleNoData condition={material.type  === 'HTML' }>
                                
                                <div className="preview-html">
                                    <iframe src={url} title={material.name}></iframe>
                                    {/* <iframe src='https://www.loftpublicidad.com/' title={material.name}></iframe> */}
                                </div>
                                
                            </HandleNoData>

                        </HandleNoData>
                    </div>
                </div >
            </HandleNoData>



        </>
    )
}
export default PreviewMediaContent