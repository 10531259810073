import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import FsLightbox from 'fslightbox-react';
import HandleNoData from "./HandleNoData";

function DragAndDrop({ label, sublabel, title, subtitle, onChange, thumbnail = '', className = '', accept = '*' }: any) {

    const didInit = useRef(false)
    const inputRef = useRef<HTMLInputElement>(null)

    const [fileSelected, setFileSelected] = useState<any>()
    const [togglerPoster, setTogglerPoster] = useState(false);

    const blobImage = useMemo(() => {
        return fileSelected && fileSelected.type.startsWith('image/') ? URL.createObjectURL(fileSelected): ''
    }, [fileSelected])

    const drop = (event: any) => {
        event.preventDefault();
        const data = event.dataTransfer;
        handleFileSelected(data)
    }

    const onDragOver = (event: any) => {
        event.preventDefault();
    }

    const handleFileSelected = (target: any) => {
        if (target.files && target.files[0]) {
            const file: File = target.files[0];
            const ext = file.name.split('.').pop()
            const allowedExt = accept.split(',')

            if(accept === '*' || allowedExt.indexOf(`.${ext!.toLowerCase()}`) !== -1 ) {
                setFileSelected(target.files[0])
            }            
        }
    }

    useLayoutEffect(() => {

        if (!fileSelected && inputRef.current) {
            inputRef.current.value = '';
        }

        if(didInit.current) {
            onChange(fileSelected)
        }
    }, [fileSelected])
    

    useEffect(() => {
        if (!didInit.current) {
            didInit.current = true;
        }
    }, [])

    return (
        <>
            <label className="form-label" >{label}</label>
            <div className={'drag-drop ' + className} onDrop={drop} onDragOver={onDragOver} >
                <h6 className="m-b-8">{title}</h6>
                <p className="">{subtitle}</p>
                <input type="file" className="d-none" accept={accept} ref={inputRef} onChange={({ target }) => handleFileSelected(target)} />
                <button type="button" className="btn btn-outline-primary btn-sm me-2 m-t-8" onClick={() => inputRef.current?.click()} >{fileSelected ? 'reemplazar archivo' : 'busca un archivo'}</button>
            </div>

            { thumbnail && !fileSelected && 
                (
                    <div className="card m-t-8 ">
                        <div className="card-body p-t-12 p-b-12">
                            <div className="d-flex align-items-center">
                                <p className="text-dark font-12 fw-semibold m-r-16 text-uppercase">{sublabel}</p>
                                <div className="d-flex align-items-center ms-auto">
                                    <FsLightbox
                                        toggler={togglerPoster}
                                        sources={[thumbnail]}
                                    />
                                    <img src={thumbnail} alt={label} className='rounded m-r-16' style={{ width: '42px'}} />
                                    <button type="button" className="btn btn-outline-primary btn-outline-trash  btn-icon " onClick={() => setTogglerPoster(!togglerPoster)}>
                                        <i className="bi bi-eye-fill"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }


            {
                fileSelected && (
                    <div className="card m-t-8 ">
                        <div className="card-body p-t-12 p-b-12">
                            <div className="d-flex align-items-center">
                                <p className="text-dark font-12 fw-semibold m-r-16 text-uppercase">{sublabel}</p>
                                <div className="d-flex align-items-center ms-auto">
                                    <p className="text-success fw-semibold m-r-16">{fileSelected?.name}</p>
                                    <HandleNoData condition={blobImage}><img src={blobImage} alt={fileSelected?.name} className='rounded m-r-16' style={{ width: '42px'}} /></HandleNoData>
                                    <button type="button" className="btn btn-outline-primary btn-outline-trash  btn-icon " onClick={() => setFileSelected(null)}>
                                        <i className="bi bi-trash-fill"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            <span className="invalid-feedback">Campo obligatorio.</span>
        </>
    )
}

export default DragAndDrop
