import { useEffect, useState } from "react";
import { MaterialService } from "../services/materialService";

interface AudioPlayertProps {
    material: any;
}

function AudioPlayer({ material }: AudioPlayertProps) {

    const [audio, setAudio] = useState<HTMLAudioElement>()
    const [duration, setDuration] = useState('00:00')
    const [currentTime, setCurrentTime] = useState('00:00')
    const [progress, setProgress] = useState(0)
    const [isPlaying, setIsPlaying] = useState(false)

    const play = () => {
        audio!.play();
        setIsPlaying(true)
    }
    const pause = () => {
        audio!.pause();
        setIsPlaying(false)
    }

    const formatTime = (duration) => {
        const secs = duration
        const minutes = `${Math.floor(secs / 60)}`;
        const seconds = `${Math.floor(secs % 60)}`;

        return `${minutes.padStart(2,'0')}:${seconds.padStart(2,'0')}`;
    }

    useEffect(() => {
        if(audio) {
        
            audio.onloadedmetadata = () => {
                setDuration(formatTime(audio.duration))
            }
            audio.ontimeupdate = () => {
                setCurrentTime(formatTime(audio.currentTime))

                const currentTime = audio.currentTime;
                const duration = audio.duration;


                const _progress_ = Math.ceil((currentTime*100) / duration)

                setProgress(_progress_)
            }

        }
        
    }, [audio])

    useEffect(() => {

        const getURL = async() => {
            const response = await MaterialService.getUrlSigned(material._id);
            const _audio_ = new Audio(response.url);
            setAudio(_audio_)
        }

        if(material) {
            getURL()
        }

    }, [material])
    
    


    return (
        <div className="card">
            <div className="card-body ">
                <div className="audio-player-container d-flex align-items-center">
                    { !isPlaying && <div className="audio-player-btn" onClick={play}><i className="bi-play-fill"></i></div>}
                    { isPlaying && <div className="audio-player-btn" onClick={pause}><i className="bi-pause-fill"></i></div>}
                    
                    <p className="">{currentTime}</p>
                    <div className="progress w-100">
                        <div className="progress-bar" role="progressbar" style={{ width: `${progress}%` }}></div>
                    </div>
                    <p>{duration}</p>
                </div>
            </div>
        </div>
    )
}
export default AudioPlayer