import moment from "moment"
import 'moment/locale/es'
import { formatTime } from "../../../utils/dateTime"
import Select from 'react-select'
import { useEffect, useState } from 'react';
import { ReportService } from '../../../services/reportService';
import EmptyState from "../../../components/EmptyState";
import { useNavigate } from "react-router-dom";
import SessionDetailModal from "./SessionDetailModal";

function MaterialTable({ materials, rangeFilter }) {

    const navigate = useNavigate();

    const [optionSelected, setOptionSelected] = useState(null)
    const [list, setList] = useState([])

    const [modal, setModal] = useState({
        show: false,
        data: null
    })

    useEffect(() => {
        const getList = async () => {
            const response = await ReportService.getHTMLSByContentRepository(optionSelected.value, rangeFilter.start.format('YYYY-MM-DD'), rangeFilter.end.format('YYYY-MM-DD'))
            setList(response)
        }

        !optionSelected ? setList([]) : getList()

    }, [optionSelected])

    useEffect(() => {
        setList([])
        setOptionSelected(null)
    }, [rangeFilter])
    


    return (
        <>

            <div className="row">

                <div className="col-12 m-b-16">
                    <div className="d-flex align-items-end">
                        <div className="w-25">
                            <label className="form-label" >Material</label>
                            <Select
                                placeholder='Seleccionar un material'
                                options={materials}
                                className={false ? 'form-select-custom is-invalid' : ''}
                                classNamePrefix='form-select-custom'
                                onChange={(value: any) => setOptionSelected(value)}
                                value={optionSelected}
                            />
                        </div>

                    </div>

                </div>
            </div>

            <table className="card-table table">
                <thead>
                    <tr>
                        <th>CONTACTO</th>
                        <th className="text-center">RUTA O ID</th>
                        <th className="text-center">SEGMENTO(S)</th>
                        <th className="text-center">Agente</th>
                        <th className="text-center">FECHA</th>
                        <th className="text-center">Duración</th>
                        <th className="text-center"></th>
                    </tr>
                </thead>
                <tbody>
                    {list.length === 0 && <tr><td colSpan={6}><EmptyState /></td></tr>}
                    {
                        list.map((row) => (
                            <tr key={row._id}>
                                <td>{row.contact.first_name} {row.contact.last_name}</td>
                                <td className="text-center">100-1</td>
                                <td className="text-center">
                                    {row.contact.segmentations.map( s => s.title).join(', ')}
                                </td>
                                <td className="text-center">{row.agent.first_name} {row.agent.last_name}</td>
                                <td className="text-center">{moment(row.started_at).format('D MMMM YYYY HH:mm')}</td>
                                <td className="text-center">{formatTime(row.time)}</td>
                                <td className="text-center">
                                    <button className="btn btn-outline-primary btn-outline-edit btn-sm " onClick={() => setModal({ show: true, data: row })} >
                                        Detalle
                                    </button>
                                </td>
                            </tr>
                        ))
                    }

                </tbody>
            </table>

            <SessionDetailModal 
                open={modal.show}
                material={modal.data}
                onClose={() => setModal({ show: false, data: null })}
            />
        </>
    )
}
export default MaterialTable