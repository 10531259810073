import axios from 'axios';
import { STORAGE } from '../constants';
import { LocalStorageService } from './localStoraService';


const _HTTP_ = axios.create();

_HTTP_.interceptors.request.use(
    (config: any) => {
        const token = LocalStorageService.get(STORAGE.TOKEN);
        if(token) {
            config.headers['token-api'] = `${token}`;
        }

        return config;
    }
)

_HTTP_.interceptors.response.use(
    (response) => response.data,
    (error) => {
        const { status } = error.response;
        if (status === 401) {
            window.location.replace('/auth/login');
            LocalStorageService.clear();
        }

        return Promise.reject(error.response.data || { message: 'Unknown error' });
    }
)

export const HTTP = _HTTP_;
