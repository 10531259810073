import { useEffect, useRef, useState } from "react"
import { useFieldArray, useForm } from "react-hook-form"
import Button from "../../components/Button"
import Input from "../../components/Input"

declare const bootstrap: any;

function PollModal({ open = false, onSave, defaultData, readOnly = false }: any) {

    const modalRef = useRef<HTMLDivElement>(null)
    const [modal, setModal] = useState<any>(null)


    const defaultValues = {
        question: '',
        answers: [
            {
                value:''
            },
            {
                value:''
            },
        ]
    }
    const { control, register, reset, handleSubmit, formState: { errors, isValid, isSubmitting } } = useForm({
        mode: 'onChange',
        defaultValues
    })

    const { fields, append, remove } = useFieldArray({
        control,
        rules: { required: true, minLength: 2 },
        name: 'answers'
    });

    const submit = (data: any) => {
        data.answers = data.answers.map( d => d.value);
        onSave(data)
    }

    const handleResetForm = () => {
        reset({
            question: defaultData?.question || '',
            answers: defaultData?.answers ? defaultData.answers.map( a => ({ value: a })) : [{ value: ''}, { value: ''}]
        })
    }

    useEffect(() => {
        if(open) {
            modal.show()
        } else {
            if(modal) {
                modal.hide()
            }
        }

        handleResetForm()
    }, [open])

    useEffect(() => {
        if(modalRef.current) {
            const modalInstance = new bootstrap.Modal(modalRef.current, {})
            setModal(modalInstance)

            modalRef.current.addEventListener('hidden.bs.modal', _ => onSave(null) )
        }
        
    }, [modalRef])
    


    return (
        <div className="modal fade" ref={modalRef} tabIndex={-1} data-bs-backdrop="static" data-bs-keyboard="false" >
            <div className="modal-dialog" role="document">
                <form onSubmit={handleSubmit(submit)}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" >Pregunta de votación</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row m-b-40">
                                <div className="col-4">
                                    <p className="text-dark fw-bold m-b-8 font-12 text-uppercase">Pregunta</p>
                                    <p className="font-12">Breve descripción</p>
                                </div>
                                <div className="col-8">
                                    <div className="m-b-24">
                                        <Input label='Título de pregunta *' errors={errors.question} {...register('question', { required: true })}  disabled={readOnly}/>
                                    </div>
                                </div>
                            </div>

                            <div className="row m-b-40">
                                <div className="col-4">
                                    <p className="text-dark fw-bold m-b-8 font-12 text-uppercase">Respuestas</p>
                                    <p className="font-12">En una votación la respuesta es obligatoria {fields.length}</p>
                                </div>
                                <div className="col-8">
                                    {
                                        fields.map((item, index) => (
                                            <div className="m-b-24" key={item.id}>
                                                <Input label={`Respuesta ${index + 1} *`} errors={errors.answers?.[index]} {...register(`answers.${index}.value`, { required: true })}  disabled={readOnly} >
                                                    {
                                                        !readOnly && index >= 2 && (
                                                            <button type="button" className="btn btn-outline-danger btn-icon m-l-8" onClick={() => remove(index)}>
                                                                <i className="bi bi-trash3"></i>
                                                            </button>
                                                        )
                                                    }
                                                </Input>
                                            </div>
                                        ))
                                    }

                                    {
                                        !readOnly && (
                                            <div className="m-b-24">
                                                <button type="button" className="btn btn-link text-info text-decoration-underline fw-semibold px-0" onClick={() => append({ value: '' })}>
                                                    <i className="bi bi-plus"></i> agregar respuesta
                                                </button>
                                            </div>
                                        )
                                    }
                                    
                                </div>
                            </div>


                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-soft-primary btn-sm" onClick={() => modal.hide() }>{readOnly ? 'Cerrar': 'Cancelar'}</button>
                            {!readOnly &&  <Button type="submit" className="btn btn-primary btn-sm" disabled={!isValid} isLoading={isSubmitting}>Guardar pregunta</Button>}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
export default PollModal