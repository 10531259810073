import { useNavigate, useLocation } from "react-router-dom";
import Aside from "../../components/Aside"
import Footer from "../../components/Footer"
import Header from "../../components/Header"
import { Controller, useForm } from "react-hook-form";
import Input from "../../components/Input";
import Select from 'react-select'
import { useEffect, useRef, useState } from "react";
import Button from "../../components/Button";
import { SegmentService } from "../../services/segmentService";
import { TemplatesService } from "../../services/templatesService";
import { MaterialService } from "../../services/materialService";
import { formatType } from "../../utils/formatTypeContentRepository";
import SMSPartial from "./SMSPartial";
import WhatsAppPartial from "./WhatsAppPartial";
import { CampaignService } from "../../services/campaignService";
import Alert from "../../components/Alert"

declare const flatpickr: any;

function CampaignFormPage() {

    const navigate = useNavigate();

    const { state: { type } } = useLocation()

    const datepicketRef = useRef()

    const [segmentations, setSegmentations] = useState([])
    const [templates, setTemplates] = useState([])
    const [contentRepositories, setContentRepositories] = useState([])
    const [showModal, setShowModal] = useState(false)

    const defaultValues = {
        name: '',
        sent_at: '',
        segmentations: [],
        type: '',
        include_content_repository: false,
        content_repository: '',

        whatsapp: {
            template: '',
            file: '',
            tokens: [],
        },

        sms: {
            message: '',
            file: ''
        },

    }
    const { control, register, watch, handleSubmit, setValue, getValues, setError, resetField, clearErrors, formState: { errors, isValid, isSubmitting } } = useForm({
        mode: 'onChange',
        defaultValues
    })

    register('sent_at', { required: true }); 
    const segmentationsField = watch('segmentations')


    const submit = async(data) => {
        
        try {
            const form = new FormData();
            form.append('name', data.name)
            form.append('sent_at', data.sent_at)
            form.append('segmentations', data.segmentations.join(','))
            form.append('type', data.type.toUpperCase())
            form.append('file', data.type === 'sms' ? data.sms.file[0] : data.whatsapp.file[0])
            form.append('content_repository', data.content_repository)

            form.append('message', data.sms.message)
            form.append('template', data.whatsapp.template)
            form.append('tokens', JSON.stringify(data.whatsapp.tokens))
            

            const response = await CampaignService.save(form);

            console.log(response)

            Alert.fire({
                title: 'Campaña guardada',
                text: `Se guardo correctamente la campaña`
            });

            navigate('/campaigns', { replace: true });
        } catch (error) {
            console.error(error)

            Alert.fire({
                title: 'Error al guardar la campaña',
                text: `Ocurrio un error al guardar la campaña, intenta nuevamentes y si el error persiste contacta a tu administrador.`,
                type: 'error'
            });
        }


    }

    const getContentRepositories = async () => {
        const response = await MaterialService.getAllForSpace(segmentationsField.join(','))
        setContentRepositories(response.map((a: any) => ({ value: a._id, label: `${a.name} (${formatType(a.type)})` })))
    }

    useEffect(() => {
        if(segmentationsField && segmentationsField.length > 0) {
            getContentRepositories()
        } else {
            setContentRepositories([])
        }
    }, [segmentationsField])
    

    useEffect(() => {
        const getSegmentations = async () => {
            const response = await SegmentService.getActive()
            setSegmentations(response.map((a: any) => ({ value: a._id, label: a.title })))
        }
        const getAllApproved = async () => {
            const response = await TemplatesService.getAllApproved()
            setTemplates(response.map((a: any) => ({ value: a._id, label: a.title })))
        }
        

        flatpickr(datepicketRef.current, {
            enableTime: true,
            dateFormat: 'd-m-Y H:i',
            onChange: (selectedDates, dateStr) => {
                setValue('sent_at', dateStr)
                clearErrors('sent_at')
            }
        });

        getSegmentations()
        getAllApproved()
        
    }, [])

    useEffect(() => {
      setValue('type', type)
    }, [type])


    return (
        <>
            <Header>
                <div>
                    <h4>Crear nueva campaña</h4>
                    <p>Lorem ipsum</p>
                </div>

                <div className="ms-auto">
                    {/* disabled={!isValid} */}
                    <Button type="submit" className="btn btn-primary btn-sm"  isLoading={isSubmitting} onClick={handleSubmit(submit)}>Guardar campaña</Button>
                </div>
            </Header>

            <Aside />

            <main role="main" className="main">

                <div className="bg-white border-bottom">
                    <div className="container p-t-12 p-b-12 px-5">
                        <div className="d-flex align-items-center">
                            <i className="bi bi-search m-l-8"></i>
                            <input className="form-control input-group-borderless form-control-flush" type="text" placeholder="Buscar" />
                        </div>
                    </div>
                </div>

                <div className="content container px-5">

                    <div className="row">
                        <div className="col-12 m-b-32">
                            <button type="button" className="btn btn-link text-dark text-decoration-underline px-0" onClick={() => navigate(`/campaigns`)}><i className="bi bi-arrow-left me-2"></i>  Campañas</button>
                        </div>
                    </div>


                    <div className='row'>
                        <div className='col-3'>
                            <p className="text-dark fw-bold text-uppercase mb-3">Datos generales</p>
                            {/* <p>Actualiza tu fotografía e información personal.</p> */}
                        </div>
                        <div className='col-9'>
                            <div className="card">
                                <div className="card-body">
                                    <div className="m-b-24">
                                        <Input label='nombre *' errors={errors.name} {...register('name', { required: true })} />
                                    </div>
                                    <div className="m-b-24">
                                        <label className="form-label" >fecha y hora de envío *</label>
                                        <input type="text" className={'form-control form-control-lg ' + (errors.sent_at ? 'is-invalid' : '')} readOnly ref={datepicketRef} />
                                        <span className="invalid-feedback">Campo requerido.</span>
                                    </div>
                                    <div className="m-b-24">
                                        <label className="form-label" >Segmentación *</label>
                                        <Controller
                                            control={control}
                                            name='segmentations'
                                            rules={{ required: false }}
                                            render={({
                                                field: { onChange, onBlur, value },
                                                fieldState: { error },
                                            }) => (
                                                <Select
                                                    placeholder='Seleccionar segmentos'
                                                    options={segmentations}
                                                    isMulti
                                                    className={error ? 'form-select-custom is-invalid' : ''}
                                                    classNamePrefix='form-select-custom'
                                                    onBlur={onBlur}
                                                    onChange={(values: any) => onChange(values.map((v: any) => v.value))}
                                                    value={segmentations.filter((s: any) => (value as Array<any>).indexOf(s.value) !== -1)}
                                                />
                                            )}
                                        />
                                        <span className="invalid-feedback">Campo requerido.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    { type === 'whatsapp' && <WhatsAppPartial errors={errors} control={control} register={register} getValues={getValues} setValue={setValue} watch={watch} contentRepositories={contentRepositories} /> }
                    { type === 'sms' && <SMSPartial errors={errors} register={register} getValues={getValues} setValue={setValue} watch={watch} control={control} contentRepositories={contentRepositories}/> }

                </div>

                <Footer />
            </main>

            
        </>
    )
}
export default CampaignFormPage