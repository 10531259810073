import { useEffect, useRef, useState } from "react";
import Button from "../../components/Button";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import Select from 'react-select'
import { TemplatesService } from "../../services/templatesService";
import { EscapeRegExp, TemplateBody, TemplateButtons, TemplateFooter, TemplateHeader, TemplateParse } from "../../utils/templateWhatsapp";
import Input from "../../components/Input";
import { InboxService } from "../../services/inboxService";
import Alert from "../../components/Alert";

declare const bootstrap: any;

interface TemplatesModalProps {
    show: Boolean;
    onRequestClose: (data?: any) => void;
    data: any
}

function TemplatesModal({ show, onRequestClose, data }: TemplatesModalProps) {

    const modalRef = useRef<HTMLDivElement>(null)

    const defaultValues = {
        template: '',
        tokens: []
    }
    const { control, register, handleSubmit, reset, watch,  getValues, setValue, formState: { errors, isValid, isSubmitting } } = useForm({
        mode: 'onChange',
        defaultValues
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'tokens'
    });

    
    const template = watch('template')

    const [modal, setModal] = useState<any>(null)
    const [templates, setTemplates] = useState([])
    const [selectedTemplate, setSelectedTemplate] = useState<{
        header: TemplateHeader,
        body: TemplateBody,
        bodyTokens: Array<any>,
        footer: TemplateFooter,
        buttons: TemplateButtons
    }>()

    const isTemplateForMaterial = (template) => {
        const buttons = template.components.find(c => c.type === 'BUTTONS');

        if (!buttons) return false;

        return buttons.buttons.find(b => b.type === 'URL' && b.url === 'https://shortty.link/{{1}}');
    }

    const buildMessage = () => {
        const expresion = /({{[0-9]}})/g

        
        let message = '';
        if(selectedTemplate.header) {
            message += `*${selectedTemplate.header.text}*\n\n`
        }

        const bodyTokens = getValues('tokens')
        message += selectedTemplate.body.text

        for (const token of bodyTokens) {
            message = message.replace(new RegExp(EscapeRegExp(token.token), 'g'), token.value )
        }

        if(selectedTemplate.footer) {
            message += `\n\n_${selectedTemplate.footer.text}_`
        }
        
        if(selectedTemplate.buttons) {
            message += '\n\n' + selectedTemplate.buttons.buttons.map( b => `- ${b.text}` ).join('\n')
        }

        return message
    }

    const buildComponents = () => {
        
        const bodyTokens = getValues('tokens')
        if(!bodyTokens.length ) return []

        let components = []
        bodyTokens.forEach( t => components[+(t.token.replace(/[{}]/g, ''))] = { type: 'text', text: t.value } )

        return [{ type: 'body', parameters: components }]
    }

    const submit = async(form) => {
        
        try {
            const response = await InboxService.sendMessage({
                contact: data.contact._id,
                message: buildMessage(),
                template: form.template,
                components: buildComponents()
            })

            Alert.fire({
                title: 'Mensaje enviado',
                text: `El mensaje se ha enviado al contacto correctamente`
            });

            onRequestClose(response.message)

        } catch (error) {
            
            Alert.fire({
                title: 'Error al enviar mensaje',
                text: `Ocurrio un error al enviar el mensaje, intenta nuevamentes y si el error persiste contacta a tu administrador.`,
                type: 'error'
            });
        }
    }

    useEffect(() => {
        if (selectedTemplate) {
            remove()
            selectedTemplate.bodyTokens.forEach( t => append({ token: t, value: '' }, { shouldFocus: false}))

        }
    }, [selectedTemplate])
    useEffect(() => {
        const t = templates.find(t => t.value === template)

        if (t) {
            const templateParsed = TemplateParse(t.data.components)
            setSelectedTemplate(templateParsed)
        }
    }, [template])


    useEffect(() => {
        if (modalRef.current) {
            const modalInstance = new bootstrap.Modal(modalRef.current, {})
            setModal(modalInstance)
        }

    }, [modalRef])

    useEffect(() => {
        if (modal) {
            if(show) {
                modal.show()
            } else {
                modal.hide()
                reset(defaultValues)
                setSelectedTemplate(null)
            }
        }
    }, [modal, show])

    useEffect(() => {
        const getTemplates = async () => {
            const response = await TemplatesService.getAllApproved();

            const templatesNotLinks = response
                .filter(t => !isTemplateForMaterial(t))
                .map((a: any) => ({ value: a._id, label: `${a.name} (${a.language})`, data: a }))

            setTemplates(templatesNotLinks)
        }

        getTemplates()
    }, [])

    return (
        <div className="modal fade" ref={modalRef} tabIndex={-1} data-bs-backdrop="static" data-bs-keyboard="false" >
            <div className="modal-dialog modal-lg" role="document">

                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" >Plantilla de texto</h5>
                        <button type="button" className="btn-close" onClick={() => onRequestClose()}></button>
                    </div>
                    <div className="modal-body">
                        <div className="row m-b-40">
                            <div className="col-4">
                                <p className="text-dark fw-bold m-b-8 font-12 text-uppercase">Plantilla</p>
                                {/* <p className="font-12">Lorem ipsum dolor sit amet consectetur adipisicing elit.</p> */}
                            </div>
                            <div className="col-8">
                                <div className="m-b-24">
                                    <label className="form-label" >PLANTILLA DE MENSAJE*</label>
                                    <Controller
                                        control={control}
                                        name='template'
                                        rules={{ required: true }}
                                        render={({
                                            field: { onChange, onBlur, value },
                                            fieldState: { error },
                                        }) => (
                                            <Select
                                                placeholder='Selecciona una plantilla'
                                                options={templates}
                                                className={error ? 'form-select-custom is-invalid' : ''}
                                                classNamePrefix='form-select-custom'
                                                onBlur={onBlur}
                                                onChange={(value: any) => onChange(value.value)}
                                                value={templates.filter(c => c.value === value)}
                                            />
                                        )}
                                    />
                                    <span className="invalid-feedback">Campo requerido.</span>
                                </div>
                            </div>
                        </div>
                        
                        <div className={`row ${fields.length > 0 ? 'm-b-40':''}`}>
                            <div className="col-4">
                                <p className="text-dark fw-bold m-b-8 font-12 text-uppercase">Previsualización</p>
                                {/* <p className="font-12">Lorem ipsum dolor sit amet consectetur adipisicing elit.</p> */}
                            </div>
                            <div className="col-8">
                                <div className="m-b-24">
                                    {
                                        !selectedTemplate && <div className="alert alert-blue">Selecciona una plantilla para previsualizar su contenido</div>
                                    }
                                    {
                                        selectedTemplate && (
                                            <div className="preview-wa">
                                                <div className="preview-wa-message">
                                                    {selectedTemplate.header && <p className="wa-title">{selectedTemplate.header.text}</p>}
                                                    <p className="wa-body" dangerouslySetInnerHTML={{ __html: selectedTemplate.body.text }} />
                                                    {selectedTemplate.footer && <p className="wa-footer">{selectedTemplate.footer.text}</p>}
                                                </div>

                                                {
                                                    selectedTemplate.buttons && selectedTemplate.buttons.buttons.map((b, i) => (<div className="preview-wa-button" key={i}>{b.text}</div>))
                                                }
                                            </div>
                                        )
                                    }

                                </div>
                            </div>
                        </div>
                        {
                            fields.length > 0 && (
                                <div className="row">
                                    <div className="col-4">
                                        <p className="text-dark fw-bold m-b-8 font-12 text-uppercase">Variables de plantilla</p>
                                        {/* <p className="font-12">Lorem ipsum dolor sit amet consectetur adipisicing elit.</p> */}
                                    </div>
                                    <div className="col-8">
                                        { fields.map(( field, index) => (
                                            <div className="m-b-36" key={field.id}>
                                                <Input label={field.token} errors={errors.tokens?.[index]?.['value']} {...register(`tokens.${index}.value`, { required: true })} />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )
                        }
                        
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-soft-primary btn-sm" onClick={() => onRequestClose()}>Cerrar</button>
                        <Button type="submit" className="btn btn-primary btn-sm" disabled={!isValid} isLoading={isSubmitting} onClick={handleSubmit(submit)}>Enviar</Button>
                    </div>
                </div>

            </div>
        </div>
    )
}
export default TemplatesModal