import Aside from "../../components/Aside"
import Footer from "../../components/Footer"
import Header from "../../components/Header"
import { ContactService } from "../../services/contactService"
import { useEffect, useState } from "react"
import HandleNoData from "../../components/HandleNoData"
import EmptyState from "../../components/EmptyState"
import ContactFormModal from "./ContactFormModal"

function ContactHomePage() {

  
    const [contacts, setContacts] = useState([])
    const [modal, setModal] = useState({
        show: false,
        data: null
    })
    
    
    const showModal = (data?: any) => {
        let contact = null;

        if(data) {
            contact = {
                _id: data._id,
                first_name: data.first_name,
                last_name: data.last_name,
                email: data.email,
                country_code: data.phones && data.phones.length ? data.phones[0].phone.country_code: '',
                phone_number: data.phones && data.phones.length ? data.phones[0].phone.phone_number: '',
                assigned_id: data.assigned_id?._id || '',
                segmentations: data.segmentations.map( (s: any) => s._id),
                type: data.type,
                medical_specialty: data?.medical_specialty?._id || ''
            }
        }        

        setModal({
            show: true,
            data: contact
        })
    }

    const getContacts = async () => {
        const response = await ContactService.getContacts()
        setContacts(response)
    }

    const handleHideModal = async(refresh: boolean) => {
        if(refresh) {
            await getContacts();
        }

        setModal({
            show: false, 
            data: null
        })
    }

    useEffect(() => {
        getContacts()
    }, [])


    return (
        <>
            <Header>
                <div>
                    <h4>Contactos</h4>
                    <p>Administra y añade nuevos contactos</p>
                </div>

                <div className="ms-auto">
                    <button type="button" className="btn btn-primary btn-sm" onClick={() => showModal()}>nuevo contacto <i className="bi bi-plus-circle-fill m-l-8"></i></button>
                </div>
            </Header>

            <Aside />

            <main role="main" className="main">

                <div className="bg-white border-bottom">
                    <div className="container p-t-12 p-b-12 px-5">
                        <div className="d-flex align-items-center">
                            <i className="bi bi-search m-l-8"></i>
                            <input className="form-control input-group-borderless form-control-flush" type="text" placeholder="Buscar" />
                        </div>
                    </div>
                </div>

                <div className="content container px-5">

                    <HandleNoData
                        condition={contacts.length}
                        emptyComponent={<EmptyState />}
                    >
                        <table className="card-table table">
                            <thead>
                                <tr>
                                    <th scope="col">NOMBRE y Correo electrónico</th>
                                    <th scope="col">Tipo o Especialidad</th>
                                    <th scope="col">RUTA O ID</th>
                                    <th scope="col">Teléfono</th>
                                    <th scope="col">Segmentación</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    contacts.map((contact: any) => (
                                        <tr key={contact._id}>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <span className="avatar avatar-sm avatar-primary avatar-circle">
                                                        <img className="avatar-img" src={contact.avatar} alt={contact.first_name + ' ' + contact.last_name} />
                                                    </span>
                                                    <div className="m-l-16">
                                                        <p className="fw-semibold text-dark">{contact.first_name} {contact.last_name}</p>
                                                        <p>{contact.email}</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                { contact.type === 'OTHER' && 'Otro' }
                                                { contact.type === 'PHARMACY' && 'Farmacia' }
                                                { contact.type === 'DOCTOR' && contact?.medical_specialty?.name }
                                            </td>
                                            <td>{contact.assigned_id ? contact.assigned_id.name: <span className="badge bg-soft-danger text-dark">Sin asignar</span>}</td>
                                            <td>
                                                {
                                                    contact.phones && contact.phones.length > 0 && (contact.phones[0].phone.country_code + ' ' + contact.phones[0].phone.phone_number)
                                                }
                                            </td>
                                            <td>
                                                {
                                                    contact.segmentations.map((segment: any) => segment.title).join(', ')
                                                }
                                            </td>
                                            <td>
                                                {/* <button type="button" className="btn btn-outline-primary btn-outline-trash  btn-icon ">
                                                    <i className="bi bi-trash-fill"></i>
                                                </button> */}
                                                <button type="button" className="btn btn-outline-primary btn-outline-edit btn-icon m-l-8" onClick={() => showModal(contact)}>
                                                    <i className="bi bi-pencil-fill"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>

                    </HandleNoData>
                </div>

                <Footer />
            </main>


            <ContactFormModal show={modal.show} data={modal.data} onRequestClose={handleHideModal}  />

        </>
    )
}

export default ContactHomePage